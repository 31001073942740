import { post } from '@/util/request.js';
import { search, genericEntityUpdate } from './system.js';

export function createCountry(data, entityclassId, attributesetId) {
	return post('eav/entity/create', {
		entityclass: entityclassId,
		attributeset: attributesetId,
		data
	});
}

export function getAllCountries(entityclassId) {
	return search(entityclassId);
}

export function updateCountry(oldEntry, newData, set) {
	return genericEntityUpdate(oldEntry, newData, set);
}
