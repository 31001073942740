
export function createDeferred() {
	const ret = {
		resolve: () => {},
		reject: () => {},
		promise: null,
	};
	ret.promise = new Promise((res, rej) => {
		ret.resolve = res;
		ret.reject = rej;
	});
	return ret;
}

export function wait(ms, rej) {
	return new Promise(function (resolve, reject) {
		setTimeout(rej ? reject : resolve, ms);
	});
}

export async function waitUntilTrue(fn, retry, max) {
	if (!max) max = 2000;
	if (!retry) retry = 100;
	let start = Date.now();
	do {
		let res = await fn();
		if (res) return true;
		await wait(retry);
		// console.log(Date.now() - start);
	} while (Date.now() - start < max);
	return false;
}
