import { post as sendPostRequest } from '@/util/request.js';
import { search, genericEntityUpdate } from './system.js';

export async function createComponent(title, parentId, disabled, entityclass, attributeset) {
	let data = {};
	data.title = title;
	if (typeof parentId === 'string' && parentId !== '') data.parent = parentId;
	if (typeof disabled !== 'undefined') data.disabled = disabled;
	if (JSON.stringify(data.disabled) === '{}') data.disabled = false;

	let entity = {
		entityclass,
		attributeset,
		data
	};

	let createdEntity = await sendPostRequest('eav/entity/create', entity);

	return createdEntity;
}

export function updateComponent(oldComponent, newData, set) {
	return genericEntityUpdate(oldComponent, newData, set);
}

export async function deleteComponent(id) {
	let deletedEntity = await sendPostRequest('eav/entity/disable', id);

	return deletedEntity.id;
}

export function getAllComponents(entityclassId) {
	return search(entityclassId);
}
