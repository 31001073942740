import * as SearchRepo from '@/repository/system';
import * as MessageRepo from '@/repository/messagecenter';
import { toLocale } from '@/plugins/filters';
import PdfLogoTypes from '@/assets/config/pdf-logo-types.json';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import Store from "@/store/main.js";
import { getSubjectFromContext } from "@/util/message-center";
import * as MessagingService from "@/service/messaging";
import { transformErrorObject } from '@/util/error.js';
import { debounce } from 'debounce';
import BmlCommon from '@fibl/bml-common';
import { getCopyOfConversation } from "@/util/portal-store-helper";

const defaultTableFilter = {
	user: 'all',
	institution: '',
	archived: false,
	unread: false,
	folder: '',
	subject: '',
	productName: '',
	sender: '',
	recipient: '',
};

const debouncedLoadMessages = debounce(async function load(
	store
) {
	const accountId = Store.getters['auth/login/accountId'];
	let conversations = [];
	const filter = Store.getters['messagecenter/searchFilter'];
	const ec = await getEntityclassByExternalId('message');
	const pagination = Store.getters['messagecenter/pagination'];
	const sorting = Store.getters['messagecenter/getSorting'];
	const pageSize = Store.getters['messagecenter/getPageSize'];
	const res = await SearchRepo.search(
		ec.id,
		filter,
		pagination,
		'indexfields.lastMessageDate:' + sorting
	);
	if (res.length < pageSize) {
		let totalResults = pagination.start + res.length;
		Store.commit('messagecenter/setTotalResults', totalResults);
	} else {
		let totalResults = await SearchRepo.countEntries(ec.id, filter);
		Store.commit('messagecenter/setTotalResults', totalResults);
	}
	let notifCount = 0;
	let allConvo = []
	try {
		allConvo = await Promise.all(
			res.map(async (row, index) => {
				let obj = new BmlCommon.MessageClass();
				obj.fromStorage(row);
				let party = MessagingService.getParty(obj, accountId);
				obj.setCurrentParty(party);
				if (row.data.notification && party === 'recipient') {
					notifCount += 1;
				}
				let sub = await getSubjectFromContext(obj);
				obj.setSubjectFromContext(sub);
				return obj;
			}),
		)
	} catch(error) {
		throw new Error(`Failed to load Conversation: ` + error);
	}
	if (notifCount !== Store.getters['messagecenter/getNotificationCount']) {
		Store.commit('messagecenter/setNotificationCount', notifCount);
	}
	Store.commit('messagecenter/setConversations', allConvo);
},
350);

const state = {
	institutionSelect: '',
	messageEc: '',
	companyLinks: [],
	conversations: [],
	conversationForRequest: null,
	currentRequestContext: '',
	notificationCount: 0, //@TODO make computed
	unreadMessageCount: 0,
	// table and pagination
	pageSize: 10,
	totalResults: 0,
	currentPage: 1,
	sorting: 'desc',
	tableFilter: JSON.parse(JSON.stringify(defaultTableFilter)),
	loadedOnce: false,
	idFiblDE: 'ab2e5fd7-9ebb-43d8-a0ab-5aedfb43708b', // remove hard coding if more than fiblDE is involved!
};

const getters = {
	getFiblDEId: (state) => {
		return state.idFiblDE;
	},
	getConversationId: (state) => {
		return state.conversations[0].getId();
	},
	searchFilter: (state) =>  {
		const filter = { $and: [] };
		const meFilter = [];
		if (state.tableFilter.user === 'mine') {
			let account = Store.getters['auth/user/getCurrentAccount']();
			meFilter.push({ field: '/data/%P/fibluser', comparison: 'eq', value: account ? account.id : '-' });
		} else if (state.tableFilter.user === 'unassigned') {
			meFilter.push({
				$not: { field: '/data/%P', comparison: 'exists', value: 'fibluser' },
			});
		} else if (state.tableFilter.user !== 'all') {
			meFilter.push({
				field: '/data/%P/fibluser',
				comparison: 'eq',
				value: state.tableFilter.user,
			});
		}
		if (state.tableFilter.unread) {
			meFilter.push({
				field: `/indexfields/%PUnread`,
				comparison: 'gt',
				value: 0,
			});
		}
		const addOnBothSides = function (key, val) {
			filter.$and.push({
				$or: [
					{ field: '/data/sender/' + key, comparison: 'eq', value: val },
					{ field: '/data/recipient/' + key, comparison: 'eq', value: val },
				],
			});
		};
		if (state.tableFilter.institution) {
			addOnBothSides('fiblorg', state.tableFilter.institution);
		}

		if (state.tableFilter.folder) {
			//my side
			meFilter.push({
				field: '/data/%P/folder',
				comparison: 'eq',
				value: state.tableFilter.folder,
			});
		} else {
			meFilter.push({
				$not: {
					field: '/data/%P',
					comparison: 'exists',
					value: 'folder',
				},
			});
		}


		if (state.tableFilter.archived) {
			//my side
			meFilter.push({
				field: '/data/%P/archived',
				comparison: 'eq',
				value: state.tableFilter.archived,
			});
		} else {
			meFilter.push({
				$or: [
					{
						field: '/data/%P/archived',
						comparison: 'eq',
						value: false,
					},
					{
						$not: {
							field: '/data/%P',
							comparison: 'exists',
							value: 'archived',
						},
					},
				],
			});
		}
		if (state.tableFilter.sender) {
			filter.$and.push({
				$or: [
					{
						field: '/data/sender/company',
						comparison: 'eq',
						value: state.tableFilter.sender,
					},
					{
						field: '/data/recipient/company',
						comparison: 'eq',
						value: state.tableFilter.sender,
					},
				],
			});
		}
		if (state.tableFilter.subject) {
			state.tableFilter.subject.length === 36
				? filter.$and.push({
						field: '/data/subject',
						comparison: 'eq',
						value: state.tableFilter.subject,
				  })
				: filter.$and.push({
						field: '/data/context',
						comparison: 'exists',
						value: state.tableFilter.subject,
				  });
		}
		if (meFilter.length) {
			filter.$and.push(MessagingService.getMeFilterBothSides({ $and: meFilter }));
		}
		return filter;
	},
	pagination: (state) => {
		return {
			start: (state.currentPage - 1) * state.pageSize,
			limit: state.pageSize,
		};
	},
	getCompanyLinks: (state) => {
		return state.companyLinks;
	},
	getConversations: (state) => {
		return state.conversations;
	},
	getUnreadMessageCount: (state) => {
		return state.unreadMessageCount;
	},
	getSorting: (state) => {
		return ['asc','desc'].includes(state.sorting) ? state.sorting : 'desc';
	},
	getNotificationCount: (state) => {
		return state.notificationCount;
	},

	getTableFilter: (state) => {
		return state.tableFilter;
	},

	getPageSize: (state) => {
		return state.pageSize;
	},

	getCurrentPage: (state) => {
		return state.currentPage;
	},

	getTotalResults: (state) => {
		return state.totalResults;
	},

	getName: (state) => (id, ec) =>  {
		if (id === null) return '';
		let res = Store.getters['related/byId'](id);
		if (res) {
			if (ec === 'company') {
				let data = res.data;
				return data.baseData.name;
			} else if (ec === 'orgas') {
				let data = res.data;
				return toLocale(data.title);
			} else if (ec === 'fibluser') {
				let data = res.data;
				return  data.staticData.fullname;
			}
		}
		Store.dispatch('related/addRequiredEntity', {id: id});
		return '';
	},
	getSpecificConversation: (state) => (id) => {
		return state.conversations.find((convo) => convo.getId() === id);
	}
};

const actions = {
	resetFilters({ state, commit, getters }) {
		for (let [key, entry] of Object.entries(defaultTableFilter)) {
			commit('setTableFilter',{ key: key, entry: entry });
		}
	},
	loadConversations({ state, commit, getters }) {
		debouncedLoadMessages(state);
	},
	async loadUnreadMessageCount({ state, commit, getters }) {
		const accountId = Store.getters['auth/user/getCurrentAccount'];
		if (!accountId) return;
		const filter = MessagingService.getMeFilterBothSides({
			field: `/indexfields/%PUnread`,
			comparison: 'gt',
			value: 0,
		});
		const ec = await getEntityclassByExternalId('message');
		const unreadMessageCount = await SearchRepo.countEntries(ec.id, filter);
		commit('setUnreadMessageCount', unreadMessageCount);
	},
	async saveConversation({ state, commit, getters }, conversation) {
		try {
			await SearchRepo.updateEntity(conversation, 'anr');
			commit('setSpecificConversation', conversation);
		} catch (error) {
			const message = await transformErrorObject(error);
			if (message.includes('eav: there is no change after applying the patch'))
				console.log(message, error);
			else throw new Error('Failed to save conversation: ' + error);
		}
	},
	async firstTimeInit({ state, commit, getters, root, dispatch }) {
		if (state.loadedOnce) return;
		commit('setLoaded', true);
		await Promise.all([
			dispatch('messagecenter/loadCompanyLinks', null, { root: true }),
			dispatch('auth/org/loadFiblUsers', null, { root: true }),
			dispatch("selectoptions/loadAll", { ifEmpty: true }, { root: true }),
			dispatch("auth/org/loadAll", { ifEmpty: true }, { root: true }),
		]);
	},
	async loadCompanyLinks({ state, commit, getters }) {
		let res = await SearchRepo.aggregateRun('paiduser', {
			field: '/data/staticData/companyLink',
			key: 'companyLinks',
			type: 'term',
		});
		let links = [];
		let buckets = res.aggregations.companyLinks.buckets;
		for (let i = 0; i < buckets.length; i++) {
			let entry = buckets[i];
			links.push(entry.key);
		}
		commit('setCompanyLinks', links);
	},
	async initNewConvo( { state, commit, getters, root },
		contextKey,
		contextId,
		recipientCompany,
	) {
		const account = Store.getters["auth/login/accountId"];
		const accountDetails = Store.getters["auth/login/accountDetails"];
		const now = new Date().toJSON();
		const isFibl = true;
		const conversation = new BmlCommon.MessageClass();
		let ec = await SearchRepo.getEntityclassByExternalId('message');
		let as = await SearchRepo.getAttributesetByExternalId('message');
		conversation.fromStorage({
			entityclass: ec.id,
			attributeset: as.id,
			id: '',
			rev: 0,
			data: {},
			created: '',
			updated: '',
		});
		const payload = {
			sender: {
				fiblorg: state.idFiblDE,
				fibluser: account,
		  	},
			messages: [],
		};
		conversation.fromData(payload);
		conversation.setCurrentParty('sender');
		if (contextKey && contextId) {
			conversation.context = {
				[contextKey]: contextId,
			};
			if (!conversation.recipient) {
				conversation.recipient = {
					company: recipientCompany,
				};
			}
		}
		return conversation;
	},
	async updateConversation({ state, commit, getters, root },
		{
			conversation,
			newMessage,
			newAttachments,
			asDraft,
			subject,
			recipient
		}
	) {
		const now = new Date();
		if(!conversation.getCurrentParty().fiblorg) {	//@TODO move further up
			if(conversation.getCurrentParty().company === null) delete conversation.getCurrentParty().company;
			conversation.getCurrentParty().fiblorg = Store.getters["messagecenter/getFiblDEId"];
			const accountId = Store.getters['auth/user/getCurrentAccount'];
			if(accountId) {
				conversation.getCurrentParty().fibluser = accountId;
			}
		}
		if (recipient) {
			conversation.recipient = recipient;
		}
		if (subject) {
			conversation.subject = subject;
		}
		if (asDraft) {
			conversation.getCurrentParty().draft = {
				message: newMessage,
				attachments: newAttachments,
			};
		} else {
			conversation.getCurrentParty().draft = undefined;
			conversation.messages.push({
				party: conversation.getCurrentPartyName(),
				sent: now.toJSON(),
				content: newMessage,
				attachments: newAttachments && newAttachments.length ? newAttachments : undefined,
			});
		}
		if (conversation.getId()) {
			await SearchRepo.updateEntity(conversation, 'anr');
			commit('setSpecificConversation', conversation);
		} else {
			if (conversation.context && !asDraft) await MessageRepo.sendConversation(conversation);
			else await SearchRepo.createEntity(conversation);
		}
		let sub = await getSubjectFromContext(conversation);
		conversation.setSubjectFromContext(sub);
		let inList = state.conversations.find((e) => e.getId() === conversation.getId());
		const convoClass = getCopyOfConversation(conversation);
		if (inList) {
			commit('setInListConversation', convoClass);
		} else {
			commit('setUnshiftConversations', convoClass);
		}
		commit('setLastReadForConversation', conversation.getId());
	},
	async setLastRead({ state, commit, getters, root }, conversation) {
		let lastmessagesent = conversation.messages[conversation.messages.length - 1]?.sent;
		if (!lastmessagesent) return;
		let now = new Date().toJSON();
		let party = conversation.getCurrentParty();
		if (!party.lastReadMessages || party.lastReadMessages < lastmessagesent) {
			commit('setLastReadForConversation', conversation.getId());
			conversation.getCurrentParty().lastReadMessages = now;
			await Store.dispatch('messagecenter/saveConversation', conversation);
			if (state.unreadMessageCount > 0) {
				const newCount = state.unreadMessageCount;
				commit('setUnreadMessageCount', newCount - 1);
			}
		}
	},
	async initializeConversation({ state, commit, getters, root },
		contextKey,
		contextId,
		recipientCompany,
	) {
		let conversation;
		try {
			conversation = await MessageRepo.loadConversation(contextKey, contextId);
		} catch (error) {
			if (error.previous.message.includes('No conversations found')) {
				console.log(error);
				conversation = await dispatch('messagecenter/initNewConvo', {contextKey, contextId, recipientCompany}) ;
			} else {
				throw new Error(`Failed to open conversation: ` + error);
			}
		}
		return conversation;
	},
};

const mutations = {

	setTableFilter(state, {key,entry}) {
		state.tableFilter[key] = entry;
	},

	setUnreadMessageCount(state, count) {
		state.unreadMessageCount = count;
	},

	setConversationForRequest(state, convo) {
		state.conversationForRequest = convo;
	},

	setCurrentRequestContext(state,context) {
		state.currentRequestContext = context
	},

	setLoaded(state, isLoad) {
		state.loadedOnce = isLoad;
	},

	setCompanyLinks(state, links) {
		state.companyLinks = links;
	},

	setConversations (state, convos) {
		state.conversations = convos;
	},

	setUnshiftConversations(state, conversation) {
		state.conversations.unshift(conversation);
	},

	 setNotificationCount: (state,val) => {
		 state.notificationCount = val;
	},

	setPageSize: (state,val) => {
		 state.pageSize= val;
	},

	setCurrentPage: (state,val) => {
		 state.currentPage= val;
	},

	setTotalResults: (state,val) => {
		 state.totalResults= val;
	},

	setSorting: (state, val) => {
		return state.sorting = val;
	},

	setLastReadForConversation: (state, id) => {
		const index = state.conversations.findIndex((e) => e.getId() === id);
		state.conversations[index].getCurrentParty().lastReadMessages = new Date().toJSON();
	},

	setInListConversation: (state, conversation) => {
		state.conversations.find((e) => e.getId() === conversation.getId()).fromEntity(conversation.toEntity());
	},

	setSpecificConversation: (state, conversation) => {
		const index = state.conversations.findIndex((e) => e.getId() === conversation.getId());
		if(index < 0) {
			state.conversations.push(conversation);
		} else {
			state.conversations.splice(index, 1, conversation);
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
