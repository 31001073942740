import * as SystemRepository from '@/repository/system.js';

const ENTITY_EXTERNAL_ID = 'restriction_text';

const state = {

	entityclassId: null,
	attributesetId: null,
	restrictionTexts: [],
	checkedBaseline: false,
	checkedRows: [],
	listFilter: {
		text: '',
		standards: [],
		categories: [],
		language: 'de_DE'
	},
	listTotalCount: 0,
	totalCount: 0

};

const getters = {

	byId: (state) => (id) => {
		return state.restrictionTexts.find(restriction => restriction.id === id);
	},

	getFilterByOption: state => option => {
		if (option === 'active') {
			return state.listFilter[option].booleanValue;
		} else {
			return state.listFilter[option];
		}
	},

	getListFilter: state => filter => {
		return state.listFilter[filter];
	},

	getListAttributeFilter: (state, getters, rootState, rootGetters) => {
		const filter = state.listFilter;
		let $and = [];
		if (filter.standards.length > 0) {
			$and.push({ $or: filter.standards.map(id => ({
				field: '/data/standards',
				comparison: 'eq',
				value: id
			}))});
		}
		if (filter.text && filter.language) {
			$and.push({
				field: `/data/text/${filter.language}`,
				comparison: 'eq',
				value: filter.text,
				fuzzy: true,
				use: 'ngram'
			});
		}
		if (filter.categories && Array.isArray(filter.categories) && filter.categories.length) {
			let orFilter = { $or: [] };
			for (let category of filter.categories) {
				let categories = rootGetters['category/byRoot'](category);
				for (let subCategory of categories) {
					orFilter.$or.push({
						field: '/data/categories',
						comparison: 'eq',
						value: subCategory.id
					});
				}
			}
			$and.push(orFilter);
		}
		if (filter.active) {
			$and.push({
				field: '/data/active',
				comparison: 'eq',
				value: filter.active.booleanValue
			});
		}
		return { $and };
	},

	byIds: (state) => (restrictionTextIds) => {
		if (!restrictionTextIds || !Array.isArray(restrictionTextIds)) {
			return [];
		}

		return state.restrictionTexts.filter(restriction => restrictionTextIds.includes(restriction.id));
	},

	getAll: (state) => {
		return state.restrictionTexts;
	},

	restrictonTextsByCategories: (state) => (categories) => {
		if (!categories || !categories.length) {
			return state.restrictionTexts;
		}
		let texts = state.restrictionTexts.filter(text => text.data.categories.find(categoryId => categories.includes(categoryId)));
		return texts;
	}

};

const actions = {

	async ensureAttributesetEntityclassLoaded({ commit }) {
		if (!state.entityclassId) {
			const ec = await SystemRepository.getEntityclassByExternalId(ENTITY_EXTERNAL_ID);
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			const as = await SystemRepository.getAttributesetByExternalId(ENTITY_EXTERNAL_ID);
			commit('setAsId', { id: as.id });
		}
	},

	async create({ state, commit, dispatch }, data) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		delete data.restrictionTextId;
		const newRestrictionText = await SystemRepository.genericEntityCreate(state.entityclassId, state.attributesetId, data);
		commit('addRestrictionText', { text: newRestrictionText });
		return newRestrictionText;
	},

	async delete({ commit }, { id }) {
		if (!id) return;
		await SystemRepository.genericEntityDelete({ id });
		commit('deleteRestrictionText', { id });
	},

	async load({ dispatch, commit }, { filter, pagination, sorting }) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let list = await SystemRepository.search(state.entityclassId, filter, pagination, sorting);
		let count = await SystemRepository.countEntries(state.entityclassId, filter);
		console.log('count?', count);
		commit('setListCount', count);
		list.forEach(item => {
			item.data.restrictionTextId = item.id;
		});
		commit('setRestrictionTexts', { texts: list });
		return list;
	},

	async update({ commit, dispatch, getters }, restrictionText) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let oldEntity = JSON.parse(JSON.stringify(getters.byId(restrictionText.restrictionTextId)));
		delete restrictionText.restrictionTextId;
		delete oldEntity.data.restrictionTextId;
		const updated = await SystemRepository.genericEntityUpdate(oldEntity, restrictionText);
		if (updated) {
			updated.data.restrictionTextId = updated.id;
			commit('updateRestrictionText', { text: updated });
		}
		return updated;
	},

	async loadAllByCategories({ commit, dispatch }, { categories }) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let filter = null;
		if (categories && categories.length) {
			filter = { $or: [] };
			for (let categoryId of categories) {
				filter.$or.push(
					{
						field: '/data/categories',
						comparison: 'eq',
						value: categoryId
					}
				);
			}
		}
		let list = await SystemRepository.search(state.entityclassId, filter);
		commit('setRestrictionTexts', { texts: list });
	},

	async loadByIds({ commit }, ids) {
		let list = await SystemRepository.getEntitiesById(ids);
		commit('setRestrictionTexts', { texts: list });
		return list;
	},


};

const mutations = {
	setFilterOption(state, { filter, value }) {
		if (filter === 'active') {
			state.listFilter[filter].booleanValue = value;
		} else {
			state.listFilter[filter] = value;
		}
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	addRestrictionText(state, { text }) {
		addUpdateRestrictionText(state, text);
	},
	updateRestrictionText(state, { text }) {
		addUpdateRestrictionText(state, text);
	},
	deleteRestrictionText(state, { id }) {
		const textIndex = state.restrictionTexts.findIndex(restrictionText => restrictionText.id === id);
		if (textIndex === -1) throw new Error(`entity not found: ${id}`);
		state.restrictionTexts.splice(textIndex, 1);
	},
	setRestrictionTexts(state, { texts }) {
		if (!texts || !texts.length) {
			state.restrictionTexts = [];
			return;
		}
		state.restrictionTexts = texts;
	},
	setListCount(state, count) {
		state.listTotalCount = count;
	}
};

function addUpdateRestrictionText(state, text) {
	if (!text.id) return;
	const oldTextIndex = state.restrictionTexts.findIndex(restrictionText => restrictionText.id === text.id);
	if (oldTextIndex !== -1) {
		state.restrictionTexts.splice(oldTextIndex, 1, text);
	} else {
		state.restrictionTexts.push(text);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
