import { deepSet } from '@/util/field-helper.js';
import { componentUnlinkableFields } from '@/store/modules/product.js';
import deepIterator from 'deep-iterator';

export const doNotUnlinkNewLists = [
	'3c57d0fe-64d5-4aae-8471-b3e9746b7086', // DE
	'd23c9774-1c42-415b-8995-b9c8e06e4e8b', // ES
	'6b4afd7c-6cb8-4b64-b921-2dad1f2d9cd3', // DE BG
];

export function stripUnusedData(workingCopy) {
	// the external components are created temporarily to allow displaying of other product's component data in the component table
	if (workingCopy._components && workingCopy._components.external) {
		delete workingCopy._components.external;
	}
	if (typeof workingCopy._checklist === 'object') {
		Object.values(workingCopy._checklist).forEach(row => {
			for (let [key, val] of Object.entries(row)) {
				if (!val) delete row[key];
			}
		});
	}
	if (Array.isArray(workingCopy._companies)) {
		workingCopy._companies = workingCopy._companies.filter(company => !!company.company);
	}
	let toRemove = [];
	for (let { value, path } of deepIterator(workingCopy)) {
		if (value === '' && path[path.length-1] === 'dateValue') {
			toRemove.push(path);
		}
	}
	for (let path of toRemove) {
		deepSet(workingCopy, path, undefined);
	}
}

export function rebuildComponents(workingCopy) {
	if (workingCopy._components && workingCopy._components.base) {
		for (let [id, row] of Object.entries(workingCopy._components.base)) {
			componentUnlinkableFields.forEach(f => {
				if (row[f]) delete row[f]._isBase;
			});
			row._id = id;
			if (Object.values(row).length < 2) {
				delete workingCopy._components.base[id];
				continue;
			}
			if (row.amount && 'numberValue' in row.amount && typeof row.amount.numberValue !== 'number') {
				workingCopy._components.base[id].amount.numberValue = 0;
			}
		}
		workingCopy._components.base = Object.values(workingCopy._components.base);
	}
	if (workingCopy._components && workingCopy._components.additional) {
		for (let [id, row] of Object.entries(workingCopy._components.additional)) {
			componentUnlinkableFields.forEach(f => {
				if (row[f]) delete row[f]._isBase;
			});
			if (Object.values(row).length < 2 || !row._id) {
				delete workingCopy._components.additional[id];
				continue;
			}
			if (row.amount && 'numberValue' in row.amount && typeof row.amount.numberValue !== 'number') {
				workingCopy._components.additional[id].amount.numberValue = 0;
			}
		}
		workingCopy._components.additional = Object.values(workingCopy._components.additional);
	}
}
