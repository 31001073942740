import { toLocale } from '@/plugins/filters.js';
import * as Repo from '@/repository/checklist.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';

const state = {

	entries: [],
	attributesetId: '',
	entityclassId: ''

};

const getters = {

	byId: (state) => (id) => {
		return state.entries.find(r => r.id === id);
	},

	all: (state) => {
		return state.entries;
	},

	allActive: (state) => {
		return state.entries.filter(entry => entry.data.active);
	}
};

const actions = {

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('clentry');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('clentry');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createChecklistEntry(data, state.entityclassId, state.attributesetId);
		commit('addEntry', entity);
	},

	async loadAll({ commit }, { ifEmpty }) {
		if (ifEmpty && state.entries.length > 0) return;

		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('clentry');
			commit('setEcId', { id: ec.id });
		}
		let list = await Repo.getAllEntries(state.entityclassId);
		list = list.sort((a, b) => toLocale(a.data.title).localeCompare(toLocale(b.data.title)));
		commit('setEntries', list);
	},

	async update({ state, commit, getters }, entry) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('clentry');
			commit('setAsId', { id: ec.id });
		}
		let oldEntry = getters.byId(entry.id);
		let updated = await Repo.updateEntry(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deleteEntry', { id: entry.id });
			commit('addEntry', updated);
		}
	},

	async delete({state, commit}, {id}) {
		await Repo.deleteEntry(id);
		commit('deleteEntry', {id});
	}

};

const mutations = {

	addEntry(state, entity) {
		state.entries.push(entity);
	},
	setEntries(state, entries) {
		state.entries = entries;
	},
	deleteEntry(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.entries.indexOf(entity);
		state.entries.splice(idx, 1);
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
