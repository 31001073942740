import Store from '@/store/main.js';
import { pathToColumnSet } from './columnset';
import { SimpleFilter, CompanyFilter } from './structures';
import SearchFields from '@/assets/config/search/search-fields';
import { transliterate } from 'transliteration';

// This table is used to map field types to the corresponding suffixes for property values that are wrapped into an JSON object.
// example: checkbox fields wrap their checked value inside an booleanValue property, so the path gets converted from
// data.listData.xyz.checkbox => data.listData.xyz.checkbox.booleanValue
const FIELD_VALUE_TYPE_MAPPING = {};
FIELD_VALUE_TYPE_MAPPING['singleline-textfield'] = 'textValue';
FIELD_VALUE_TYPE_MAPPING.textfield = 'textValue';
FIELD_VALUE_TYPE_MAPPING.checkbox = 'booleanValue';
FIELD_VALUE_TYPE_MAPPING.datefield = 'dateValue';
FIELD_VALUE_TYPE_MAPPING.select = 'selectValue';
FIELD_VALUE_TYPE_MAPPING.numberfield = 'numberValue';
FIELD_VALUE_TYPE_MAPPING.comment = 'comments/text';
FIELD_VALUE_TYPE_MAPPING.company = 'label';
FIELD_VALUE_TYPE_MAPPING.multiselect = 'multiselectValue';
// fallback mapping
const DEFAULT_VALUE_MAPPING = 'textValue';

const DYNAMIC_FIELD_TO_INDEX_MAPPING = {
	'basis-f1': '/indexfields/namesearch/{listId}',
	'companyIndexDistributor': '/data/indexData/{listId}/distributor',
	'companyIndexManufacturer': '/data/indexData/{listId}/manufacturer'
};
const FIELD_EXTRA_PREP = {
	'basis-f1'(value) {
		value = transliterate(value.toLowerCase());
		let titleWithSpaces = value.replace(/[^\d\w ]+/gu, '');
		let titleNoSpaces = titleWithSpaces.replace(/\s*/g, '');
		return titleNoSpaces ? [ titleWithSpaces, titleNoSpaces ] : [ titleWithSpaces ];
	}
};

export function constructSelectionFilter(baseFilter, productSelection) {
	if (productSelection.blacklistMode) {
		if (productSelection.items.length === 0) return baseFilter;
		return constructBlacklistFilter(baseFilter, productSelection.items);
	} else {
		if (productSelection.items.length === 0) return baseFilter;
		return constructWhitelistFilter(productSelection.items);
	}
}

// include selected objects, if we are in whitebox selection mode (e. g. the select all-checkbox wasn't selected)
function constructWhitelistFilter(items) {
	let filter = { $or: [] };
	for (const id of items) {
		filter.$or.push({
			comparison: 'eq',
			field: '/id',
			value: id
		});
	}
	return filter;
}

// exclude object ids from blackbox selection mode (e. g. the select all-checkbox was selected)
// creates an additional AND clause to an given filter
// the function relies on the whitelist filter and just inverts the sub-filter returned over there
function constructBlacklistFilter(subFilter, items) {
	let totalFilter = { $and: [] };
	const notFilter = { $not: {} };
	if (items.length > 0) {
		notFilter.$not = constructWhitelistFilter(items);
		totalFilter.$and.push(notFilter);
	}
	if (subFilter) {
		totalFilter.$and.push(subFilter);
	}
	return totalFilter;
}

function _appendNestedFieldMapping(field, path) {
	// preventNestedFieldMapping is used to control if the path suffix from FIELD_VALUE_TYPE_MAPPING should be appended.
	// if true, this is an nop.
	if (field.data.preventNestedFieldMapping) {
		return path;
	}

	const type = field.data.type.toLowerCase();
	if (type.includes('multitext')) {
		return path;
	}

	let mapping = FIELD_VALUE_TYPE_MAPPING[type];
	return `${path}/${!mapping ? DEFAULT_VALUE_MAPPING : mapping}`;
}

function getComparisonMeta(node) {
	let comparison = node.comparator === 'fuz' || node.comparator === 'contains' ? 'eq' : node.comparator;
	let fuzzy = node.comparator === 'fuz';
	let use = node.comparator === 'contains' ? 'ngram' : node.comparator === 'begins' ? 'keyword' : 'text';
	return { comparison, fuzzy, use };
}

let nd = new Date();
let now = nd.toJSON().substr(0, 10);

export function generateQueryFromSearchRoot({ search, inList, products, institution, accountId }) {
	const baseList = Store.getters['list/baseDataList'];

	function _getAllLists() {
		const availableLists = Store.getters['auth/user/roles/getAvailableLists'];

		return inList
			? availableLists.filter(l => l.id === inList)
			: availableLists;
	}

	function _isIndexMappedField(field, listId) {
		const mappingRaw = DYNAMIC_FIELD_TO_INDEX_MAPPING[field.data.path];
		return {
			isIndexMapped: !!mappingRaw,
			mapping: mappingRaw ? mappingRaw.replace('{listId}', listId) : mappingRaw
		};
	}

	function _wrapResponsibleFilter(query) {
		if (!institution) {
			return query;
		}

		let wrap = { $and: [] };

		const subQuery = { $or: [] };

		for (const list of _getAllLists()) {
			const basePath = `/data/listData/${list.id}/_responsible`;
			const responsibilityQuery = { $and: [] };
			responsibilityQuery.$and.push({
				field: `${basePath}/institution`,
				comparison: 'eq',
				value: institution
			});

			if (products === 'active' || products === 'inactive') {
				responsibilityQuery.$and.push({
					field: `/data/listData/${list.id}/_active`,
					comparison: 'eq',
					value: products === 'active'
				});
			}

			if (accountId) {
				responsibilityQuery.$and.push({
					field: `${basePath}/accountId`,
					comparison: 'eq',
					value: accountId
				});
			}

			subQuery.$or.push(responsibilityQuery);
		}

		wrap.$and.push(query);
		wrap.$and.push(subQuery);

		return wrap;
	}

	function _isCompanyQuery(fieldId) {
		return fieldId === '_companies';
	}

	function _addAdditionalSearchFilters(query) {
		const filters = Store.getters['search/getAdditionalSearchFilters'];
		if (!filters || Object.keys(filters).length === 0) {
			return query;
		}

		const additionalQuery = { $and: [] };
		for (const [path, data] of Object.entries(filters)) {
			if (!data) {
				continue;
			}

			if (!data.value && typeof data.value !== 'boolean') {
				continue;
			}

			const columnset = pathToColumnSet(path);
			if (!columnset) {
				continue;
			}

			inList = columnset.listId ? columnset.listId : null;

			// resolve the field path to an entity id
			const fieldByPath = Store.getters['columnset/fieldByPath'](columnset.fieldId);
			if (!fieldByPath) {
				console.warn('field by path not found', columnset.fieldId);
				continue;
			}

			const isCompanyQuery = _isCompanyQuery(columnset.fieldId);
			let node;
			if (isCompanyQuery) {
				node = new CompanyFilter();
				node.role = columnset.tableColumnId.replace('company', '').toLowerCase();
			} else {
				node = new SimpleFilter();
			}

			node.fieldId = fieldByPath.id;
			node.tableColumnPath = columnset.tableColumnId;
			node.tableRowId = columnset.tableRowId;

			if (fieldByPath.data.defaultComparator) {
				node.comparator = fieldByPath.data.defaultComparator;
			} else if (data.comparator) {
				node.comparator = data.comparator;
			} else {
				node.comparator = 'contains';
			}

			node.value = data.value;

			let subQuery;

			if (isCompanyQuery) {
				subQuery = _companyQuery(node);
			} else if (node.value && Array.isArray(node.value)) {
				subQuery = { '$or': [] };
				for (const field of node.value) {
					subQuery.$or.push(_simpleQuery({
						...node,
						value: field
					}));
				}
			} else {
				subQuery = _simpleQuery(node);
			}

			if (subQuery) {
				additionalQuery.$and.push(subQuery);
			}
		}

		const totalQuery = { $and: [] };
		totalQuery.$and.push(query);
		totalQuery.$and.push(additionalQuery);

		return totalQuery;
	}

	function _wrapActiveFlag(query) {
		const listSearch = { '$or': []};
		let wrap = {
			'$and': []
		};

		if (products === 'active' || products === 'inactive') {
			for (const list of _getAllLists()) {
				listSearch.$or.push({
					field: `/data/listData/${list.id}/_active`,
					comparison: 'eq',
					value: products === 'active'
				});
			}
		}

		if (inList) wrap.$and.push({
			field: '/data/listData',
			comparison: 'exists',
			value: inList
		});
		wrap.$and.push(listSearch);
		wrap.$and.push(query);

		return wrap;
	}

	function _getFieldFromNode(node) {
		const field = Store.getters['columnset/fieldById'](node.fieldId);
		if (!field) {
			console.error('field by id not found', node.fieldId);
			return null;
		}

		let tableColumn = null;
		let actualField;

		if (node.tableColumnPath) {
			tableColumn = Store.getters['columnset/fieldByPath'](node.tableColumnPath);
			if (!tableColumn) {
				console.error('tableColumn by path not found', node.tableColumnPath);
				return null;
			}
			actualField = tableColumn;
		} else if (node.tableColumnId) {
			tableColumn = Store.getters['columnset/fieldById'](node.tableColumnId);
			if (!tableColumn) {
				console.error('tableColumn by id not found', node.tableColumnId);
				return null;
			}
			actualField = tableColumn;
		} else {
			actualField = field;
		}

		return {
			field: actualField,
			tableColumn
		};
	}

	function _parseValue(value, field) {
		if (field.data.path in FIELD_EXTRA_PREP) {
			return FIELD_EXTRA_PREP[field.data.path](value);
		}
		if (!isNaN(parseFloat(value)) && isFinite(value)) {
			return parseFloat(value);
		}
		if (typeof value === 'string') {
			return value.trim();
		}
		return value;
	}

	function _createMultiValueQuery(values, operator, query) {
		let valueArr = values;
		if (typeof value === 'string') {
			valueArr = valueArr.split(',');
		}

		let result = {};
		const key = `$${operator}`;
		result[key] = [];

		for (const value of valueArr) {
			let q = JSON.parse(JSON.stringify(query));
			q.value = value;

			result[key].push(q);
		}

		return result;
	}

	function _pushSearchField(query, list, field, node, tableColumn, optionSource, preventAutoPath) {
		let path;
		const { isIndexMapped, mapping } = _isIndexMappedField(field, list.id);

		// global: fields which live on the /data level.
		// noPathPrefix: fields which live on the root scope
		// isIndexMapped: fields which live in the index
		// pathPrefix: fields that don't have any prefix to be prepended in the path, for example _companies
		// manualAppendListInPath: fields that require to have the list id followed after the path prefix, for example _companies
		if (field.data.global) {
			path = '/data/';
		} else if (field.data.noPathPrefix) {
			path = '/';
		} else if (isIndexMapped) {
			path = mapping;
		} else if (!field.data.pathPrefix) {
			path = `/data/listData/${list.id}/`;
		} else {
			path = field.data.pathPrefix;
			if (field.data.manualAppendListInPath) {
				path += `/${list.id}/`;
			}
		}

		let actualField;
		let value = _parseValue(node.value, field);

		if (tableColumn) {
			if (!field.data.pathPrefix) {
				path += `${field.data.path}/`;
			}

			// sometimes tabular data is nested inside /table subpaths
			if (!tableColumn.data.usePlaceholder && !field.data.usePlaceholder) {
				path += 'table/';
			} else if (optionSource) {
				path += `${optionSource || ''}/`;
			}

			actualField = tableColumn;
		} else {
			actualField = field;
		}

		// required to use an single iteration loop instead of confusing branching later
		// value is the value to be searched for, multi-select values get splitted and translated into sub-queries
		if (actualField.data.type === 'multiselect' && typeof value === 'string') {
			value = value.split(',');
		} else if (!Array.isArray(value)) {
			value = [ value ];
		}

		if (!isIndexMapped) {
			if (path[path.length-1] !== '/') path += '/';
			path += `${actualField.data.path}`;
		}

		// if necessary, add the /textValue, /dateValue, ... suffixes to the path
		path = _appendNestedFieldMapping(actualField, path);

		// sometimes the select option value needs to be used as key in the path, for example for risk labels
		if (field.data.useSourceAsKey) {
			path += `/${value}`;
			// for risk labels, this would be an boolean value. the search value gets replaced here!
			if (field.data.valueFilter) {
				value = field.data.valueFilter;
			}
		}

		let comparator = node.comparator;

		// needed for the invoice flags
		if (field.data.forceExistsQuery) {
			comparator = 'exists';
		}
		if (actualField.data.doesTableFieldExist) {
			let pathParts = path.split('/');
			let lastPart = pathParts.pop();
			let rawQuery = { $and: [] };
			let activePart = null;
			let existsPart = {
				field: pathParts.join('/'),
				value: lastPart,
				comparison: 'exists'
			};

			if (value && value.length && (value[0] === true || value[0] === 'true')) {
				rawQuery.$and.push(existsPart);
			} else {
				rawQuery.$and.push({
					$not: existsPart
				});
			}
			if (actualField.data.forceActiveFlag) {
				activePart = {
					field: `${pathParts.join('/')}/active/booleanValue`,
					comparison: 'eq',
					value: true
				};
				rawQuery.$and.push(activePart);
			}
			query.push(rawQuery);
			return;
		}

		// in case of nested queries we need to reconstruct the full path, so preventAutoPath is only true if we have nested queries
		let result = {
			field: path,
			value: value[0],
			...getComparisonMeta({ comparator })
		};

		if (actualField.data.type === 'datefield') {
			result.format = 'yyyy-MM-dd';
		} else if (actualField.data.type === 'multiselect') {
			result = _createMultiValueQuery(value, 'or', result);
		} else if (Array.isArray(value[0])) {
			result = _createMultiValueQuery(value[0], 'or', result);
		} else if (Array.isArray(value) && value.length > 1) {
			result = _createMultiValueQuery(value, 'or', result);
		}

		query.push(result);
	}

	// query that only searches for lists but doesn't append any other filters.
	function _nopQuery() {
		let query = [];

		if (inList) {
			const allLists = _getAllLists();

			for (const list of allLists) {
				query.push({
					field: '/data/listData',
					comparison: 'exists',
					value: list.id
				});
			}
		}

		return { '$or': query };
	}

	// query for simple field filters
	function _simpleQuery(node) {
		const field = Store.getters['columnset/fieldById'](node.fieldId);
		if (!field) return null;
		let fieldData = _getFieldFromNode(node);
		if (!fieldData || !fieldData.field) return null;
		const fieldForVis = fieldData.field;
		const tableColumn = fieldData.tableColumn;

		const allLists = _getAllLists();
		let lists;
		if (fieldForVis.data.visibleInLists && fieldForVis.data.visibleInLists.length > 0) {
			lists = allLists.filter(l => fieldForVis.data.visibleInLists.includes(l.id));
		} else if (fieldForVis.data.global || fieldForVis.data.noPathPrefix) {
			lists = [ allLists[0] ];
		} else {
			lists = allLists;
		}
		// console.log('_simpleNode', { node, lists, fieldData, fieldForVis });
		if (lists.length === 0) return null;

		// generate options for sub-paths
		const useOptionSources = !!fieldForVis.data.optionSourcesForPath;
		let subOptions = [];
		if (node.tableRowId) {
			subOptions = [node.tableRowId];
		} else if (useOptionSources) {
			for (const optionSource of fieldForVis.data.optionSourcesForPath) {
				const data = Store.getters['product/getOptionsBySource'](optionSource);
				if (!data) {
					continue;
				}
				subOptions = subOptions.concat(data.map(f => f.value));
			}
		} else {
			subOptions = [null];
		}

		let query = [];

		if (field.data.nestedSearch || field.data.type === 'table' && !SearchFields.find(searchField => searchField.data && searchField.data.path === field.data.path)) {
			for (const list of lists) {
				_pushSearchField(query, list, field, node, tableColumn);
			}
		} else {
			for (const list of lists) {
				for (const optionSource of subOptions) {
					_pushSearchField(query, list, field, node, tableColumn, optionSource);
				}
			}
		}

		return { '$or': query };
	}

	// query that targets the approval history.
	// if a biostandardId was set in the node, use it, otherwise use all biostandards
	function _approvalQuery(node) {
		if (node.rangeType === 'initiated') return _approvalInitiatedQuery(node);
		let query = [];
		const lists = _getAllLists();
		let biostandards = [];
		const basePath = '/data/approvalHistory';

		if (node.biostandardId) {
			biostandards.push(node.biostandardId);
		} else {
			const allStandards = Store.getters['biostandard/all'];
			biostandards = allStandards.map(f => f.id);
		}

		for (const list of lists) {
			for (const standard of biostandards) {
				let nested = {
					'$nested': {
						path: basePath,
						match: {
							'$and': []
						}
					}
				};

				nested.$nested.match.$and.push(
					{
						field: `${basePath}/listId`,
						comparison: 'eq',
						value: list.id
					}
				);

				nested.$nested.match.$and.push(
					{
						field: `${basePath}/biostandardId`,
						comparison: 'eq',
						value: standard
					}
				);

				if (node.rangeType === 'complete') {	//aktuell aktiv
					// find any element for the given list and standard which has no end date set

					nested.$nested.match.$and.push({
						field: `${basePath}/begin`,
						comparison: 'lte',
						value: now,
						format: 'yyyy-MM-dd'
					});
					nested.$nested.match.$and.push({
						field: `${basePath}/end`,
						comparison: 'gte',
						value: now,
						format: 'yyyy-MM-dd'
					});
				} else if (node.rangeType === 'partial_total') {	//im Gesamtzeitraum
					if (node.startDate) {
						nested.$nested.match.$and.push(
							{
								field: `${basePath}/begin`,
								comparison: 'lte',
								value: node.startDate,
								format: 'yyyy-MM-dd'
							}
						);
					}

					if (node.endDate) {
						let or = [{
							field: `${basePath}/end`,
							comparison: 'gte',
							value: node.endDate,
							format: 'yyyy-MM-dd'
						}];
						if (node.startDate) {
							or.push({ $not: {
								field: basePath,
								comparison: 'exists',
								value: 'end'
							}});
						}
						nested.$nested.match.$and.push({ $or: or });
					} else {
						nested.$nested.match.$and.push({ $not: {
							field: basePath,
							comparison: 'exists',
							value: 'end'
						}});
					}
				} else {	//im Teilzeitraum
					if (node.endDate) {
						nested.$nested.match.$and.push(
							{
								field: `${basePath}/begin`,
								comparison: 'lte',
								value: node.endDate,
								format: 'yyyy-MM-dd'
							}
						);
					}

					if (node.startDate) {
						nested.$nested.match.$and.push({ $or: [
							{
								field: `${basePath}/end`,
								comparison: 'gte',
								value: node.startDate,
								format: 'yyyy-MM-dd'
							}, { $not: {
								field: basePath,
								comparison: 'exists',
								value: 'end'
							}}
						]});
					}
				}

				query.push(nested);
			}
		}

		return { '$or': query };
	}

	// find any company with a given role in the index
	// if no role was given, just find the company in the listData array
	function _companyQuery(node) {
		let query = [];
		const lists = _getAllLists();

		if (node.role !== '') {
			const basePath = '/data/indexData';

			for (const list of lists) {
				const path = `${basePath}/${list.id}`;

				query.push({
					field: `${path}/${node.role}/id`,
					comparison: 'eq',
					value: node.value
				});
			}
		} else {
			const basePath = '/data/listData';
			for (const list of lists) {
				const path = `${basePath}/${list.id}/_companies`;

				let nested = {
					$nested: {
						path,
						match: { $or: [] }
					}
				};

				nested.$nested.match.$or.push({
					field: `${path}/company`,
					comparison: 'eq',
					value: node.value
				});

				query.push(nested);
			}
		}

		return { '$or': query };
	}

	// query that targets _approval in lists
	function _approvalInitiatedQuery(node) {
		let query = { '$or': [] };
		let biostandards = [];
		const lists = _getAllLists();
		const basePath = '/data/listData';

		if (node.biostandardId) {
			biostandards.push(node.biostandardId);
		} else {
			const allStandards = Store.getters['biostandard/all'];
			biostandards = allStandards.map(f => f.id);
		}

		for (const list of lists) {
			let subQueryBiostandard = { '$and': [] };
			for (const standard of biostandards) {
				let path = `${basePath}/${list.id}/_approval/${standard}`;
				const isActive = [{
					field: `${path}/active/booleanValue`,
					comparison: 'eq',
					value: true
				}];
				const approvalInitiated = [
					{
						'$or': [
							{
								'$and': [
									{
										field: `${path}`,
										comparison: 'exists',
										value: 'tmp_begin'
									},
									{
										field: `${path}`,
										comparison: 'exists',
										value: 'tmp_end'
									}
								]
							}, {
								'$and': [
									{
										field: `${path}`,
										comparison: 'exists',
										value: 'begin'
									},
									{
										field: `${path}`,
										comparison: 'exists',
										value: 'end'
									}
								]
							}
						]
					}, {
						field: `${path}`,
						comparison: 'exists',
						value: 'approvalInitiatorAccountId'
					}
				];
				const notFinallyApproved = [{
					'$not': {
						field: `${path}/hasEverBeenApproved`,
						comparison: 'eq',
						value: true
					}
				}];
				subQueryBiostandard.$and = subQueryBiostandard.$and.concat(isActive);
				subQueryBiostandard.$and = subQueryBiostandard.$and.concat(approvalInitiated);
				subQueryBiostandard.$and = subQueryBiostandard.$and.concat(notFinallyApproved);
			}
			query.$or.push(subQueryBiostandard);
		}
		return query;
	}

	// re-use an existing query
	function _externalQuery(node) {
		const externalRootId = node.externalRoot;
		const externalSearchRoot = Store.getters['search/byId'](externalRootId);

		if (!externalSearchRoot) {
			return null;
		}

		return generateQueryFromSearchRoot(externalSearchRoot.data);
	}

	function _nestedQuery(node) {
		let nested = [];
		const children = node.children || [];
		for (const child of children) {
			const childSearch = _internalGenerateQuery(child);
			if (childSearch !== null) {
				nested.push(childSearch);
			}
		}

		const operator = `$${node.operator.toLowerCase()}`;
		let obj = {};
		if (operator === '$not') {
		// imply AND
			nested = {
				'$and': nested
			};
		}
		obj[operator] = nested;
		return obj;
	}

	function _tableRowQuery(node) {
		let tableId = node.tableId;
		const searchField = Store.getters['columnset/fieldById'](tableId);

		if (!searchField) {
			return null;
		}
		// console.log('_tableRowQuery', { searchField, node });
		if (Object.hasOwn(searchField.data, 'nestedSearch') && searchField.data.nestedSearch === false) {
			let children = node.children.map(_simpleQuery);
			let operator = `$${node.operator.toLowerCase()}`;
			return { [operator]: children };
		}

		const allLists = _getAllLists();

		let query = [];

		for (const list of allLists) {
			let nested = {
				'$nested': {
					match: {}
				}
			};

			const basePath = searchField.data.pathPrefix || '/data/listData';
			nested.$nested.path = `${basePath}`;
			//for component index
			if (!searchField.data.listIdField) nested.$nested.path += `/${list.id}`;

			if (!searchField.data.preventAppendPath) {
				nested.$nested.path += `/${searchField.data.path}`;
			}

			if (!searchField.data.usePlaceholder) {
				nested.$nested.path += '/table';
			}

			const operator = `$${node.operator.toLowerCase()}`;
			let rows = [];
			nested.$nested.match[operator] = operator === '$not' ? { $and: rows } : rows;

			const children = node.children || [];
			for (const child of children) {
				const fieldData = _getFieldFromNode(child);
				if (!fieldData) {
					continue;
				}

				_pushSearchField(rows, list, searchField, child, fieldData.tableColumn, null, true);

				if (fieldData.tableColumn) {
					const additionalFilter = fieldData.tableColumn.data.filter;
					if (additionalFilter) {
						rows.push({
							field: `${nested.$nested.path}/${additionalFilter.column}`,
							comparison: 'eq',
							value: additionalFilter.value
						});
					}
				}
			}

			query.push(nested);
		}

		return { '$or': query };
	}

	function _internalGenerateQuery(node) {
		if (!node || node._type === 'nop') {
			return _nopQuery();
		} else if (node._type === 'simple') {
			return _simpleQuery(node);
		} else if (node._type === 'external') {
			return _externalQuery(node);
		} else if (node._type === 'approval') {
			return _approvalQuery(node);
		} else if (node._type === 'tablerow') {
			return _tableRowQuery(node);
		} else if (node._type === 'company') {
			return _companyQuery(node);
		} else {
			return _nestedQuery(node);
		}
	}

	// 1. generate main query
	// 2. apply active products filter (selectable for each search)
	// 3. find only products for some given institute and account (selectable for each search)
	// 4. add the ad-hoc table filters as separate AND query
	console.log('generate search', { search, inList, products, institution, accountId });
	const res = _addAdditionalSearchFilters(_wrapResponsibleFilter(_wrapActiveFlag(_internalGenerateQuery(search))));
	//console.log(JSON.stringify(res, null, 2));
	return res;
}
