<script>
import EventBus from '@/util/eventbus.js';
import Message from './message.vue';

let counter = 1;

export default {
	name: 'Messages',

	components: {
		Message
	},

	data() {
		return {
			items: [],
			maxItems: 10
		};
	},

	created() {
		EventBus.$on('message', (title) => {
			this.addItem('success', title);
		});
	},

	methods: {
		addItem(type, title, data) {
			this.items.push({
				n: ++counter,
				type: type || 'success',
				title,
				message: data || ''
			});
			if (this.items.length > this.maxItems) {
				this.items.shift();
			}
		},
		remove(item) {
			let index = this.items.indexOf(item);
			if (index < 0) return;
			this.items.splice(index, 1);
		}
	}
};
</script>

<template>
	<div id="messages">
		<transition-group tag="div" name="message">
			<message
				v-for="item in items"
				:key="item.n"
				v-bind="item"
				@close="remove(item)"
			/>
		</transition-group>
	</div>
</template>

<style lang="scss">
#messages {
	position: fixed;
	z-index: 999;
	left: 0;
	right: 0;
	.message-leave-active {
		transition: all 1s;
	}
	.message-leave-to {
		opacity: 0;
		transform: translateY(-25px);
	}
	.message {
		padding: 3px;
		margin: 3px;
		&.success {
			background: #0a0;
		}
		&.error {
			background: #a00;
			color: #fff;
			font-weight: bold;
		}
		&.warn {
			background: #a52;
		}
		display: flex;
		> header,
		> article {
			flex-grow: 1;
		}
		> header {
			line-height: 25px;
		}
	}

}
</style>
