import { toLocale } from '@/plugins/filters';

export function mapObjectToSelectOption(field) {
	return (obj) => {
		return {
			value: obj.id,
			text: toLocale(obj.data[field])
		};
	};
}

export function getSelectListFromArray(array, titleField, useEmptyValue, emptyKey, emptyText) {
	let data = array.map(mapObjectToSelectOption(titleField));
	/*data = data.sort((a, b) => {
		return a.text > b.text ? 1 : -1;
	});*/

	if (useEmptyValue) {
		data.unshift({
			value: emptyKey,
			text: emptyText
		});
	}

	return data;
}

export function getDefaultSelectListFromArray(array, titleField, useEmptyValue) {
	return getSelectListFromArray(array, titleField, useEmptyValue, null, '-');
}
