import { get, post } from '@/util/request.js';

function sleep(ms) {
	return new Promise(function(resolve) {
		setTimeout(resolve, ms);
	});
}

export async function waitForTicket(ticketNumber, waitingInterval) {
	let start = Date.now();
	do {
		let { done, result, error } = await get('bml/product/getpdfandstatus', { ticketNumber }, null, null, null, true);
		if (error) throw new Error(error.message ? error.message : error);
		if (done) return result;
		await sleep(waitingInterval || 1000);
	} while (Date.now() - start < 1000 * 60 * 45);
	throw new Error('timeout on retrieving pdf');
}

export function createInvoicePdf(params) {
	return post('bml/invoice/createinvoicepdf', params);
}

export async function createPrintReportPdf({ attributeFilter, listId, language, standard, reportType, productId, contactPerson, dateTime } ) {
	let { ticket } = await post('bml/product/pdfprintreport', { attributeFilter, listId, language, standard, reportType, productId, contactPerson, dateTime });
	return ticket;
}

export async function createReregistrationPdf({ attributeFilter, listId, biologicalGardeningListId, language, standard, reportType, company, institution } ) {
	let { ticket } = await post('bml/product/pdfreregistration', { attributeFilter, listId, biologicalGardeningListId, language, standard, reportType, company, institution });
	return ticket;
}

export async function getReregistrationSummary({ attributeFilter, listId, biologicalGardeningListId, language, standard, reportType, institution } ) {
	let summary = await post('bml/product/pdfreregistrationsummary', { attributeFilter, listId, biologicalGardeningListId, language, standard, reportType, institution });
	return summary;
}

export async function* waitForMultiple(tickets) {
	for (let entry of tickets) {
		try {
			let pdf = await waitForTicket(entry.ticket);
			yield { pdf, id: entry.id, name: entry.name };
		} catch (e) {
			yield { error: e, id: entry.id, name: entry.name };
		}
	}
}
