

const state = {

};

const getters = {

	getNotes: (state, getters, rootState, rootGetters) => {
		if (!rootState.product.currentList) return {};
		return rootState.product.workingCopy._notes ?
			rootState.product.workingCopy._notes : [];
	},

	getUnreadNoteCount: (state, getters, rootState, rootGetters) => {
		const notes = getters.getNotes;
		return notes.length;
	}
};

const actions = {

};

const mutations = {

};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
};
