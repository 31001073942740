import * as Repo from '@/repository/auth.js';
import { getEntitiesById } from '@/repository/system.js';
import EventHub from '@/util/eventbus.js';
import notes from './user/notes';
import roles from './user/roles';
import routes from './user/routes';
import { i18n } from '@/main';

const state = {
	accounts: [],
	currentAccount: null
};

const modules = {
	notes,
	roles,
	routes
};

const getters = {

	getAccounts: (state) => () => {
		return state.accounts;
	},

	getCurrentAccount: (state) => () => {
		return state.currentAccount;
	},

	getCurrentAccountId: (state) => {
		return state.currentAccount ? state.currentAccount.id : '';
	},

	getUserInstitutionIds: (state, getters, rootState, rootGetters) => {
		const account = state.currentAccount;
		if (account) {
			const data = rootGetters['auth/org/byAccountId'](account.id);
			return data.map(f => f.id);
		}

		return [];
	},

	isAdmin: (state) => {
		return state.currentAccount && state.currentAccount.staticData.isAdmin;
	}
};

const actions = {

	async loadAccounts({ commit, rootGetters }) {
		const isExpired = rootGetters['auth/login/getIsExpired'];
		if (isExpired) return false;
		commit('resetAccounts');
		const accountIds = await Repo.listAccountsForUser();
		if (!accountIds.length) return false;
		const accounts = await getEntitiesById(accountIds);
		for (const account of accounts) {
			commit('addAccount', account);
		}
		return true;
	},

	async loadAccountData({ commit, getters, dispatch, rootGetters }) {
		let accountDetails = await Repo.getAccountDetails();
		if (!accountDetails) {
			commit('setAccountData', null);
			commit('roles/setRole', null);
			dispatch('routes/setRoutes', { userRole: null });
			console.warn('Could not load account details.');
			return;
		}
		commit('setAccountData', accountDetails);
		commit('roles/setRole', accountDetails);
		const role = getters['roles/getRole']();
		dispatch('routes/setRoutes', { userRole: role });
		if (accountDetails.userData.selectedUiLanguage) {
			commit('base/setUiLanguage', accountDetails.userData.selectedUiLanguage, { root: true });
		}
		if (accountDetails.userData.userLanguagesForMultiLangFields) {
			commit('base/setUserLangForFields', accountDetails.userData.userLanguagesForMultiLangFields, { root: true });
		}
		let searches = accountDetails.userData.searches && accountDetails.userData.searches.length ? accountDetails.userData.searches : [];
		commit('base/setFavoredSearches', searches, { root: true });
		commit('base/setReceiveMailsNotes', { enabled: accountDetails.userData.sendNotificationMailsNotes, email: accountDetails.userData.email }, { root: true });
		commit('base/setReceiveMailsMessages', { enabled: accountDetails.userData.sendNotificationMailsMessages, email: accountDetails.userData.email }, { root: true });
		commit('base/setOverrideMultitextLanguages', { enabled: accountDetails.userData.overrideMultiLangFieldsWithListData }, { root: true });
		if (accountDetails.userData.defaultProductColumns) {
			await dispatch('columnset/loadAll', { ifEmpty: true }, { root: true });
			let obj = rootGetters['columnset/all'].find(e => e.id === accountDetails.userData.defaultProductColumns);
			if (obj) {
				commit('columnset/setSelectedColumnSet', obj, { root: true });
			}
		} else {
			await commit('columnset/resetSelectedColumnSet', {}, { root: true });
		}
		EventHub.emit('auth.updateLists');
	},

	resetMultiLanguageFields({ commit }) {
		if (!state.currentAccount) {
			return;
		}
		if (state.currentAccount.userData.userLanguagesForMultiLangFields) {
			commit('base/setUserLangForFields', state.currentAccount.userData.userLanguagesForMultiLangFields, { root: true });
		}
	},

	async updateUserData({ state, dispatch, rootState }, { selectedUiLanguage, userLanguagesForMultiLangFields, favoredSearches, receiveMailsNotes, receiveMailsMessages, email, defaultProductFilter, defaultProductColumns, overrideMultiLangFieldsWithListData }) {
		let payload = {};
		if (!state.currentAccount) {
			throw new Error('No account set.');
		}

		let hasChanged = false;

		if (selectedUiLanguage) {
			payload.selectedUiLanguage = selectedUiLanguage;
			hasChanged = true;
			i18n.locale = selectedUiLanguage;
		} else {
			payload.selectedUiLanguage = rootState.base.selectedUiLanguage;
		}

		if (userLanguagesForMultiLangFields) {
			payload.userLanguagesForMultiLangFields = userLanguagesForMultiLangFields;
			hasChanged = true;
		} else {
			payload.userLanguagesForMultiLangFields = rootState.base.userLanguagesForMultiLangFields;
		}

		if (favoredSearches) {
			payload.searches = favoredSearches;
			hasChanged = true;
		} else {
			payload.searches = rootState.base.favoredSearches;
		}

		if (typeof receiveMailsNotes !== 'undefined') {
			payload.sendNotificationMailsNotes = receiveMailsNotes;
			hasChanged = true;
		} else {
			payload.sendNotificationMailsNotes = rootState.base.receiveMailsNotes;
		}

		if (typeof receiveMailsMessages !== 'undefined') {
			payload.sendNotificationMailsMessages = receiveMailsMessages;
			hasChanged = true;
		} else {
			payload.sendNotificationMailsMessages = rootState.base.receiveMailsMessages;
		}

		if (typeof overrideMultiLangFieldsWithListData !== 'undefined') {
			payload.overrideMultiLangFieldsWithListData = overrideMultiLangFieldsWithListData;
			hasChanged = true;
		} else {
			payload.overrideMultiLangFieldsWithListData = rootState.base.overrideMultitextLanguages;
		}

		if (defaultProductColumns) {
			payload.defaultProductColumns = defaultProductColumns;
			hasChanged = true;
		} else {
			payload.defaultProductColumns = state.currentAccount.userData.defaultProductColumns;
		}

		if (defaultProductFilter) {
			payload.defaultProductFilter = defaultProductFilter;
			hasChanged = true;
		} else {
			payload.defaultProductFilter = state.currentAccount.userData.defaultProductFilter;
		}

		if (email) {
			payload.email = email;
			hasChanged = true;
		} else if (rootState.base.notificationEmail) {
			hasChanged = true;
			payload.email = rootState.base.notificationEmail;
		}

		if (hasChanged) {
			await Repo.updateAccount(payload);
			await dispatch('loadAccountData');
		}
	},

	async acceptInvite({ rootState, dispatch }, { inviteId, registrationName }) {
		let { ssoToken } = rootState.auth.login;
		let accountId = await Repo.registerWithDomainByInvite(inviteId, registrationName, ssoToken);
		await dispatch('auth/login/switchAccountAndLogin', accountId, { root: true });
	},

	async updateUserEmail({ rootState, dispatch }) {
		let ssoMail = rootState.auth.login.ssoParsed.email;
		let existing = rootState.base.notificationEmail;
		if (ssoMail === existing || !ssoMail) return;
		await dispatch('updateUserData', { email: ssoMail });
	}

};

const mutations = {

	resetAccounts(state) {
		state.accounts = [];
	},

	addAccount(state, account) {
		if (account.data.registrationOptionName !== 'editor'
			|| state.accounts.find(f => f.id === account.id)) {
			return;
		}
		state.accounts.push(account);
	},

	setAccountData(state, account) {
		state.currentAccount = account;
	}

};

export default {
	namespaced: true,
	modules,
	state,
	getters,
	actions,
	mutations
};
