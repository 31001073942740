import * as Repo from '@/repository/messages';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import Vue from 'vue';

const CLASS_ID = 'conversation';

const state = {
	conversations: [],
	attributesetId: '',
	entityclassId: ''
};

const getters = {
	allConversations: (state) => {
		return state.conversations;
	},
	archivedConversations: (state) => {
		return state.conversations.filter(f => f.data.archived);
	},
	nonArchivedConversations: (state) => {
		return state.conversations.filter(f => !f.data.archived);
	},
	conversationById: (state) => (id) => {
		return state.conversations.find(f => f.id === id);
	},
	messagesByConversation: (state) => (id) => {
		const conversation = state.conversations.find(f => f.id === id);
		if (!conversation) {
			return [];
		}

		return conversation.data.messages;
	}
};

const actions = {
	async _loadEntityClass({ state, commit }) {
		if (state.entityclassId) {
			return;
		}
		const ec = await getEntityclassByExternalId(CLASS_ID);
		commit('setEcId', { id: ec.id });
	},
	async _loadAttributeSet({ state, commit }) {
		if (state.attributesetId) {
			return;
		}

		const as = await getAttributesetByExternalId(CLASS_ID);
		commit('setAsId', { id: as.id });
	},
	async loadAllConversations({ state, commit, dispatch, rootGetters }, { productId }) {
		await dispatch('_loadEntityClass');

		const accountId = rootGetters['auth/user/getCurrentAccountId'];

		const list = await Repo.getAllConversationsForUser(state.entityclassId, accountId, productId);
		commit('resetList');
		list.forEach(conversation => commit('addConversation', conversation));
	},
	async reloadConversation({ state, commit, dispatch }, { id }) {
		await dispatch('_loadEntityClass');

		const conversation = await Repo.getSingleConversation(state.entityclassId, id);
		commit('deleteConversation', id);
		commit('addConversation', conversation);
	},
	async create({ state, commit, dispatch }, payload) {
		await dispatch('_loadEntityClass');
		await dispatch('_loadAttributeSet');

		const entity = await Repo.createConversation(payload, state.entityclassId, state.attributesetId);
		commit('addConversation', entity);
		return entity;
	},
	async addMessage({ state, commit, dispatch, getters }, { conversation, payload }) {
		await dispatch('_loadAttributeSet');

		const oldData = JSON.parse(JSON.stringify(getters.conversationById(conversation.id)));
		commit('addSingleMessage', { conversation, payload });
		const newData = getters.conversationById(conversation.id);

		const updated = await Repo.updateConversation(oldData, newData.data, state.attributesetId);
		if (updated) {
			commit('deleteConversation', conversation.id);
			commit('addConversation', updated);
		}
	},
	async archive({ state, commit, dispatch, getters }, { conversation }) {
		await dispatch('_loadAttributeSet');

		const oldEntry = JSON.parse(JSON.stringify(getters.conversationById(conversation.id)));
		await Repo.setArchived(oldEntry, state.attributesetId);
		commit('setArchived', conversation.id);
	}
};

const mutations = {
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	resetList(state) {
		state.conversations = [];
	},
	addConversation(state, conversation) {
		state.conversations.push(conversation);
	},
	addSingleMessage(state, { conversation, payload }) {
		const idx = state.conversations.findIndex(f => f.id === conversation.id);
		state.conversations[idx].data.messages.push(payload);
	},
	deleteConversation(state, id) {
		const idx = state.conversations.findIndex(f => f.id === id);
		state.conversations.splice(idx, 1);
	},
	setArchived(state, id) {
		const idx = state.conversations.findIndex(f => f.id === id);
		Vue.set(state.conversations[idx].data, 'archived', true);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
