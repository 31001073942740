import store from './main.js';
import EventBus from '@/util/eventbus.js';
import { stripUnusedData, rebuildComponents } from '@/util/component-helper.js';

EventBus.on('beforeProductSave', async(workingCopy) => {
	stripUnusedData(workingCopy);
	await store.dispatch('product/uploadDocuments', workingCopy);
});
EventBus.on('beforeProductSaveCleanup', (workingCopy) => {
	rebuildComponents(workingCopy);
});
EventBus.on('afterSwitchList', async() => {
	store.dispatch('product/clearMoveDocuments');
	await store.dispatch('product/display/updateCompanyData');
});
EventBus.on('beforeProductSaveAllLists', async({ id, data, savedList }) => {
	if (store.getters['product/isBaseList'] && store.state.product.workingCopyHasChanges) {
		await store.dispatch('productlist/unlinkNewComponents', { id, data, savedList });
		store.dispatch('productlist/cleanDeletedComponents', { data, savedList });
	}
	// store.dispatch('productlist/syncComponents', { data });
	if (store.getters['product/getDocumentsToMove'].length) {
		store.dispatch('productlist/moveDocumentsToTargetList', {
			documentsToMove: store.getters['product/getDocumentsToMove'],
			data,
			savedList
		});
		store.dispatch('product/clearMoveDocuments');
	}
});

// re-set available lists in product store after account switch or login
EventBus.on('auth.updateLists', () => {
	const allLists = store.getters['auth/user/roles/getAvailableLists'];
	store.commit('product/setAllLists', { lists: allLists });
});

// check and refresh user token and login
EventBus.on('checkToken', async() => {
	await store.dispatch('auth/login/refreshTokenAndLogin');
});
