import * as Repo from '@/repository/labels.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';

const ENTITY_CLASS_ID = 'label';

const state = {

	labels: [],
	attributesetId: '',
	entityclassId: ''

};

const getters = {

	byId: (state) => (id) => {
		return state.labels.find(r => r.id === id);
	},

	byIds: (state) => (ids) => {
		return state.labels.filter(f => ids.includes(f.id));
	},

	all: (state) => {
		return state.labels;
	}

};

const actions = {

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('label');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('label');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createLabel(data, state.entityclassId, state.attributesetId);
		commit('addLabel', entity);
	},

	async loadAll({ commit }, { ifEmpty }) {
		if (ifEmpty && state.labels.length > 0) return;
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('label');
			commit('setEcId', { id: ec.id });
		}
		let label = await Repo.getAllLabels(state.entityclassId);
		commit('resetLabels');
		label.forEach(row => commit('addLabel', row));
	},

	async update({ state, commit, getters }, label) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('label');
			commit('setAsId', { id: ec.id });
		}
		let oldLabel = getters.byId(label.id);
		let updated = await Repo.updateLabel(oldLabel, label.data, state.attributesetId);
		if (updated) {
			commit('deleteLabel', { id: label.id });
			commit('addLabel', updated);
		}
	}

};

const mutations = {

	addLabel(state, entity) {
		state.labels.push(entity);
	},
	resetLabels(state) {
		state.labels = [];
	},
	deleteLabel(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.labels.indexOf(entity);
		state.labels.splice(idx, 1);
	},


	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
