import BaseColumnsetStore from '../columnset/base';
const base = new BaseColumnsetStore('exportset');

const state = {
	...base.state,
};

const getters = {
	...base.getters,
};

const actions = {
	...base.actions,
};

const mutations = {
	...base.mutations,
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
