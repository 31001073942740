import Vue from 'vue';
import moment from 'moment';
import Store from '@/store/main.js';
import { i18n } from '@/main';

export function alphanumericSort(a, b) {
	if (a.text < b.text) {
		return -1;
	}

	if (a.text > b.text) {
		return 1;
	}

	return 0;
}

export function toDate(dateString) {
	return moment(dateString);
}

export function toTimeFormat(date) {
	const format = Store.state.base.userTimeFormat;
	return moment(date).format(format);
}

export function toDateFormat(date) {
	if (!date) {
		return '';
	}

	const locale = window.navigator.language;
	return moment(date).locale(locale).format('L');
}

export function toDateTimeFormat(obj) {
	const format = `L ${Store.state.base.userTimeFormat}`;
	const locale = window.navigator.language;
	return moment(obj).locale(locale).format(format);
}

Vue.filter('dateFormat', toDateFormat);
Vue.filter('timeFormat', toTimeFormat);
Vue.filter('dateTimeFormat', toDateTimeFormat);

export function toLocale(obj, language, fallbackLanguage) {
	const t = typeof obj;
	if (t === 'string' || t === 'number') return obj;
	if (t === 'boolean') return booleanDisplay(obj);
	if (t === 'object' && obj) {
		let best = language || Store.state.product.exports.exportLanguage || Store.state.base.selectedUiLanguage;
		if (obj[best]) return obj[best];
		let fallback = fallbackLanguage || Store.state.base.fallbackLocale;
		if (obj[fallback]) return obj[fallback];
		for (let [ key, value ] of Object.entries(obj)) {
			if (typeof value === 'string' && value.trim().length > 0) {
				return obj[key];
			}
		}
	}
	return '---';	//undefined / null
}
export function toSortable(str) {
	if (typeof str !== 'string') return '';
	return str.toLowerCase()
		.replace('ö', 'o')
		.replace('ä', 'a')
		.replace('ü', 'u');
}

Vue.filter('toLocale', toLocale);

export function booleanDisplay(obj) {
	let booleanValue = false;
	if (typeof obj !== 'undefined') {
		booleanValue = obj;
	}

	if (booleanValue) {
		return i18n.t('admin.crud.yes');
	}

	return i18n.t('admin.crud.no');
}

Vue.filter('toBooleanDisplay', booleanDisplay);

export function invoiceFlagDisplay(product, timeline, flag, returnDate) {
	if (!product || !product.data.invoiceFlags || !product.data.invoiceFlags[flag]) {
		return '';
	}

	if (!returnDate) {
		const flagDisplay = Store.getters['selectoptions/byId'](flag);
		if (flagDisplay) {
			return toLocale(flagDisplay.data.title);
		}

		return '';
	}

	if (timeline) {
		const rev = product.data.invoiceFlags[flag];
		const entry = timeline.find(f => f.rev === rev);
		if (entry) {
			return toDateTimeFormat(entry.updated);
		}
	}

	return '';
}

Vue.filter('toTabDisplay', function(tab) {
	return i18n.t(`product.tabTitles.${tab}`);
});

function genericDisplay(baseData, entry) {
	if (!baseData || baseData.length === 0) return '';
	const actualEntry = baseData.find(x => x.id === entry);
	if (!actualEntry) return '';
	return toLocale(actualEntry.data.title);
}

export function categoryDisplay(category) {
	return genericDisplay(Store.state.category.categories, category);
}

export function componentDisplay(component) {
	return genericDisplay(Store.state.component.components, component);
}

export function hobbygardenDisplay(entry) {
	return genericDisplay(Store.state.hobbygarden.entries, entry);
}

export function treeDisplay(type, value) {
	if (type === 'category') {
		return categoryDisplay(value);
	} else if (type === 'component') {
		return componentDisplay(value);
	} else if (type === 'hobbygarden') {
		return hobbygardenDisplay(value);
	}

	return value;
}

export function selectOptionDisplay(id) {
	return genericDisplay(Store.state.selectoptions.options, id);
}

export function countryDisplay(country) {
	return genericDisplay(Store.state.country.countries, country);
}

export function regionDisplay(region) {
	return genericDisplay(Store.state.region.regions, region);
}

export function labelDisplay(label) {
	return genericDisplay(Store.state.label.labels, label);
}

export function roleDisplay(role) {
	const roleEntry = Store.state.companylist.roles.find(f => f === role);
	if (roleEntry) {
		return i18n.t(`default.companyRoles.${role}`);
	}

	return '';
}

export function biostandardDisplay(value) {
	if (value) {
		let biostandard = Store.getters['biostandard/byId'](value);
		if (biostandard) {
			return toLocale(biostandard.data.name);
		}
	}

	return '-';
}

export function listDisplay(list) {
	const allLists = Store.state.list.lists;
	if (!allLists || allLists.length === 0) return '';
	const currentList = allLists.find(x => x.id === list);
	if (!currentList) return '';
	return toLocale(currentList.data.title);
}

Vue.filter('toCategoryDisplay', categoryDisplay);
Vue.filter('toComponentDisplay', componentDisplay);
Vue.filter('toSelectOptionDisplay', selectOptionDisplay);
Vue.filter('toCountryDisplay', countryDisplay);
Vue.filter('toRegionDisplay', regionDisplay);
Vue.filter('toLabelDisplay', labelDisplay);
Vue.filter('toListDisplay', listDisplay);
Vue.filter('toRoleDisplay', roleDisplay);
Vue.filter('toBiostandardDisplay', biostandardDisplay);

Vue.filter('toNumberFormat', function(val) {
	if (typeof val !== 'number') return val;
	let formatter = new Intl.NumberFormat(Store.state.base.selectedUiLanguage.replace('_', '-'), {
		style: 'percent',
		minimumFractionDigits: 3
	});
	return formatter.format(val / 100.0);
});

export function accountDisplay(accountId) {
	const account = Store.getters['auth/admin/getAccountById'](accountId);

	if (account) {
		return account.data.staticData.fullname;
	}

	return '';
}

Vue.filter('toAccountDisplay', accountDisplay);
