import { get, post } from '@/util/request.js';
import { search, genericEntityUpdate } from './system.js';

export async function createOption(data, entityclassId, attributesetId) {
	if (!data.order) data.order = 0;
	data.order = parseInt(data.order);
	return post('eav/entity/create', {
		entityclass: entityclassId,
		attributeset: attributesetId,
		data
	});
}

export function getAllOptions(entityclassId) {
	return search(entityclassId);
}

export function updateOption(oldEntry, newData, set) {
	if (!newData.order) newData.order = 0;
	newData.order = parseInt(newData.order);
	return genericEntityUpdate(oldEntry, newData, set);
}
