import { toLocale, toSortable } from '@/plugins/filters.js';
import SECRECY_LEVELS from '@/assets/config/secrecy-levels';
import {getSelectListFromArray} from '@/util/select-helper';
import AddressTypes from '@/assets/config/address-types.json';
import CompanyRoles from '@/assets/config/company-roles.json';
import { i18n } from '@/main';
// import Router from '@/router';
let Router;

(async function() {
	Router = await import('@/router');
}());

// Maps an key to an select-list option to clean up the switch case
const KEY_OPTIONSOURCE_MAPPING = {
	'controlbody': 'controlbody',
	'cert-standards': 'cert-standards',
	'risk-phrase': 'risk-phrase',
	'origin': 'origin',
	'quality': 'quality',
	'keeping-type': 'keeping-type',
	'nutrient': 'nutrient',
	'nutrient-fum': 'nutrient-fum',
	'unit': 'unit',
	'person-types': 'person-types',
	'company.type': 'company.type',
	'company.person.title': 'company.person.title',
	'invoice-flags': 'invoice-flags'
};

// maps an given option source key to an fixed getter to return the values
const KEY_GETTER_OPTIONS = {
	'maincategory': 'category/roots',
	'checklist': 'checklist/allActive',
	'institutions': 'auth/org/institutionsByCurrentAccount',
	'countries': 'country/all',
	'lists': 'list/root',
	'permits': 'permit/forListAndCategory',
	'selectoptions': 'selectoptions/all'
};

function replaceSpecials(el) {
	return el.replace(/\[_/g, '')
		.replace(/_\]/g, '')
		.replace(/\[\^/g, '')
		.replace(/\^\]/g, '');
}

const getters = {
	getOptionsBySource: (state, getters, rootState, rootGetters) => (source, exporting) => {
		const sourceValue = KEY_OPTIONSOURCE_MAPPING[source];

		if (sourceValue) {
			const selectOpts = rootGetters['selectoptions/byTypeSorted'](sourceValue);
			if (selectOpts.length === 0) {
				console.warn(`no select options from type "${source}" found`);
				return [];
			}

			return selectOpts.map(sel => {
				return { value: sel.id, text: replaceSpecials(toLocale(sel.data.title)) };
			});
		}

		const getterValue = KEY_GETTER_OPTIONS[source];

		if (getterValue) {
			const options = rootGetters[getterValue];
			if (typeof options === 'function') {
				let opts;
				if (source === 'permits') {
					if (Router.default.currentRoute.name.substr(0, 11) === 'product-tab' && rootState.product && rootState.product.currentList) {
						opts = options(
							rootState.product.currentList.id,
							rootGetters['product/getCurrentMainCategoryId']
						);
					} else {
						opts = rootGetters['permit/all'];
					}
				} else {
					opts = options();
				}
				return getSelectListFromArray(opts, 'title', false).sort((a, b) => a.text.localeCompare(b.text));
			} else {
				return getSelectListFromArray(options, 'title', false).sort((a, b) => a.text.localeCompare(b.text));
			}
		}

		// default behaviour
		switch (source) {
			default:
				throw new Error(`invalid optionSource: ${source}`);

			case 'biostandard':
				let bioopts = rootState.biostandard.biostandards;
				return bioopts.map(bio => {
					return { text: toLocale(bio.data.name), value: bio.id };
				});

			case 'company':
				let companies = rootGetters['companylist/all'];
				if (companies.length === 0) {
					return [];
				}
				return companies.map(sel => {
					return { value: sel.id, text: sel.data.baseData.name };
				});

			case 'company-cache': {
				let companyCache = rootState.productlist.companyCache;
				if (companyCache.length === 0) {
					return [];
				}
				return companyCache.map(sel => {
					return {value: sel.id, text: rootGetters['companylist/getCompanyTitle'](sel.data) };
				});
			}

			case 'company-cache-export': {
				let companyCache = rootState.product.exports.companyCache;
				if (companyCache.length === 0) {
					return [];
				}
				return companyCache.map(sel => {
					return {value: sel.id, text: rootGetters['companylist/getCompanyTitle'](sel.data) };
				});
			}

			case 'company-cache-export-full': {
				let companyCache = rootState.product.exports.companyCache;
				if (companyCache.length === 0) {
					return [];
				}

				return companyCache;
			}

			case 'secrecy-levels':
				return SECRECY_LEVELS.map(f => {
					return {
						value: f,
						text: i18n.t(`default.secrecyLevels.${f}`)
					};
				});

			case 'roles':
				return CompanyRoles.map(f => {
					return {
						value: f,
						text: i18n.t(`default.companyRoles.${f}`)
					};
				});

			case 'users':
				let userOpts = exporting ? rootGetters['auth/admin/getAccountsForAllUsers'] : rootGetters['auth/admin/getAccountsForActiveUsers'];
				userOpts = JSON.parse(JSON.stringify(userOpts));
				userOpts = userOpts.sort((user1, user2) => {
					let name1 = user1.data.staticData.fullname || '';
					let name2 = user2.data.staticData.fullname || '';
					return name1.localeCompare(name2);
				});
				return userOpts
					.map(f => {
						return {
							value: f.id,
							text: f.data.staticData.fullname || f.id
						};
					});

			case 'checklist-status':
				const checklistStatus = rootGetters['checklist/all'];
				let sources = [];
				for (const entry of checklistStatus) {
					const parentId = entry.id;

					for (const row of entry.data.options) {
						sources.push({
							value: row.id,
							text: toLocale(row.title),
							parentRowId: parentId
						});
					}
				}
				return sources;

			case 'checklist-status-nok':
				const checklistNOkStatus = rootGetters['checklist/all'];
				let src = [];
				for (const entry of checklistNOkStatus) {
					const parentId = entry.id;

					for (const row of entry.data.options.filter(f => !f.okay)) {
						src.push({
							value: row.id,
							text: toLocale(row.title),
							parentRowId: parentId
						});
					}
				}
				return src;

			case 'languages':
				return rootGetters['base/getLanguagesForSelect'];

			case 'address-types':
				return AddressTypes.map(f => {
					return {
						value: f,
						text: i18n.t(`default.addressTypes.${f}`)
					};
				});

			case 'restrictionText':
				let list = rootState.restrictiontext.restrictionTexts;
				return list.map(text => {
					return { text: toLocale(text.data.text), value: text.id };
				});

			case 'boolean':
				return [
					{
						value: '',
						text: '-'
					},
					{
						value: 'true',
						text: i18n.t('admin.crud.yes')
					},
					{
						value: 'false',
						text: i18n.t('admin.crud.no')
					}
				];
		}
	},

	getOptions: (state, getters, rootState, rootGetters) => (path, source) => {
		if (source) {
			return getters.getOptionsBySource(source);
		}

		let field = getters.getField(path);
		if (!field) {
			return [];
		}

		let locale = rootState.base.selectedUiLanguage;

		if (field.data.optionSource) {
			//generate from source
			return getters.getOptionsBySource(field.data.optionSource);
		}

		if (!field.data.options) {
			console.warn(`no options in path: ${path}`);
			return [];
		}
		let sortByText = function(a, b) {
			let va = toSortable(a.label);
			let vb = toSortable(b.label);
			return va.localeCompare(vb);
		};
		let isGrouped = getters.hasGroupedOptions(path);
		if (isGrouped) {
			let res = field.data.options
				.filter(opt => opt.enabled)
				.reduce((res, opt) => {
					let grpId = opt.group;
					let grpLabel;
					if (grpId) {
						let grp = field.data.optionGroups.find(g => g.id === grpId);
						if (!grp) throw new Error(`invalid group designation: ${grpId}`);
						grpLabel = toLocale(grp.title);
					} else {
						grpId = '-';
						grpLabel = {};
						grpLabel[locale] = 'NOGROUP';
					}
					let grpContainer = res.find(g => g.id === grpId);
					if (!grpContainer) {
						grpContainer = {
							id: grpId,
							label: grpLabel,
							options: []
						};
						res.push(grpContainer);
					}
					grpContainer.options.push({
						value: opt.id,
						label: toLocale(opt.title)
					});
					return res;
				}, []);
			res.sort(sortByText);
			res.forEach(grp => {
				grp.options.sort(sortByText);
			});
			return res;
		} else {
			return field.data.options
				.filter(opt => opt.enabled)
				.map(opt => {
					return {
						value: opt.id,
						text: toLocale(opt.title)
					};
				})
				.sort(sortByText);
		}
	},

	hasGroupedOptions: (state, getters) => (path, source) => {
		if (source) return false;
		const field = getters.getField(path);
		if (!field || field.data.optionSource) {
			return false;
		}

		return !!field.data.options.find(o => o.enabled && o.group);
	},
};

export default {
	namespaced: false,
	getters
};
