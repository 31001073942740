import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import * as Repo from '@/repository/org.js';
import { toLocale } from '@/plugins/filters';
import * as SearchRepo from '@/repository/system';

const ENTITY_CLASS_ID = 'orgas';
const ATTRIBUTE_SET_ID = 'org';

const state = {
	organisations: [],
	entityClassId: null,
	attributesetId: null,
	fiblUsers: []
};

const getters = {
	byId: (state) => (id) => {
		return state.organisations.find(organisation => organisation.id === id);
	},
	byIds: (state) => (ids) => {
		return state.organisations.filter(f => ids.includes(f.id));
	},
	all: (state) => {
		return state.organisations;
	},
	byAccountId: (state) => (accountId) => {
		return state.organisations.filter(org => org.data.users.includes(accountId));
	},
	institutionsByCurrentAccount: (state, getters, rootState, rootGetters) => {
		if (rootGetters['auth/user/roles/isAdmin']) {
			return state.organisations;
		}

		const currentAccount = rootGetters['auth/user/getCurrentAccount']();
		if (!currentAccount) {
			return [];
		}

		return getters.byAccountId(currentAccount.id);
	},
	getFiblUsers: (state) => {
		return state.fiblUsers;
	}
};

const actions = {

	async loadAll({ commit, state }, { ifEmpty }) {
		if (ifEmpty && state.organisations.length > 0) {
			return;
		}

		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId(ENTITY_CLASS_ID);
			commit('setEntityClassId', entityClass);
		}

		let list = await Repo.getAllOrganisations(state.entityClassId);
		commit('resetList', list);
	},

	async create({ state, commit }, data) {
		if (!state.entityClassId) {
			let ec = await getEntityclassByExternalId(ENTITY_CLASS_ID);
			commit('setEntityClassId', { id: ec.id });
		}

		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId(ATTRIBUTE_SET_ID);
			commit('setAsId', { id: ec.id });
		}

		let entity = await Repo.createOrganisation(data, state.entityClassId, state.attributesetId);
		commit('addOrganisation', entity);
	},

	async update({ state, commit, getters }, entry) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId(ATTRIBUTE_SET_ID);
			commit('setAsId', { id: ec.id });
		}
		let oldEntry = getters.byId(entry.id);
		let updated = await Repo.updateOrganisation(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deleteOrganisation', { id: entry.id });
			commit('addOrganisation', updated);
		}
	},

	async delete({ commit }, id) {
		await Repo.deleteOrganisation(id);
		commit('deleteOrganisation', {id});
	},

	async loadFiblUsers({ state, commit, getters }) {
		if (state.fiblUsers.length) return;
		let ecaccount = await SearchRepo.getEntityclassByExternalId('account');
		let res = await SearchRepo.search(ecaccount.id, undefined, { limit: 900 });
		commit('setFiblUsers', res);
	},

};

const mutations = {
	setEntityClassId(state, { id }) {
		state.entityClassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	resetList(state, l) {
		l = l.sort((a, b) => {
			return toLocale(a.data.title).localeCompare(toLocale(b.data.title));
		});
		state.organisations = l || [];
	},
	addOrganisation(state, entity) {
		state.organisations.push(entity);
	},
	deleteOrganisation(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.organisations.indexOf(entity);
		state.organisations.splice(idx, 1);
	},
	setFiblUsers(state, fiblUsers) {
		state.fiblUsers = fiblUsers;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
