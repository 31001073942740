

export default class ChangeEmitter {

	constructor() {
		this.events = new Map();
		this.cache = new Map();
	}

	on(evt, listener) {
		let match = this._getMatchRegex(evt);
		// console.log('set',evt,match);
		this.events.set(evt, { match, listener });
		this.cache.clear();
	}

	has(evt) {
		return this.events.has(evt);
	}

	remove(evt) {
		this.events.delete(evt);
	}

	emit(evt, data, origEvt) {
		let listeners = this._getMatchingListeners(evt);
		// if (listeners.length) console.log(`emitting ${evt} to ${listeners.length} listeners`);
		for (let listener of listeners) {
			listener(origEvt ? origEvt : evt, data);
		}
	}

	emitAll(evt, data) {
		let parts = evt.split('.');
		for (let i=parts.length; i>0; i--) {
			let re = parts.slice(0, i).join('.');
			this.emit(re, data, evt);
		}
	}

	_getMatchingListeners(evt) {
		if (this.cache.has(evt)) {
			return this.cache.get(evt);
		}
		let res = [];
		for (let [ , { match, listener } ] of this.events) {
			if (this._matchingEvent(match, evt)) {
				res.push(listener);
			}
		}
		// console.log('calculated listeners',evt,res);
		this.cache.set(evt, res);
		return res;
	}

	_getMatchRegex(evt) {
		let reg = evt.replace('.', '\\.')
			.replace('-', '\\-')
			.replace('*', '[^\\.]+');
		return new RegExp(`^${reg}$`, '');
	}

	_matchingEvent(match, evt) {
		return match.test(evt);
	}
}
