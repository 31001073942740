import Vue from 'vue';
import * as Repo from '@/repository/search/search.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';

const CLASS_ID = 'search';

const state = {
	lastSearch: null,
	adhocSearch: null,
	searches: [],
	activeSearch: null,
	searchFilter: null,
	activePageNum: 1,
	attributesetId: '',
	entityclassId: '',
	selection: {
		blacklistMode: true,
		items: []
	},
	additionalSearchFilters: {} // table header search filters
};

const getters = {
	all: (state) => {
		return state.searches;
	},
	byId: (state) => (id) => {
		return state.searches.find(f => f.id === id);
	},
	getActivePageNum: (state) => {
		return state.activePageNum;
	},
	getSearchFilter: (state) => {
		return state.searchFilter;
	},
	getActiveSearch: (state) => {
		return state.activeSearch;
	},
	getAdhocSearch: (state) => {
		return state.adhocSearch;
	},
	getAdditionalSearchFilters: (state) => {
		return state.additionalSearchFilters;
	},
	getAdditionalSearchFilter: (state) => (path) => {
		return state.additionalSearchFilters[path];
	},
	getSelectedItems: (state) => {
		return state.selection.items;
	},
	getBlacklistMode: (state) => {
		return state.selection.blacklistMode;
	},
	getCheckedCount: (state, getters, rootState) => {
		if (state.selection.blacklistMode) {
			return rootState.productlist.productSearchCount - state.selection.items.length;
		}
		return state.selection.items.length;
	},
	getVisible: (state, getters) => {
		return state.searches.filter(s => getters.getIsVisible(s));
	},
	getIsVisible: (state, getters, rootState, rootGetters) => (search) => {
		const isAdmin = rootGetters['auth/user/roles/isAdmin'];

		if (isAdmin || !search.data || !search.data.search || search.data.search.isTemporary) {
			return true;
		}

		const creator = search.data.creator;
		const currentAccountId = rootGetters['auth/user/getCurrentAccountId'];
		if (creator === currentAccountId) {
			return true;
		}

		if (search.data.isPublic) {
			return true;
		}

		const institutions = search.data.visibleForInstitutions || [];
		if (institutions.length === 0) {
			return false;
		}

		const currentInstitutions = rootGetters['auth/user/getUserInstitutionIds'];
		const isInInstitution = currentInstitutions.filter(f => institutions.includes(f)).length > 0;

		return isInInstitution;
	}
};

const actions = {
	async _loadEntityClass({ state, commit }) {
		if (state.entityclassId) {
			return;
		}
		const ec = await getEntityclassByExternalId(CLASS_ID);
		commit('setEcId', { id: ec.id });
	},
	async _loadAttributeSet({ state, commit }) {
		if (state.attributesetId) {
			return;
		}

		const as = await getAttributesetByExternalId(CLASS_ID);
		commit('setAsId', { id: as.id });
	},
	async loadAll({ state, commit, dispatch }, { ifEmpty }) {
		if (ifEmpty && state.searches.length > 0) return;
		await dispatch('_loadEntityClass');

		const list = await Repo.getAllSearches(state.entityclassId);
		commit('resetList', list);
		commit('sortSearches');
	},
	async create({ state, commit, dispatch }, { node, inList, products, institution, accountId, isPublic, visibleForInstitutions, creator }) {
		await dispatch('_loadEntityClass');
		await dispatch('_loadAttributeSet');

		let payload = {
			search: node,
			inList,
			isPublic,
			products,
			visibleForInstitutions,
			creator,
			institution: institution || '',
			accountId: accountId || ''
		};

		delete payload.search.isTemporary;

		const entity = await Repo.createSearch(payload, state.entityclassId, state.attributesetId);
		commit('addSearch', entity);
		commit('sortSearches');
		return entity;
	},
	async update({ state, commit, dispatch, getters }, node) {
		await dispatch('_loadAttributeSet');

		const oldSearch = getters.byId(node.id);
		let payload = {
			search: node.data.search,
			inList: node.data.inList,
			products: node.data.products,
			isPublic: node.data.isPublic,
			institution: node.data.institution || '',
			accountId: node.data.accountId || '',
			visibleForInstitutions: node.data.visibleForInstitutions,
			creator: node.data.creator
		};

		const updated = await Repo.updateSearch(oldSearch, payload, state.attributesetId);
		if (updated) {
			commit('deleteSearch', node.id);
			commit('addSearch', updated);
			commit('sortSearches');

			if (state.activeSearch && state.activeSearch.id === node.id) {
				commit('setActiveSearch', updated);
			}
		}
	},
	async delete({ commit }, node) {
		if (typeof node === 'undefined') return;
		await Repo.deleteSearch(node.id);
		commit('deleteSearch', node.id);
		commit('sortSearches');
	}
};

const mutations = {
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	resetList(state, l) {
		state.searches = l || [];
	},
	addSearch(state, node) {
		state.searches.push(node);
	},
	deleteSearch(state, id) {
		const idx = state.searches.findIndex(f => f.id === id);
		state.searches.splice(idx, 1);
	},
	setAdhocSearch(state, root) {
		state.adhocSearch = root;
		state.activePageNum = 1;
	},
	setActiveSearch(state, root) {
		state.activeSearch = root;
		state.activePageNum = 1;
	},
	setAdditionalSearchFilters(state, filters) {
		state.additionalSearchFilters = filters || {};
	},
	setAdditionalSearchFilter(state, { path, subPath, value }) {
		if (!state.additionalSearchFilters[path]) {
			Vue.set(state.additionalSearchFilters, path, {});
		}

		Vue.set(state.additionalSearchFilters[path], subPath, value);
	},
	clearAdditionalSearchFilter(state, path) {
		if (state.additionalSearchFilters[path]) {
			state.additionalSearchFilters[path] = '';
		}
	},
	resetAdditionalSearchFilters(state) {
		state.additionalSearchFilters = {};
	},
	resetActiveSearch(state) {
		state.activeSearch = null;
		state.searchFilter = null;
		state.additionalSearchFilters = {};
		state.activePageNum = 1;
	},
	setActivePageNum(state, page) {
		state.activePageNum = page;
	},
	setSearchFilter(state, filter) {
		state.searchFilter = filter;
	},
	setBlacklistMode(state, value) {
		state.selection.blacklistMode = value;
		state.selection.items = [];
	},
	setSelectedItems(state, items) {
		state.selection.items = items;
	},
	setLastSearch(state, search) {
		state.lastSearch = search;
	},
	sortSearches(state) {
		if (state.searches.length) {
			state.searches = state.searches.sort((searchA, searchB) => {
				if (!searchA.data.search || !searchA.data.search.label) return 1;
				if (!searchB.data.search || !searchB.data.search.label) return -1;
				return searchA.data.search.label.localeCompare(searchB.data.search.label);
			});
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
