import * as SystemRepository from '@/repository/system.js';

const ENTITY_EXTERNAL_ID = 'category_text';

const state = {

	entityclassId: null,
	attributesetId: null,
	categoryTexts: [],
	checkedBaseline: false,
	checkedRows: [],
	listFilter: {
		title: '',
		description: '',
		lists: [],
		standards: [],
		active: {
			booleanValue: true
		},
		language: 'de_DE',
		category: []
	},
	listTotalCount: 0

};

const getters = {

	byId: (state) => (id) => {
		return state.categoryTexts.find(category => category.id === id);
	},

	getFilterByOption: state => option => {
		if (option === 'active') {
			return state.listFilter[option].booleanValue;
		} else {
			return state.listFilter[option];
		}
	},

	getListFilter: state => filter => {
		return state.listFilter[filter];
	},

	getListAttributeFilter: (state, getters, rootState, rootGetters) => {
		const filter = state.listFilter;
		let $and = [];
		let listFilter = [];
		if (filter.lists && filter.lists.length) {
			listFilter = filter.lists;
		} else {
			listFilter = rootGetters['list/root']
				.filter(list => rootGetters['auth/user/roles/canViewList'](list))
				.map(e => e.id);
		}
		let $or = [];
		if (listFilter.length === 0) listFilter.push('-');
		for (const list of listFilter) {
			$or.push({
				field: '/data/list',
				comparison: 'eq',
				value: list
			});
		}
		$and.push({ $or });
		if (filter.standards.length > 0) {
			$and.push({ $or: filter.standards.map(id => ({
				field: '/data/standard',
				comparison: 'eq',
				value: id
			}))});
		}
		if (filter.title && filter.language) {
			$and.push({
				field: `/data/title/${filter.language}`,
				comparison: 'eq',
				value: filter.title,
				fuzzy: true,
				use: 'ngram'
			});
		}
		if (filter.description && filter.language) {
			$and.push({
				field: `/data/description/${filter.language}`,
				comparison: 'eq',
				value: filter.description,
				fuzzy: true,
				use: 'ngram'
			});
		}
		if (filter.category && Array.isArray(filter.category) && filter.category.length) {
			let orFilter = { $or: [] };
			for (let category of filter.category) {
				let categories = rootGetters['category/byRoot'](category);
				for (let subCategory of categories) {
					orFilter.$or.push({
						field: '/data/category',
						comparison: 'eq',
						value: subCategory.id
					});
				}
			}
			$and.push(orFilter);
		}
		if (filter.active) {
			$and.push({
				field: '/data/active',
				comparison: 'eq',
				value: filter.active.booleanValue
			});
		}
		return { $and };
	},

	byIds: (state) => (categoryTextIds) => {
		if (!categoryTextIds || !Array.isArray(categoryTextIds)) {
			return [];
		}

		return state.categoryTexts.filter(category => categoryTextIds.includes(category.id));
	},

	getAll: (state) => {
		return state.categoryTexts;
	}

};

const actions = {

	async ensureAttributesetEntityclassLoaded({ commit }) {
		if (!state.entityclassId) {
			const ec = await SystemRepository.getEntityclassByExternalId(ENTITY_EXTERNAL_ID);
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			const as = await SystemRepository.getAttributesetByExternalId(ENTITY_EXTERNAL_ID);
			commit('setAsId', { id: as.id });
		}
	},

	async create({ state, commit, dispatch }, data) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		data.category = data.category[0];
		delete data.categoryTextId;
		const newCategoryText = await SystemRepository.genericEntityCreate(state.entityclassId, state.attributesetId, data);
		commit('addCategoryText', { text: newCategoryText });
		return newCategoryText;
	},

	async delete({ commit }, { id }) {
		if (!id) return;
		await SystemRepository.genericEntityDelete({ id });
		commit('deleteCategoryText', { id });
	},

	async load({ dispatch, commit }, { filter, pagination, sorting }) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let list = await SystemRepository.search(state.entityclassId, filter, pagination, sorting);
		let count = await SystemRepository.countEntries(state.entityclassId, filter);
		commit('setListCount', count);
		list.forEach(item => {
			item.data.category = [ item.data.category ];
		});
		commit('setCategoryTexts', { texts: list });
		return list;
	},

	async update({ commit, dispatch, getters }, categoryText) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		categoryText.category = categoryText.category[0];
		let oldEntity = JSON.parse(JSON.stringify(getters.byId(categoryText.categoryTextId)));
		delete categoryText.categoryTextId;
		oldEntity.data.category = oldEntity.data.category[0];
		delete oldEntity.data.categoryTextId;
		const updated = await SystemRepository.genericEntityUpdate(oldEntity, categoryText);
		if (updated) {
			updated.data.categoryTextId = updated.id;
			commit('updateCategoryText', { text: updated });
		}
		return updated;
	}

};

const mutations = {
	setFilterOption(state, { filter, value }) {
		if (filter === 'active') {
			state.listFilter[filter].booleanValue = value;
		} else {
			state.listFilter[filter] = value;
		}
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	addCategoryText(state, { text }) {
		addUpdateCategoryText(state, text);
	},
	updateCategoryText(state, { text }) {
		addUpdateCategoryText(state, text);
	},
	deleteCategoryText(state, { id }) {
		const textIndex = state.categoryTexts.findIndex(categoryText => categoryText.id === id);
		if (textIndex === -1) throw new Error(`entity not found: ${id}`);
		state.categoryTexts.splice(textIndex, 1);
	},
	setCategoryTexts(state, { texts }) {
		if (!texts || !texts.length) {
			state.categoryTexts = [];
			return;
		}
		state.categoryTexts = texts;
	},
	setListCount(state, count) {
		state.listTotalCount = count;
	}
};

function addUpdateCategoryText(state, text) {
	if (!text.id) return;
	const oldTextIndex = state.categoryTexts.findIndex(categoryText => categoryText.id === text.id);
	if (oldTextIndex !== -1) {
		state.categoryTexts.splice(oldTextIndex, 1, text);
	} else {
		state.categoryTexts.push(text);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
