import { post as sendPostRequest } from '@/util/request.js';
import { search, genericEntityUpdate, getEntityById } from './system.js';

export async function createBiostandard(data, entityclassId, attributesetId) {
	return sendPostRequest('eav/entity/create', {
		entityclass: entityclassId,
		attributeset: attributesetId,
		data
	});
}

export function getBiostandardById(id) {
	return getEntityById(id);
}

export async function updateBiostandard(oldBiostandard, newData, set) {
	let updatedBiostandard = await genericEntityUpdate(oldBiostandard, newData, set);
	return updatedBiostandard;
}

export async function deleteBiostandard(id) {
	let deletedBiostandardEntity = await sendPostRequest('eav/entity/disable', id);

	return deletedBiostandardEntity.id;
}

export function getAllBiostandards(entityClassId) {
	return search(entityClassId);
}
