import { post } from '@/util/request.js';
import { genericEntityUpdate } from './system.js';

export async function createFromProducts(invoiceData) {
	let invoice = await post('bml/invoice/createFromProducts', invoiceData);
	return invoice;
}

export function updateInvoice(oldEntry, newData, set) {
	return genericEntityUpdate(oldEntry, newData, set);
}

export function deleteSelection(attributeFilter) {
	return post('bml/invoice/deleteInvoices', { attributeFilter });
}

export function sendSelection(attributeFilter) {
	return post('bml/invoice/sendInvoices', { attributeFilter });
}

export async function findInvoicedSpecialItems({ ids, institutionId }) {
	const endpoint = 'bml/invoice/findinvoicedspecialitems';
	let result = await post(endpoint, { ids, institutionId });
	return result;
}

export async function markForUnindex({ productId, listId, institutionId }) {
	const endpoint = 'bml/invoice/markforunindex';
	let result = await post(endpoint, { productId, listId, institutionId });
	return result;
}

export async function markAsPseudoSent(attributeFilter) {
	const endpoint = 'bml/invoice/markinvoicesaspseudosent';
	let result = await post(endpoint, { attributeFilter });
	return result;
}
