import { post } from '@/util/request.js';
import { search, genericEntityUpdate } from '../system.js';

export async function createSearch(data, entityClassId, attributeSetId) {
	data.search = stringifySearchValues(data.search);
	let payload = {
		entityclass: entityClassId,
		attributeset: attributeSetId,
		data
	};
	let newSearch = await post('eav/entity/create', payload);
	newSearch.data.search = restoreFromStorage(newSearch.data.search);
	return newSearch;
}

export async function updateSearch(oldEntity, newData, set) {
	newData.search = stringifySearchValues(newData.search);
	let updatedSearch = await genericEntityUpdate(oldEntity, newData, set);
	updatedSearch.data.search = restoreFromStorage(updatedSearch.data.search);
	return updatedSearch;
}

export async function deleteSearch(id) {
	const deletedEntity = await post('eav/entity/disable', id);
	return deletedEntity.id;
}

export async function getAllSearches(entityClassId) {
	let savedSearches = await search(entityClassId);
	for (let search of savedSearches) {
		search.data.search = restoreFromStorage(search.data.search);
	}
	return savedSearches;
}

function stringifySearchValues(node) {
	if (node.value && typeof node.value !== 'string') {
		node.value = JSON.stringify(node.value);
	}
	if (node.children && node.children.length) {
		for (let childNode of node.children) {
			childNode = stringifySearchValues(childNode);
		}
	}
	return node;
}

function restoreFromStorage(node) {
	if (node.value) {
		try {
			node.value = JSON.parse(node.value);
		} catch (e) {
			// already a string, do nothing
		}
	}
	if (node.children && node.children.length) {
		for (let childNode of node.children) {
			childNode = restoreFromStorage(childNode);
		}
	}
	return node;
}
