import { createDocument, findIdentical, getDocument } from '@/repository/document-repository.js';
import SECRECY_LEVELS from '@/assets/config/secrecy-levels.json';
import { searchCursorWithGenerator} from '@/repository/system.js';
import { toLocale } from '@/plugins/filters.js';

const state = {

	loadedDocuments: [],	//cache for doc content
	toUpload: [],			//queue to upload on product global save
	documentsToMove: [],	//queue to move documents on product global save
	secrecyLevels: SECRECY_LEVELS

};

const getters = {

	byId: (state) => (id) => {
		return state.loadedDocuments.find(d => d.id === id);
	},

	getDocuments: (state, getters, rootState, rootGetters) => (listId) => {
		if (!rootState.product.currentList) return [];
		let documents = [];
		if (listId === rootState.product.currentList.id) {
			documents = rootState.product.workingCopy._documents ?
				rootState.product.workingCopy._documents.slice(0) : [];
		} else {
			documents = rootState.product.loadedData[listId]
					&& rootState.product.loadedData[listId]._documents ?
				rootState.product.loadedData[listId]._documents.slice(0) : [];
		}
		return documents.sort((a, b) => {
			function getOptionTitle(id) {
				let option = rootGetters['selectoptions/byId'](id);
				return option ? toLocale(option.data.title) : '';
			}
			let titleA = getOptionTitle(a.type);
			let titleB = getOptionTitle(b.type);
			let result = titleA.localeCompare(titleB);
			let data = result ? result : new Date(b.uploadTime) - new Date(a.uploadTime);
			return data;
		});
	},

	getDocumentsToMove: (state) => {
		return state.documentsToMove;
	}

};

const actions = {

	async uploadDocuments({ state, commit, rootGetters }, workingCopy) {
		if (state.toUpload.length === 0) return;
		const path = '_documents';
		const uploadDoc = async function(doc) {
			doc.uploadTime = new Date();
			doc.fileName = doc.fileData.name;
			let existingId = await findIdentical(doc.fileData);
			if (existingId) {
				doc.fileId = existingId;
			} else {
				let newFile = await createDocument(doc.fileData, doc.fileName);
				doc.fileId = newFile.id;
			}
			delete doc.fileData;
			return doc;
		};
		let docs = await Promise.all(state.toUpload.map(uploadDoc));
		let fields = rootGetters['product/getValue'](path);
		if (fields) fields = fields.concat(docs);
		else fields = docs;
		workingCopy[path] = fields;
		commit('product/updateField', { path, value: fields }, { root: true });
		commit('resetUploadDocs');
	},

	async loadDocument({ commit, getters }, { fileId, fileName }) {
		let document = getters.byId(fileId);
		if (!document) {
			document = await getDocument(fileId, fileName);
			commit('addToLoadedDocuments', document);
		}
		return document;
	},

	loadBatch(_, { filter }) {
		let generator = searchCursorWithGenerator(null, filter, 'POST', 'bml/product/search');
		return generator;
	},

	moveDocument({ commit }, moveEntry) {
		commit('addToMovedDocuments', moveEntry);
	},

	clearMoveDocuments({ commit }) {
		commit('resetDocumentsToMove');
	},

};

const mutations = {

	addDocumentToUpload(state, document) {
		state.toUpload.push(document);
	},

	resetUploadDocs(state) {
		state.toUpload = [];
	},

	removeDocFromTemp(state, doc) {
		let idx = state.toUpload.indexOf(doc);
		if (idx === -1) return;
		state.toUpload.splice(idx, 1);
	},

	deleteDocument(state, { listId, document }) {
		state.documents[listId].splice(state.documents[listId].findIndex(d => d === document), 1);
	},

	addToLoadedDocuments(state, doc) {
		state.loadedDocuments.push(doc);
	},

	addToMovedDocuments(state, entry) {
		let index = state.documentsToMove.findIndex(doc => doc.document.fileId === entry.document.fileId && doc.document.uploadTime === entry.document.uploadTime);
		if (index > -1) {
			state.documentsToMove.splice(index, 1, entry)
		} else {
			state.documentsToMove.push(entry);
		}
	},

	resetDocumentsToMove(state) {
		state.documentsToMove = [];
	}

};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
};
