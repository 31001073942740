import { debounce } from "debounce";
import * as SystemRepo from '@/repository/system';
import Store from "@/store/main.js";
import * as ProductRepo from '@/repository/product';
import { createDeferred } from'@/util/flow-helper';

const maxEntitiesPerCall = 400;
const maxProductsPerCall = 200;

const state = {
	idsWanted: [],
	idsWantedProduct: [],
	idsUnavailable: [],
	entities: [],
	promises: {}
};
const getters = {
	byId: state => id => {
		return state.entities.find(e => e && e.id === id);
	}
};
const actions = {
	addRequiredEntity({ state, commit, dispatch }, { id, ec }) {
		if (!id) throw new Error('must give id');
		if(!state.promises[id]) {
			commit('createPromise', id);
			if(ec === 'product') {
				commit('addProductId', id);
				dispatch('debouncedLoadProduct');
			} else {
				commit('addId', id);
				dispatch('debouncedLoad');
			}
		}
		return state.promises[id].promise;
	},
	debouncedLoad: debounce(async function loadRelated({ state, commit, dispatch }) {
		let ids = JSON.parse(JSON.stringify(state.idsWanted));
		if (ids.length === 0) return;
		let res = await SystemRepo.getEntitiesById(ids);
		let unavailable = [];
		res = res.filter((e, i) => {
			if (!e) {
				unavailable.push(ids[i]);
			}
			return !!e;
		});
		if (unavailable.length > 0) {
			commit('addUnavailable', unavailable);
		}
		commit('clearIds', ids);
		commit('addEntities', res);
	}, 100),
	debouncedLoadProduct: debounce(async function loadRelated({ state, commit, dispatch }) {
		let ids = JSON.parse(JSON.stringify(state.idsWantedProduct));
		if (ids.length === 0) return;
		let productIds = ids.slice(0, maxProductsPerCall);
		let restIds = ids.slice(maxProductsPerCall);
		commit('setIdsWantedProduct', restIds);
		let pagination = { limit: maxProductsPerCall };
		let list = [];
		let isFibl = Store.getters["auth/login/currentAccountInstitution"].length > 0;
		if (isFibl) {
			let filter = {
				$or: productIds.map((id) => ({ field: '/id', comparison: 'eq', value: id })),
			};
			list = await ProductRepo.searchProducts(filter, pagination);
		} else {
			let found = await ProductRepo.listProductsForOwner(
				undefined,
				productIds,
				undefined,
				pagination,
			);
			list = found.list;
		}
		let unavailable = ids.filter((id) => {
			return !list.find((entity) => entity.id === id);
		});
		commit('addEntities', list);
		commit('addUnavailable', unavailable);
		if (restIds.length > 0) {
			dispatch('debouncedLoadProduct')
		}
	}, 100),

	clearWantedProducts({ state, commit }, keepIds) {
		if (keepIds && keepIds.length) {
			commit('setIdsWantedProduct', keepIds);
		} else if (state.idsWantedProduct.length > 0) {
			commit('setIdsWantedProduct', []);
		}
	},
};

const mutations = {
	addId(state, id) {
		state.idsWanted.push(id);
	},
	addProductId(state, id) {
		state.idsWantedProduct.push(id);
	},
	addEntities(state, entities) {
		if (entities.length === 0) return;
		state.entities = state.entities.concat(entities);
		for (let ent of entities) {
			let key = ent.id;
			if (key in state.promises) {
				state.promises[key].resolve(ent);
			}
		}
	},
	clearIds(state, ids) {
		state.idsWanted = state.idsWanted.filter(id => !ids.includes(id));
	},
	clearAllIds(state) {
		state.idsWanted = [];
	},
	clearEntities(state) {
		state.entities = [];
	},
	addUnavailable(state, ids) {
		state.idsUnavailable = state.idsUnavailable.concat(ids);
		for (let id of ids) {
			let key = id;
			if (key in state.promises) {
				state.promises[key].reject(new Error('id not found: '+id));
			}
		}
	},
	createPromise(state, id) {
		state.promises[id] = createDeferred();
	},
	setIdsWantedProduct(state, val) {
		state.idsWantedProduct = val;
	}
};


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};

