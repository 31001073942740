import de_DE from './de_DE.json';
import en_GB from './en_GB.json';
import fr_FR from './fr_FR.json';
import sv_SE from './sv_SE.json';

export const languages = {
	de_DE,
	en_GB,
	fr_FR,
	sv_SE
};
