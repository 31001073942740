import Store from '@/store/main';
import { i18n } from '@/main';

export default class ApprovalWorkflowService {
	static _basePath = '_approval';

	constructor(listId, currentAccountId) {
		this._listId = listId;
		this._currentAccountId = currentAccountId;
	}

	static _getPath(id, key) {
		return `${this._basePath}.${id}.${key}`;
	}

	static _getRowValue(item, key) {
		return Store.getters['product/getValue'](this._getPath(item.id, key));
	}

	static _updateField(id, path, value) {
		Store.commit('product/updateField', {
			path: this._getPath(id, path),
			value
		});
		if (path !== 'updatedBy' && Store.getters['product/getValue'](this._getPath(id, 'updatedBy')) !== Store.getters['auth/user/getCurrentAccountId']) {
			Store.commit('product/updateField', {
				path: this._getPath(id, 'updatedBy'),
				value: Store.getters['auth/user/getCurrentAccountId']
			});
		}
	}

	static _getDate(d) {
		d.setHours(d.getHours() + 4);
		return { dateValue: d.toJSON().substr(0, 10) };
	}

	static _getDateObject(dateWrap) {
		if (!dateWrap || !dateWrap.dateValue) {
			return null;
		}

		return new Date(dateWrap.dateValue);
	}

	static _getDateValue(id, key) {
		let dateStr = ApprovalWorkflowService._getRowValue( { id }, key);

		if (dateStr && dateStr.dateValue) {
			dateStr = dateStr.dateValue;
		}

		return dateStr;
	}

	isActiveRow(id) {
		let data = Store.getters['product/getApprovalData'](Store.getters['product/getCurrentListId']);
		let obj = data[id];
		if (!obj) {
			data = Store.getters['product/getApprovalData'](Store.getters['list/baseDataList'].id);
			obj = data[id];
		}
		if (!obj) {
			return false;
		}

		return obj.active && obj.active.booleanValue;
	}

	getInitiator(item) {
		return ApprovalWorkflowService._getRowValue(item, 'approvalInitiatorAccountId') || ApprovalWorkflowService._getRowValue(item, 'updatedBy');
	}

	_hasApprovalDateSet(item, temporary) {
		const prefix = temporary ? 'tmp_' : '';

		const begin = ApprovalWorkflowService._getRowValue(item, `${prefix}begin`);
		const end = ApprovalWorkflowService._getRowValue(item, `${prefix}end`);

		return begin && end;
	}

	hasTemporaryApprovalDateSet(item) {
		return this._hasApprovalDateSet(item, true);
	}

	hasApprovalDateSet(item) {
		return this._hasApprovalDateSet(item, false);
	}

	canInitiateApproval(item, isProductCreatedByNationalPartner) {
		if (!Store.getters['auth/user/roles/canApproveStandards']) {
			return false;
		}

		if (!item.data.baseApproval) {
			return false;
		}

		const base = !this.isApprovalInitiated(item) && !this.hasBeenFinallyApproved(item);
		if (!base) {
			return false;
		}

		const active = ApprovalWorkflowService._getRowValue(item, 'active');
		return active && !isProductCreatedByNationalPartner;
	}

	isApprovalInitiated(item) {
		if (!this.isActiveRow(item.id)) {
			return false;
		}

		if (this.hasApprovalDateSet(item) || this.hasTemporaryApprovalDateSet(item)) {
			const initiator = this.getInitiator(item);
			return !!initiator;
		}

		return false;
	}

	hasBeenFinallyApproved(item) {
		return !!ApprovalWorkflowService._getRowValue(item, 'hasEverBeenApproved');
	}

	isActiveAndApproved(item) {
		return this.isActiveRow(item.id) && this.hasBeenFinallyApproved(item);
	}

	canResetApprovalDate(item) {
		return this.isActiveRow(item.id) && this.hasBeenFinallyApproved(item);
	}

	canRemoveApprovalDate(item) {
		if (!this.canResetApprovalDate(item)) {
			return false;
		}

		return this.hasApprovalDateSet(item);
	}

	canSetFinalApproval(item) {
		if (!this.isActiveRow(item.id)) {
			return false;
		}

		if (this.hasBeenFinallyApproved(item)) {
			return false;
		}

		if (!item.data.baseApproval) {
			return true;
		}

		if (!this.isApprovalInitiated(item)) {
			return false;
		}

		const initiatorAccountId = ApprovalWorkflowService._getRowValue(item, 'approvalInitiatorAccountId') || ApprovalWorkflowService._getRowValue(item, 'updatedBy');
		return initiatorAccountId && this._currentAccountId !== initiatorAccountId;
	}

	getApprovalStateText(item) {
		let state = 'none';
		if (this.hasBeenFinallyApproved(item) && this.hasApprovalDateSet(item)) {
			const data = Store.getters['product/getApprovalData'](this._listId);
			if (ApprovalWorkflowService.checkDateForApproval(data[item.id])) {
				state = 'finallyApproved';
			} else {
				state = 'approvalExpired';
			}
		} else if (this.isApprovalInitiated(item) && this.hasTemporaryApprovalDateSet(item)) {
			state = 'initiated';
		}
		return i18n.t(`product.tabs.approval.state.${state}`);
	}

	initializeApproval(item, begin, end) {
		const itemId = item.id;

		let beginFinal = ApprovalWorkflowService._getDateObject(begin) || new Date();
		let endFinal = ApprovalWorkflowService._getDateObject(end) || new Date();
		if (!end) {
			endFinal.setFullYear(endFinal.getFullYear() + 1);
		}

		ApprovalWorkflowService._updateField(itemId, 'tmp_begin', ApprovalWorkflowService._getDate(beginFinal));
		ApprovalWorkflowService._updateField(itemId, 'tmp_end', ApprovalWorkflowService._getDate(endFinal));
		ApprovalWorkflowService._updateField(itemId, 'approvalInitiatorAccountId', this._currentAccountId);
		ApprovalWorkflowService._updateField(itemId, 'approvalInitiationDate', ApprovalWorkflowService._getDate(new Date()));
	}

	approveFinally(item) {
		const itemId = item.id;

		ApprovalWorkflowService._updateField(itemId, 'hasEverBeenApproved', true);
		ApprovalWorkflowService._updateField(itemId, 'finalApprovalAccountId', this._currentAccountId);
		ApprovalWorkflowService._updateField(itemId, 'finalApprovalDate', ApprovalWorkflowService._getDate(new Date()));

		// copy over the temporary date values.
		const begin = ApprovalWorkflowService._getRowValue(item, 'tmp_begin');
		const end = ApprovalWorkflowService._getRowValue(item, 'tmp_end');

		this.updateApprovalDate(item, begin, end);
	}

	updateApprovalDate(item, begin, end) {
		ApprovalWorkflowService._updateField(item.id, 'begin', begin);
		ApprovalWorkflowService._updateField(item.id, 'end', end);
	}

	overrideApprovalDate(item, begin, end) {
		if (!this.hasBeenFinallyApproved(item)) {
			this.initializeApproval(item, begin, end);
		} else {
			this.updateApprovalDate(item, begin, end);
		}
	}

	removeApprovalDate(item) {
		this.updateApprovalDate(item, null, null);
	}

	static checkDateForApproval(item) {
		if (!item || !item.begin || !item.begin.dateValue) {
			return false;
		}

		const now = Store.getters['product/referenceTime'];
		let begin = new Date(item.begin.dateValue);
		if (begin > now) {
			return false;
		}

		if (item.end && item.end.dateValue) {
			let end = new Date(item.end.dateValue);
			if (end < now) {
				return false;
			}
		}

		return true;
	}

	isApproved(item) {
		const id = item.id;

		const data = Store.getters['product/getApprovalData'](this._listId);
		return ApprovalWorkflowService.checkDateForApproval(data[id]);
	}

	addYearToApproval(item) {
		const itemId = item.id;

		let beginStr = ApprovalWorkflowService._getDateValue(itemId, 'begin');

		// if, for any reason, the approval was not yet accomplished, then do it now.
		if (!beginStr) {
			this.initializeApproval(item);
			this.approveFinally(item);
			return;
		}

		// create the new date object based on the existing end or begin date and add one year.
		const endStr = ApprovalWorkflowService._getDateValue(itemId, 'end');
		let end = new Date(endStr || beginStr);
		end.setFullYear(end.getFullYear() + 1);

		ApprovalWorkflowService._updateField(itemId, 'end', ApprovalWorkflowService._getDate(end));
	}

	setApprovalToOneYear(item) {
		const begin = new Date();
		const end = new Date();
		end.setFullYear(end.getFullYear() + 1);

		this.updateApprovalDate(item, ApprovalWorkflowService._getDate(begin), ApprovalWorkflowService._getDate(end));
	}
}
