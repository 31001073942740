import { v4 as uuid } from 'uuid';
import Store from '@/store/main';
import { toLocale, toDateFormat } from '@/plugins/filters';
import { i18n } from '@/main';

class BaseTreeNode {
	constructor() {
		this.id = uuid();
	}

	setLabel(label) {
		this.label = label;
	}

	setDescription(description) {
		this.description = description;
	}
}

/**
 * Node not containing any filter functionality.
 */
export class NopFilter extends BaseTreeNode {
	constructor() {
		super();
		this._type = 'nop';
	}
}

export class SimpleFilter extends BaseTreeNode {
	constructor() {
		super();
		this._type = 'simple';
		this.tab = 'main';
		this.comparator = 'eq';
		this.value = '';
		// fixedParentField: the parent is provided in-code, not by a tab selection or something else
		this.fixedParentField = false;

		// this._initializeFields();
	}

	/**
	 * Selects the first possible field and, if necessary, table column
	 */
	_initializeFields() {
		const fields = Store.getters['field/forTab'](this.tab);

		this.tableRowId = '';

		if (fields.length > 0) {
			const field = fields[0];
			if (this.tab === 'main') {
				const nameField = Store.getters['field/byPath']('basis-f1');
				if (nameField) {
					this.fieldId = nameField.id;
				}
			} else {
				this.fieldId = field.id;
			}

			if (field.data.type === 'table') {
				const columns = Store.getters['field/byTable'](field.id);

				if (columns.length > 0) {
					const column = columns[0];
					this.tableColumnId = column.id;
				} else {
					this.tableColumnId = '';
				}
			}
		} else {
			this.fieldId = '';
			this.tableColumnId = '';
		}
	}

	setFieldId(fieldId) {
		this.fieldId = fieldId;
	}

	setTab(tab) {
		this.tab = tab;
	}

	setTableColumnId(tableColumnId) {
		this.tableColumnId = tableColumnId;
	}

	setComparator(comparator) {
		this.comparator = comparator;
	}

	setValue(value) {
		this.value = value;
	}
}

export class CombinedFilter extends BaseTreeNode {
	constructor() {
		super();
		this.children = [];
		this.operator = 'AND';
		this._type = 'combined';
	}

	setOperator(operator) {
		this.operator = operator;
	}

	addChild(childNode) {
		this.children.push(childNode);
	}

	static _findClosestParentNode(root, searchNode) {
		if (!root) {
			return null;
		}

		if (root.children && root.children.length) {
			for (const child of root.children) {
				if (child.id === searchNode.id) return root;
				if (child.children && child.children.length) {
					const searchResult = this._findClosestParentNode(child, searchNode);
					if (searchResult) return searchResult;
				}
			}
		}

		return null;
	}

	// Removes a node from the tree by finding its closest parent node.
	static removeNode(root, node) {
		const closestParent = this._findClosestParentNode(root, node);

		if (!closestParent) {
			return;
		}

		const index = closestParent.children.findIndex(f => f.id === node.id);
		closestParent.children.splice(index, 1);
	}
}

export class TableRowFilter extends CombinedFilter {
	constructor() {
		super();
		this._type = 'tablerow';
		this.tableId = '';

		this._initializeFields();
	}

	_initializeFields() {
		const tables = Store.getters['field/byType']('table') || [];
		if (tables.length > 0) {
			this.tableId = tables[0].id;
		}
	}
}

export class ExternalFilter extends BaseTreeNode {
	constructor() {
		super();
		this._type = 'external';
		this.externalRoot = '';
	}

	setExternalRoot(externalRoot) {
		this.externalRoot = externalRoot.id;
	}
}

export class ApprovalFilter extends BaseTreeNode {
	constructor() {
		super();
		this._type = 'approval';
		this.biostandardId = '';
		this.rangeType = 'complete';
		this.startDate = '';
		this.endDate = '';
	}

	static _toString(node) {
		if (node._type !== 'approval') {
			return 'UNKNOWN';
		}

		let parts = [];

		if (node.biostandardId) {
			const biostandard = Store.getters['biostandard/byId'](node.biostandardId);
			if (biostandard) {
				parts.push(`${i18n.t('product.common.standard')}: ${toLocale(biostandard.data.name)}`);
			}
		}

		switch (node.rangeType) {
			case 'complete': {
				pushTranslation('complete');
				break;
			}
			case 'initiated': {
				pushTranslation('initiated');
				break;
			}
			case 'partial_total': {
				pushTranslation('partial_total');
				pushDateStrings(node);
				break;
			}
			case 'partial_partial': {
				pushTranslation('partial_partial');
				pushDateStrings(node);
				break;
			}
			default: {
				pushTranslation('complete');
				break;
			}
		}

		function pushTranslation(type) {
			parts.push(i18n.t(`product.search.searchNode.dateRange.${type}`));
		}
		function pushDateStrings(node) {
			let dateString = '';
			if (node.startDate) {
				dateString += `${i18n.t('product.search.searchNode.dateRange.start')} ${toDateFormat(node.startDate)}`;
			}

			if (node.endDate) {
				dateString += ` ${i18n.t('product.search.searchNode.dateRange.end')} ${toDateFormat(node.endDate)}`;
			}

			parts.push(dateString);
		}

		return parts.join(', ');
	}
}

export class CompanyFilter extends SimpleFilter {
	constructor() {
		super();
		this._type = 'company';
		this.role = '';
	}

	static _toString(node) {
	}
}
