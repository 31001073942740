import { toLocale, toSortable } from '@/plugins/filters.js';

export function traverseToData(container, path) {
	let parts = path.split('.');
	let v = container;
	for (let i=0, ii=parts.length; i<ii; i++) {
		let p = parts[i];
		if (p in v) {
			v = v[p];
		} else {
			return null;
		}
	}
	if (typeof v !== 'undefined' && v !== null) {
		return v;
	} else {
		return null;
	}
}

export function checkEntry(entry, currentListId, currentCategory, tabs) {
	if (entry.data.showInTabs && entry.data.showInTabs.length && tabs && tabs.length) {
		if (!tabs.some(tab => entry.data.showInTabs.includes(tab))) return false;
	}
	if (entry.data.listRestrict && entry.data.listRestrict.length && currentListId && !entry.data.listRestrict.includes(currentListId)) {
		return false;
	}
	if (entry.data.categoryRestrict && entry.data.categoryRestrict.length
		&& (!currentCategory || !entry.data.categoryRestrict.includes(currentCategory.id))) {
		return false;
	}
	return true;
}

const state = {


};

const getters = {

	getChecklistEntries: (state, getters, rootState, rootGetters) => ({ tabs, onlyOptionals, forCompany }) => {
		// console.log('getChecklistEntries', { tabs, onlyOptionals, forCompany });
		return rootState.checklist.entries.filter(entry => {
			if (!entry.data.active) return false;
			if (!forCompany) {
				//filter out wrong tabs
				if (!checkEntry(entry, rootState.product.currentList?.id, rootGetters['product/display/getSelectedMainCategory'], tabs)) {
					return false;
				}
				if (entry.data.modus === 'company') return false;
			} else if (entry.data.modus !== 'company') {
				return false;
			}
			// use onlyOptionals to get the display list for the optionals selection dialogue
			if (onlyOptionals && entry.data.isOptional) {
				if (!forCompany && (entry.data.modus === 'default' || rootGetters['product/isBaseList'])) {
					return true;
				}
				if (forCompany && entry.data.modus === 'company') return true;
			}
			if (!onlyOptionals) {
				return true;
			}
			return false;

		}).sort((a, b) => {
			let va = toSortable(toLocale(a.data.title));
			let vb = toSortable(toLocale(b.data.title));
			return va > vb ? 1 : -1;
		});
	},

	getChecklistApprovalStatus: (state, getters, rootState, rootGetters) => {
		const checklistData = rootGetters['product/getValue']('_checklist');
		const companyLinks = rootGetters['product/getValue']('_companies');
		const relevantProductChecklists = getters.getChecklistEntries({ forCompany: false }).filter(e => e.data.mandatory);
		let baseChecklistData = {};
		if (rootState.product.baseData) {
			baseChecklistData = traverseToData(rootState.product.baseData, '_checklist');
		}
		const isBaseList = rootGetters['product/isBaseList'];
		const uncheckedEntries = [];
		function addProblem(cl) {
			uncheckedEntries.push(toLocale(cl.data.title));
		}
		function checkData(cl, rowData) {
			if (!rowData) {
				if (!cl.data.isOptional) addProblem(cl);
				return;
			}
			let status = cl.data.options.find(o => o.id === rowData.status);
			if (!status.okay) {
				addProblem(cl);
			}
		}
		for (let cl of relevantProductChecklists) {
			let rowData = null;
			if (cl.data.modus === 'reference' && !isBaseList) {
				if (cl.id in baseChecklistData) {
					rowData = baseChecklistData[cl.id];
				}
			} else {
				rowData = checklistData[cl.id];
			}
			checkData(cl, rowData);
		}
		let distributor = Array.isArray(companyLinks) ? companyLinks.find(e => e.role === 'distributor') : null;
		let distributorId = distributor ? distributor.company : null;
		let company = rootGetters['companylist/byId'](distributorId);
		if (company) {
			const relevantCompanyChecklists = getters.getChecklistEntries({ forCompany: true }).filter(e => e.data.mandatory);
			for (let cl of relevantCompanyChecklists) {
				if (!checkEntry(cl, rootState.product.currentList.id, rootGetters['product/display/getSelectedMainCategory'])) {
					continue;
				}
				let rowData = company.data._checklist ? company.data._checklist[cl.id] : null;
				checkData(cl, rowData);
			}
		}
		return uncheckedEntries;
	}
};

const actions = {


};

const mutations = {


};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
};
