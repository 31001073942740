import * as RoleService from '@/util/role.js';

const state = {
	currentRole: null
};

const getters = {
	isAdmin: (state) => {
		return state.currentRole === RoleService.ROLE.Admin;
	},
	isEditor: (state) => {
		return state.currentRole === RoleService.ROLE.Editor;
	},
	getRole: (state) => () => {
		return state.currentRole;
	},

	isCorePartner: (state, getters, rootState, rootGetters) => {
		const currentAccount = rootGetters['auth/user/getCurrentAccount']();
		if (!currentAccount) {
			return false;
		}

		const allInstitutions = rootGetters['auth/org/byAccountId'](currentAccount.id);
		return allInstitutions.filter(f => f.data.corePartner === true).length > 0;
	},

	isNationalPartner: (state, getters) => {
		const isCorePartner = getters.isCorePartner;
		return !isCorePartner;
	},

	isInInstitute: (state, getters, rootState, rootGetters) => (institutes) => {
		if (!institutes || institutes.length === 0) {
			return false;
		}

		const currentInstitutions = rootGetters['auth/user/getUserInstitutionIds'];
		return currentInstitutions.filter(ci => institutes.includes(ci)).length > 0;
	},

	canViewList: (state, getters) => (list) => {
		if (!list) {
			console.warn('No list provided.');
			return false;
		}
		if (getters.isAdmin || getters.isCorePartner) {
			return true;
		}
		return getters.isInInstitute(list.data.institutes) || list.data.isBasedata;
	},

	basicCanEditList: (state, getters, rootState, rootGetters) => (list) => {
		if (!list) {
			return false;
		}
		if (rootGetters['product/isListArchived'](list.id)) {
			return false;
		}
		return true;
	},

	canEditList: (state, getters) => (list, responsibleData) => {
		if (getters.isAdmin) {
			return true;
		}
		if (!getters.basicCanEditList(list)) {
			return false;
		}
		return getters.isResponsibleForProduct(true, responsibleData);
	},

	canViewProduct: (state, getters) => (product, list) => {
		if (getters.isAdmin) {
			return true;
		}
		if (!getters.basicCanEditList(list)) {
			return false;
		}
		// if (!product.data.isNationalPartner && !getters.isInInstitute(list.data.institutes)) {
		// 	return false;
		// }
		if (getters.isCorePartner) {
			return true;
		}
		const listData = product.data.listData[list.id];
		if (!listData) {
			return false;
		}
		let canAccessList = false;
		if (listData._responsible) {
			canAccessList = getters.isResponsibleForProductGiven({ listResponsible: listData._responsible });
		} else {
			canAccessList = getters.isInInstitute(list.data.institutes);
		}
		return canAccessList;
	},

	isResponsibleForProductGiven: (state, getters, rootState, rootGetters) => ({ listResponsible, baseResponsible }) => {
		if (getters.isAdmin) return true;
		const responsibleData = listResponsible || baseResponsible;
		if (!responsibleData) return false;
		const currentInstitutions = rootGetters['auth/user/getUserInstitutionIds'];
		let isResponsible = currentInstitutions.includes(responsibleData.institution);
		return isResponsible;
	},

	isResponsibleForProduct: (state, getters, rootState, rootGetters) => (skipAccount, responsibleData) => {
		if (getters.isAdmin) {
			return true;
		}
		if (!responsibleData) responsibleData = rootGetters['product/getValue']('_responsible');
		if (!responsibleData) {
			console.warn('no responsible data set');
			return false;
		}
		const currentInstitutions = rootGetters['auth/user/getUserInstitutionIds'];
		let isResponsible = currentInstitutions.includes(responsibleData.institution);
		if (!skipAccount && responsibleData.accountId) {
			const currentAccount = rootGetters['auth/user/getCurrentAccount']();
			isResponsible = isResponsible && currentAccount.id === responsibleData.accountId;
		}
		return isResponsible;
	},

	canApproveStandards: (state, getters, rootState, rootGetters) => {
		const currentAccount = rootGetters['auth/user/getCurrentAccount']();
		if (currentAccount) {
			return !!currentAccount.staticData.hasApprovalRight;
		}
		return false;
	},

	isListReadonly: (state, getters) => (list) => {
		return !getters.canEditList(list);
	},

	getAvailableLists: (state, getters, rootState, rootGetters) => {
		const allLists = rootGetters['list/root'];
		return allLists.filter(f => f.data.isBasedata || getters.canViewList(f));
	}

};

const actions = {
};

const mutations = {
	setRole(state, account) {
		state.currentRole = RoleService.accountToRole(account);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
