import { post } from '@/util/request.js';
import { search, genericEntityUpdate } from './system.js';

export function createChecklistEntry(data, entityclassId, attributesetId) {
	return post('eav/entity/create', {
		entityclass: entityclassId,
		attributeset: attributesetId,
		data
	});
}

export function getAllEntries(entityclassId) {
	return search(entityclassId);
}

export function updateEntry(oldEntry, newData, set) {
	return genericEntityUpdate(oldEntry, newData, set);
}

export async function deleteEntry(id) {
	let field = await post('eav/entity/disable', id);
	return field.id;
}
