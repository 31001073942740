<template>
	<div
		id="AccountSelect"
		v-click-outside="hideAccountSelect"
	>
		<b-button @click="toggle">
			<v-icon icon="user-circle" class="m-0" />
			{{ accountName }}
		</b-button>
		<b-card
			class="popout"
			v-show="open"
		>
			<template v-if="hasMultipleAccounts">
				<b-form-group :label="$t('default.accountSelect.account')">
					<b-form-select :options="accounts" v-model="account" />
				</b-form-group>
				<b-button
					v-if="isDev"
					@click="unregister"
					variant="danger"
					class="btn-block"
				>
					{{ $t('default.accountSelect.unregister') }}
				</b-button>
			</template>

			<b-button
				v-if="isLoggedIn"
				@click="goToKeycloak"
				variant="primary"
				class="btn-block"
			>
				{{ $t('default.accountSelect.editKeycloakData') }}
			</b-button>

			<b-button
				v-if="isLoggedIn"
				variant="danger"
				@click="logout"
				class="mt-2 btn-block"
			>
				{{ $t('default.accountSelect.logout') }}
			</b-button>
		</b-card>
	</div>
</template>

<script>
import { unregisterAccount } from '@/repository/auth.js';

export default {
	name: 'AccountSelect',

	data() {
		return {
			open: false
		};
	},

	computed: {

		accounts() {
			return this.$store.getters['auth/user/getAccounts']()
			.filter(account => account.data.active)
			.filter(account => account.data.registrationOptionName === "editor")
			.map(account => {
				return { value: account.id, text: account.data.staticData.fullname };
			});
		},

		account: {
			get() {
				const account = this.$store.getters['auth/user/getCurrentAccount']();
				return account ? account.id : null;
			},
			set(v) {
				this.$store.dispatch('auth/login/switchAccountAndLogin', v);
			}
		},

		accountName() {
			const account = this.$store.getters['auth/user/getCurrentAccount']();
			return account ? account.staticData.fullname : '';
		},

		isLoggedIn() {
			return this.$store.getters['auth/login/isLoggedIn']
				&& !this.$store.getters['auth/login/getIsExpired'];
		},

		hasAccountSelected() {
			return this.$store.getters['auth/login/hasAccountSelected'];
		},

		token() {
			return this.$route.query.token;
		},

		isDev() {
			return process.env.NODE_ENV === 'development';
		},

		hasMultipleAccounts() {
			return this.accounts.length > 1;
		}
	},

	mounted() {
		// if (!this.isLoggedIn) {
		// 	if (!this.token) {
		// 		EventBus.$emit('auth.tokenExpired');
		// 	} else {
		// 		this.$store.commit('auth/login/login', { jwt: this.token });
		// 		this.$store.dispatch('auth/user/loadAccountData', { token: this.token });
		// 	}
		// } else {
		// 	const authToken = this.$store.getters['auth/login/getCurrentToken'];
		//
		// 	if (this.hasAccountSelected) {
		// 		this.$store.dispatch('auth/user/loadAccountData', { token: authToken });
		// 	}
		// 	this.$store.dispatch('auth/user/loadAccounts', { token: authToken, useToken: true });
		// }
	},

	methods: {
		toggle() {
			this.open = !this.open;
		},

		hide() {
			this.open = false;
		},

		hideAccountSelect() {
			if (this.open) this.hide();
		},

		logout() {
			this.$store.dispatch('auth/login/logout');
		},

		async unregister() {
			await unregisterAccount( this.account );
			this.logout();
		},

		async goToKeycloak() {
			let url = await this.$store.getters['auth/login/getKeycloakAccountUrl'];
			window.location.href = url;
		}
	}
};
</script>

<style lang="scss">
#AccountSelect {
	position: relative;
	color: #000;
	> button {
		z-index: 2;
	}
	> .popout {
		z-index: 1100;
		position: absolute;
		top: 30px;
		right: 0;
		width: 240px;
	}
}
</style>
