import { get, post } from '@/util/request.js';
import { search, genericEntityUpdate } from './system.js';

export async function createLabel(data, entityclassId, attributesetId) {
	return post('eav/entity/create', {
		entityclass: entityclassId,
		attributeset: attributesetId,
		data
	});
}

export function getAllLabels(entityclassId) {
	return search(entityclassId);
}

export function updateLabel(oldEntry, newData, changeset) {
	return genericEntityUpdate(oldEntry, newData, changeset);
}
