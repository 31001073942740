import * as Repo from '@/repository/field-repository.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import FieldTypes from '@/assets/config/field-types.json';

const state = {

	fields: [],
	options: [],
	types: FieldTypes,
	attributesetId: '',
	entityclassId: ''

};

const getters = {

	byId: (state) => (id) => {
		return state.fields.find(r => r.id === id);
	},

	byType: (state) => (type) => {
		return state.fields.filter(r => r.data.type === type);
	},

	byPath: (state) => (p) => {
		return state.fields.find(r => r.data.path === p);
	},

	all: (state) => {
		return state.fields;
	},

	selectOptionById: (state) => (id) => {
		return state.options.find(f => f.id === id);
	},

	forTab: (state) => (tab) => {
		return state.fields.filter(f => f.data.displayType === 'tab' && f.data.displayKey === tab);
	},

	byTable: (state) => (parentTableId) => {
		return state.fields.filter(f => f.data.displayType === 'table' && f.data.displayKey === parentTableId);
	},

	emptyInLists: (state) => {
		return state.fields.filter(f => f.data.emptyInLists && f.data.emptyInLists.length);
	},

	getDefaultValue: (state) => (id) => {
		const field = state.fields.find(r => r.id === id);
		if (!field) throw new Error(`Field not found with id: ${id}`);
		switch (field.data.type) {
			case 'multitext': return {};
			case 'textfield': return { textValue: '' };
			case 'select': return { selectValue: '' };
			case 'singleline-multitext': return {};
			case 'singleline-textfield': return { textValue: '' };
			case 'checkbox': return { booleanValue: false };
			case 'table': return { table: [] };
			case 'numberfield': return { numberValue: 0.0 };
			case 'datefield': return { dateValue: '' };
			case 'button': return {};
			case 'multiselect': return { multiselectValue: [] };
			default: throw new Error(`Field type not found: ${field.data.type}, id: ${field.id}`);
		}
	}

};

const actions = {

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('fields');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('fields');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createField(data, state.entityclassId, state.attributesetId);
		commit('addField', entity);
	},

	async loadAll({ commit }, { ifEmpty }) {
		if (ifEmpty && state.fields.length > 0) {
			return state.fields;
		}
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('fields');
			commit('setEcId', { id: ec.id });
		}
		let list = await Repo.getAllFields(state.entityclassId);
		commit('setFields', list);
		commit('setOptions');

		return list;
	},

	async update({ state, commit, getters }, list) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('fields');
			commit('setAsId', { id: ec.id });
		}
		let oldField = getters.byId(list.id);
		let updated = await Repo.updateField(oldField, list.data, state.attributesetId);
		if (updated) {
			commit('deleteField', { id: list.id });
			commit('addField', updated);
		}
	},

	async delete({state, commit}, {id}) {
		await Repo.deleteField(id);
		commit('deleteField', {id});
	},

};

const mutations = {

	addField(state, entity) {
		state.fields.push(entity);
	},
	setFields(state, fields) {
		state.fields = fields;
	},
	deleteField(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.fields.indexOf(entity);
		state.fields.splice(idx, 1);
	},


	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	setOptions(state) {
		state.options = state.fields
			.filter(f => f.data.type === 'select' && typeof f.data.optionSource === 'undefined')
			.map(f => f.data.options)
			.flat();
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
