import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import { createCategory, deleteCategory, getAllCategories, updateCategory } from '@/repository/category-repository.js';
import mainCategories from '@/assets/config/main-categories.json';
import { i18n } from '@/main';

const state = {

	categories: [],
	entityclassId: null,
	attributesetId: null

};

const getters = {

	byKey: (state) => (key) => {
		return state.categories.find(r => r.data.mainCategoryKey === key);
	},

	byId: (state) => (id) => {
		return state.categories.find(r => r.id === id);
	},

	byIds: (state) => (categoryIds) => {
		if (!categoryIds) {
			return [];
		}

		return state.categories.filter(r => categoryIds.includes(r.id));
	},

	mainCategoryFromIds: (state, getters) => (totalEntries) => {
		for (const id of totalEntries) {
			let category = getters.byId(id);
			if(category !== undefined) {
				if (!category.data.parent && category.data.mainCategoryKey) {
					return category;
				}
			}
		}

		return null;
	},

	pathToRoot: (state, getters) => (ids) => {
		if (!ids) {
			return [];
		}

		let entries = ids.slice(0);
		for (const id of ids) {
			const category = getters.byId(id);
			if (category && category.data.parent) {
				let parent = getters.byId(category.data.parent);
				while (parent) {
					if (!entries.includes(parent.id)) {
						entries.push(parent.id);
					}

					parent = getters.byId(parent.data.parent);
				}
			}
		}

		return entries;
	},

	pathToRootOrdered: (state, getters) => (id) => {
		if (!id) {
			return {};
		}

		const entries = {};

		let idx = 0;
		const category = getters.byId(id);

		if (category) {
			entries[idx] = category;
			++idx;

			if (category.data.parent) {
				let parent = getters.byId(category.data.parent);
				while (parent) {
					entries[idx] = parent;
					++idx;

					parent = getters.byId(parent.data.parent);
				}
			}
		}

		return entries;
	},

	numberString: (state, getters) => (id) => {
		let numberString = '';
		if (!id) {
			return '';
		}
		let result = getters.pathToRoot([ id ]);
		if (!result) return '';
		result = result.reverse();
		let index = 0;
		while (result[index]) {
			let category = getters.byId(result[index]);
			numberString = numberString ? `${numberString}-${category.data.order}` : category.data.order;
			index++;
		}
		return numberString;
	},

	byParent: (state) => (id) => {
		return state.categories.filter(r => r.data.parent === id);
	},

	roots: (state) => {
		return state.categories.filter(r => !r.data.parent).sort((a, b) => {
			return a.data.order < b.data.order ? -1 : 1;
		});
	},

	all: (state) => {
		return state.categories;
	},

	byRoot: (state, getters) => (rootId) => {
		return state.categories.filter(category => {
			return getters.pathToRoot([category.id]).includes(rootId);
		});
	}

};

const actions = {

	async create({ state, commit, getters }, { title, parentId, disabled, mainCategoryKey, order, number }) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('category');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('category');
			commit('setAsId', { id: ec.id });
		}

		let entity = await createCategory(title, parentId, disabled, mainCategoryKey, number, state.entityclassId, state.attributesetId, order);
		commit('addCategory', entity);

		if (parentId) {
			const parent = getters.byId(parentId);
			commit('addChild', { parent, child: entity });
		}
	},

	async delete({ commit, dispatch, getters }, { entry }) {
		if (typeof entry === 'undefined') {
			return;
		}

		if (typeof entry.children !== 'undefined') {
			let list = new Set(entry.children);
			for (let child of list) {
				await dispatch('delete', { entry: child });
			}
		}

		await deleteCategory(entry.id);
		commit('deleteCategory', { id: entry.id });

		if (entry.data.parent) {
			const parent = getters.byId(entry.data.parent);
			commit('removeChild', { parent, childId: entry.id });
		}
	},

	async loadAll({ commit, state }, { ifEmpty }) {
		if (ifEmpty && state.categories.length > 0) return;
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('category');
			commit('setEcId', { id: ec.id });
		}
		let list = await getAllCategories(state.entityclassId);
		commit('setList', list);
	},

	async update({ commit, state, getters }, category) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('category');
			commit('setAsId', { id: ec.id });
		}
		let oldCategory = getters.byId(category.id);
		let updated = await updateCategory(oldCategory, category.data, state.attributesetId);
		if (updated) {
			commit('deleteCategory', { id: category.id });
			commit('addCategory', updated);

			if (updated.data.parent) {
				const parent = getters.byId(updated.data.parent);
				commit('removeChild', { parent, childId: updated.id });
				commit('addChild', { parent, child: updated });
			}
		}
	}

};

const mutations = {

	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	addChild(state, { parent, child }) {
		if (!parent.children) {
			parent.children = [];
		}

		parent.children.push(child);
	},
	removeChild(state, { parent, childId }) {
		if (!parent.children) {
			return;
		}

		const idx = parent.children.findIndex(f => f.id === childId);
		parent.children.splice(idx, 1);
	},
	addCategory(state, entity) {
		if (!entity.children) {
			entity.children = [];
		}

		entity.children = getters.byParent(state)(entity.id);
		state.categories.push(entity);
	},
	deleteCategory(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.categories.indexOf(entity);
		state.categories.splice(idx, 1);
	},
	resetList(state) {
		state.categories = [];
	},
	setList(state, categories) {
		categories = categories.sort((a, b) => {
			return a.data.order < b.data.order ? -1 : 1;
		});
		state.categories = categories;

		for (let entity of state.categories) {
			if (!entity.children) {
				entity.children = [];
			}

			entity.children = getters.byParent(state)(entity.id);
		}
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
