import Store from '@/store/main';

const SORT_FIELD_MAPPING = {
	'baseAddressCountry': 'data.indexData.country',
	'data.baseData.owner': 'data.indexData.owner'
};

const MULTI_LANG_FIELDS = ['data.indexData.owner'];

function _generateMultiLangSearchField(column) {
	const currentLanguage = Store.state.base.selectedUiLanguage || Store.state.base.fallbackLocale;

	return `${column}.${currentLanguage}`;
}

export function generateSearchField(sort) {
	if (!sort || !sort.column || !sort.order) {
		return [];
	}

	let actualColumn = sort.column in SORT_FIELD_MAPPING ? SORT_FIELD_MAPPING[sort.column] : sort.column;

	if (MULTI_LANG_FIELDS.includes(actualColumn)) {
		actualColumn = _generateMultiLangSearchField(actualColumn);
	}

	return [ `${actualColumn}:${sort.order}` ];
}
