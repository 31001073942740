import * as Repo from '@/repository/country.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import { toLocale } from '@/plugins/filters';

const state = {

	countries: [],
	attributesetId: '',
	entityclassId: ''

};

const getters = {

	byId: (state) => (id) => {
		return state.countries.find(r => r.id === id);
	},

	all: (state) => {
		return state.countries;
	}

};

const actions = {

	async loadAll({ state, commit }, { ifEmpty }) {
		if (ifEmpty && state.countries.length > 0) return;
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('country');
			commit('setEcId', { id: ec.id });
		}
		let list = await Repo.getAllCountries(state.entityclassId);
		commit('resetCountries', list);
	},

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('country');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('country');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createCountry(data, state.entityclassId, state.attributesetId);
		commit('addCountry', entity);
	},

	async update({ state, commit, getters }, entry) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('country');
			commit('setAsId', { id: ec.id });
		}
		let oldEntry = getters.byId(entry.id);
		let updated = await Repo.updateCountry(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deleteCountry', { id: entry.id });
			commit('addCountry', updated);
		}
	}

};

const mutations = {

	addCountry(state, entity) {
		state.countries.push(entity);
	},
	resetCountries(state, l) {
		l.sort((a, b) => {
			return toLocale(a.data.title).localeCompare(toLocale(b.data.title));
		});
		state.countries = l || [];
	},
	deleteCountry(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.countries.indexOf(entity);
		state.countries.splice(idx, 1);
	},


	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
