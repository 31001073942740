import Vue from 'vue';
import * as Repo from '@/repository/product.js';
import { search, getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import { generateSortFields } from '@/util/search/sort-util';
import {SimpleFilter} from '@/util/search/structures';
import {generateQueryFromSearchRoot} from '@/util/search/query-generator';
import ApprovalWorkflowService from '@/util/approval-workflow-service';
import { deepGet, deepSet } from '@/util/field-helper.js';
import { doNotUnlinkNewLists } from '@/util/component-helper.js';
import { toLocale } from '@/plugins/filters';

const state = {
	products: [],
	productSearchCache: [],
	productSearchCount: null,
	companyCache: [],
	attributesetId: '',
	entityclassId: '',
	sort: null,
	paginationStart: 0,
	paginationLimit: 10
};

const getters = {

	byId: (state) => (id) => {	//component, subproductlist, cloning
		return state.products.find(r => r.id === id);
	},

	all: (state) => {	//unused
		return state.products;
	},

	searchCached: (state) => {
		return state.productSearchCache;
	},

	getDerivedProductId: (state) => (id) => {	//subproductlist
		const product = state.products.find(r => r.id === id);
		if (product && product.data.derivedProductId) {
			return product.data.derivedProductId;
		}

		return null;
	},

	getDerivedProduct: (state, getters) => (id) => {	//component, productui
		const product = getters.byId(id);
		if (!product || !product.data.derivedProductId) {
			return null;
		}

		return getters.byId(product.data.derivedProductId);
	},

	getBaseApproval: (state, getters) => (id) => {	//not used
		const product = getters.byId(id);
		if (!product) throw new Error(`product not loaded: ${id}`);
		if (product.data.baseApproval) {
			return product.data.baseApproval;
		}
		return null;
	},

	isCreatedByNationalPartner: (state, getters) => (id) => {	//by many
		const p = getters.byId(id);
		if (!p) return false;
		return !!p.data.isNationalPartner;
	},

	isInitiatedByNationalPartner: (state, getters) => (id) => {
		const p = getters.byId(id);
		return p && !!p.data.isNationalPartnerInitial;
	},

	getProductsByInstitutions: (state) => (institutions) => {	//nur in notes
		let productsByInstitutions = [];

		for (const product of state.products) {
			if (productsByInstitutions.find(f => f.id === product.id)) {
				continue;
			}

			if (!product.data.listData) {
				continue;
			}

			for (const list of Object.values(product.data.listData)) {
				const responsible = list._responsible;
				if (responsible && responsible.institution && institutions.find(f => f.id === responsible.institution)) {
					productsByInstitutions.push(product);
					break;
				}
			}
		}

		return productsByInstitutions;
	},

	getSort: (state) => {
		return state.sort;
	}
};

const actions = {

	async _setEntityClassId({ state, commit }) {
		if (!state.entityclassId) {
			const ec = await getEntityclassByExternalId('products');
			commit('setEcId', { id: ec.id });
		}
	},

	async _setAttributeSetId({ state, commit }) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('products');
			commit('setAsId', { id: ec.id });
		}
	},

	async create({ commit, dispatch }, data) {
		await dispatch('_setEntityClassId');
		await dispatch('_setAttributeSetId');
		let entity = await Repo.createProduct(data);
		commit('addProduct', entity);

		return entity.id;
	},

	async setDerivedProduct({ commit, dispatch, rootGetters }, { id, derivedProductId }) {
		await dispatch('_setAttributeSetId');
		const result = await Repo.setDerivedProductId(id, derivedProductId, state.attributesetId, rootGetters['field/byPath'], rootGetters['field/getDefaultValue']);
		if (!result) return;
		commit('setDerivedProduct', { id, derivedProductId });
		commit('product/setLoadedList', { full: result.data.listData }, { root: true });
		dispatch('product/switchList', { list: rootGetters['product/getCurrentListId'] }, { root: true });
	},

	async addList({ state, dispatch, rootGetters, commit }, { productId, listId }) {
		if (!productId || !listId) return false;
		await dispatch('_setAttributeSetId');
		let list = rootGetters['list/byId'](listId);
		if (!list) return false;
		const baseListId = rootGetters['list/baseDataList'].id;
		let product = await Repo.loadProductWithCache(productId);
		let oldBaseListData = JSON.parse(JSON.stringify(product.data.listData[baseListId]));
		let fieldsEmptyInList = rootGetters['field/emptyInLists'];
		fieldsEmptyInList = fieldsEmptyInList.filter(field => {
			let parentField = rootGetters['field/byId'](field.data.displayKey);
			return !(parentField && fieldsEmptyInList.find(parent => parent.id === field.data.displayKey));
		});
		try {
			fieldsEmptyInList = fieldsEmptyInList.map(field => {
				let parentField = rootGetters['field/byId'](field.data.displayKey);
				let parentData = null;
				if (field.data.type === 'table') {
					parentData = { table: [] };
					parentField = null;
				} else if (parentField && parentField.data.emptyInLists && parentField.data.emptyInLists.find(emptyInList => emptyInList === listId)) {
					parentData = oldBaseListData[parentField.data.path];
					if (parentData) {
						if (parentData.table && parentData.table.length) {
							for (let currentRow of parentData.table) {
								currentRow[field.data.path] = rootGetters['field/getDefaultValue'](field.id);
							}
						}
					}
				}
				return { path: field.data.path,
					emptyInLists: field.data.emptyInLists,
					defaultValue: parentData ? parentData : rootGetters['field/getDefaultValue'](field.id)};
			});
		} catch (e) {
			console.error(e);
			throw e;
		}
		const result = await Repo.addList(productId, listId, baseListId, state.attributesetId, rootGetters['auth/user/getCurrentAccountId'], fieldsEmptyInList);
		if (!result) return false;
		commit('product/addList', { listId }, { root: true });
		commit('product/setLoadedList', { list, data: result }, { root: true });
		return true;
	},

	async resetNationalPartnerStatus({ state, commit, dispatch }, { id }) {
		await dispatch('_setAttributeSetId');

		await Repo.resetNationalPartnerStatus(id, state.attributesetId);
		commit('resetNationalPartnerStatus', { id });
	},

	async archiveList({ rootGetters, dispatch, commit }, { id, listId, archived }) {
		await dispatch('_setAttributeSetId');

		const baseListId = rootGetters['list/baseDataList'].id;

		if (listId === baseListId) {
			return;
		}

		await Repo.setListArchived(id, listId, archived, state.attributesetId);
		commit('product/setListArchived', { listId, archived }, { root: true });
	},

	async delete({ commit }, { entry }) {
		if (typeof entry === 'undefined') {
			return;
		}

		await Repo.deleteProduct(entry.id);
		commit('deleteProduct', { id: entry.id });
	},

	async _createCompanyCache({ state, commit, dispatch }) {
		if (!state.productSearchCache) {
			return;
		}

		const productListData = state.productSearchCache
			.map(f => Object.values(f.data.listData));

		let ids = [];
		for (const product of productListData) {
			for (const listData of product) {
				if (!('_companies' in listData)) {
					continue;
				}

				ids = ids.concat(listData._companies.map(f => f.company));
			}
		}

		const uniqueIds = [...new Set(ids)];

		const companies = await dispatch('companylist/loadByIds', uniqueIds, { root: true });
		commit('setCompanyCache', companies);
	},

	async search({ state, commit, dispatch }, { filter, pagination, sorting }) {
		let sortObj = sorting ? sorting : state.sort;
		sortObj = generateSortFields(sortObj);

		let list = await Repo.searchProducts(filter, pagination, sortObj);

		if (pagination && list.length < pagination.limit) {
			commit('setCount', { count: list.length + pagination.start });
		} else {
			let count = await Repo.countProducts(filter);
			commit('setCount', { count });
		}
		commit('setProductCache', list);

		await dispatch('_createCompanyCache');
	},

	getProductCount(_, { filter }) {
		return Repo.countProducts(filter);
	},

	async ensureProductInCache({ commit, dispatch }, product) {
		if (typeof product === 'string') {
			product = await dispatch('loadById', product);
			if (product && product.length > 0) {
				commit('addProduct', product[0]);
			}
		} else if (product) {
			commit('addProduct', product);
		}
	},

	async searchByName({ rootGetters }, { name, excludedIds, allowedCategoryId, extraFilter }) {
		// if (!name) return [];
		const filter = {$and: []};
		const baseListId = rootGetters['list/baseDataList'].id;
		if (name) {
			const nameFilter = {
				$and: [{
					$or: [
						{
							field: `/data/listData/${baseListId}/basis-f1`,
							comparison: 'eq',
							use: 'keyword',
							value: name
						},
						{
							field: `/data/listData/${baseListId}/basis-f1`,
							comparison: 'eq',
							use: 'text',
							value: name
						},
						{
							field: `/data/listData/${baseListId}/basis-f1`,
							comparison: 'eq',
							fuzzy: true,
							use: 'ngram',
							value: name
						}
					]
				}]
			};
			filter.$and.push(nameFilter);
		}

		for (const id of excludedIds) {
			const subFilter = {
				$not: {
					'field': '/id',
					'comparison': 'eq',
					'value': id
				}
			};
			filter.$and.push(subFilter);
		}
		if (allowedCategoryId) {
			filter.$and.push({
				field: `/data/listData/${baseListId}/_categoryTree`,
				comparison: 'eq',
				value: allowedCategoryId
			});
		}
		if(extraFilter) {
			filter.$and.push(extraFilter);
		}
		let result = await Repo.searchProducts(filter, { start: 0, limit: 25 }, null);
		if (result && result.length) {
			let exactMatches = result.filter(item => item.data.listData[baseListId] && toLocale(item.data.listData[baseListId]['basis-f1']).toLowerCase() === name.toLowerCase());
			if (exactMatches) {
				let exactMatchesIds = exactMatches.map(match => match.id);
				result = result.filter(item => !exactMatchesIds.includes(item.id));
				result = exactMatches.concat(result);
			}
		}
		return result;
	},

	async loadApplicableAsParent({ state, commit, dispatch, rootGetters }, { productIdExcluded, name }) {
		await dispatch('_setEntityClassId');

		let queryNode;
		let nameFilter;
		const field = rootGetters['columnset/fieldByPath']('basis-f1');
		if (field) {
			queryNode = {};
			queryNode.search = new SimpleFilter();
			queryNode.search.tab = 'main';
			queryNode.search.fieldId = field.id;
			queryNode.search.comparator = 'fuz';
			queryNode.search.value = name;

			nameFilter = generateQueryFromSearchRoot(queryNode);
		}

		const filter = {
			$and: [
				{
					$not: {
						field: '/id',
						comparison: 'eq',
						value: productIdExcluded
					}
				},
			]
		};

		if (queryNode && nameFilter) {
			filter.$and.push(nameFilter);
		}

		const products = await Repo.searchProducts(filter);
		return products;
	},

	async loadById({ state, commit, dispatch }, productId) {
		await dispatch('_setEntityClassId');

		const filter = {
			$and: [
				{
					field: '/id',
					comparison: 'eq',
					value: productId
				}
			]
		};

		const products = await Repo.searchProducts(filter);
		return products;
	},

	async loadByIds({ dispatch }, productIds) {
		if (!productIds.length) return [];
		await dispatch('_setEntityClassId');
		const filter = {
			$or: [
			]
		};
		for (const productId of productIds) {
			if (!productId) {
				continue;
			}
			filter.$or.push(
				{
					field: '/id',
					comparison: 'eq',
					value: productId
				}
			);
		}
		const products = await Repo.searchProducts(filter, { start: 0, limit: productIds.length });
		return products;
	},

	async loadReferencingProducts({ dispatch, rootGetters }, { productId }) {
		let baseListId = rootGetters['list/baseDataList'].id;
		await dispatch('_setEntityClassId');
		const filter = {
			field: `/data/listData/${baseListId}/_components/additional/_id`,
			comparison: 'eq',
			value: productId
		};
		return Repo.searchProducts(filter, { limit: 250 });
	},

	async getProductsByInstitutions({ commit, rootGetters, dispatch }, { institute, user, list }) {
		await dispatch('_setEntityClassId');

		let lists = rootGetters['list/root'];
		let filter = { $or: [] };
		function addForList(listId) {
			let res = [];
			res.push({
				field: `/data/listData/${listId}/_responsible/institution`,
				comparison: 'eq',
				value: institute
			});
			if (user) {
				res.push({
					field: `/data/listData/${listId}/_responsible/account`,
					comparison: 'eq',
					value: user
				});
			}
			res.push({
				field: `/data/listData/${listId}`,
				comparison: 'exists',
				value: '_notes'
			});
			return { $and: res };
		}
		if (list) {
			filter.$or.push(addForList(list));
		} else {
			for (let l of lists) {
				filter.$or.push(addForList(l.id));
			}
		}
		let res = await search(state.entityclassId, filter);
		res.forEach(row => commit('addProduct', row));
		return res;
	},

	async addYearToApprovalsInAllLists({ state, dispatch, rootGetters }, { productId }) {
		await dispatch('_setEntityClassId');

		const product = await Repo.loadProductWithCache(productId);
		if (!product) {
			throw new Error(`Product with ID ${productId} not found.`);
		}
		const baseListId = rootGetters['list/baseDataList'].id;
		const baseListData = product.data.listData[baseListId];
		const relevantLists = rootGetters['auth/user/roles/getAvailableLists'].filter(list => {
			let listData = product.data.listData[list.id] || {};
			return rootGetters['auth/user/roles/isResponsibleForProductGiven']({
				listResponsible: listData._responsible,
				baseResponsible: baseListData._responsible
			});
		});
		const sortedStandards = rootGetters['biostandard/sortedByPublicOrder'];

		function checkIfCurrentlyApproved(bsId, listId, base) {
			if (!(product.data.listData[listId] && product.data.listData[listId]._approval && product.data.listData[listId]._approval[bsId])) {
				if (!base) {
					// console.log('cannot find standard', product.data.listData[listId]);
					return checkIfCurrentlyApproved(bsId, baseListId, true);
				} else {
					// console.log('cannot find standard', product.data.listData[listId]);
					return false;
				}
			}
			let approvalData = product.data.listData[listId]._approval[bsId];
			if (!approvalData) {
				// console.log('no approval data');
				return false;
			}
			// if (!(approvalData.active && approvalData.active.booleanValue)) console.log('not active');
			return approvalData.active && approvalData.active.booleanValue && ApprovalWorkflowService.checkDateForApproval(approvalData);
		}

		let hasProcessed = false;
		const currentAccountId = rootGetters['auth/user/getCurrentAccountId'];
		for (const relevantList of relevantLists) {
			const listId = relevantList.id;
			const listData = product.data.listData[listId];
			if (!listData) continue;
			const listBase = rootGetters['list/byId'](listId);
			const biostandards = listBase.data.biostandards;
			const relevantApprovals = sortedStandards.filter(b => biostandards.includes(b.id));
			for (const approvalBase of relevantApprovals) {
				let dependentOnApprovals = rootGetters['biostandard/getDependencyLine'](approvalBase.id);
				dependentOnApprovals.push(approvalBase.id);
				// console.log('dependent standards', dependentOnApprovals);
				let allValid = true;
				for (let bsId of dependentOnApprovals) {
					let valid = checkIfCurrentlyApproved(bsId, listId);
					if (!valid) {
						allValid = false;
						// console.log('failed dependncy check with ', bsId);
						break;
					}
				}
				if (!allValid) continue;
				const pathPrefix = `data.listData.${listId}._approval.${approvalBase.id}.`;
				const beginPathParts = `${pathPrefix}begin.dateValue`.split('.');
				const endPathParts = `${pathPrefix}end.dateValue`.split('.');
				const updatedByPathParts = `${pathPrefix}updatedBy`.split('.');
				const begin = deepGet(product, beginPathParts);
				const end = deepGet(product, endPathParts);
				if (begin && end) {
					const endDate = new Date(end);
					endDate.setFullYear(endDate.getFullYear() + 1);
					deepSet(product, endPathParts, ApprovalWorkflowService._getDate(endDate).dateValue);
					deepSet(product, updatedByPathParts, currentAccountId);
					// console.log('setting', endPathParts, endDate);
					hasProcessed = true;
				}
			}
		}

		let updatedProduct = null;
		if (hasProcessed) {
			await dispatch('_setAttributeSetId');
			updatedProduct = await Repo.saveProductWrap(productId, product.data, state.attributesetId);
		}
		return updatedProduct;
	},

	loadProductWithCache({ state }, { productId }) {
		return Repo.loadProductWithCache(productId);
	},

	async saveProductWrap({ state, dispatch }, { product }) {
		await dispatch('_setAttributeSetId');
		await Repo.saveProductWrap(product.id, product.data, state.attributesetId);
	},

	moveDocumentsToTargetList(_, { data, documentsToMove, savedList }) {
		const listData = data.listData;
		for (const documentToMove of documentsToMove) {
			if (!listData[documentToMove.targetListId]) {
				throw new Error(`List missing: ${documentToMove.targetListId}`);
			}
			// remove from working copy
			if (!documentToMove.onlyCopy) {
				const baseList = listData[savedList];
				const index = baseList._documents.findIndex(document => documentToMove.document.uploadTime === document.uploadTime && documentToMove.document.fileId === document.fileId);
				if (index > -1) {
					baseList._documents.splice(index, 1);
				}
			}
			// add to target list
			if (!listData[documentToMove.targetListId]._documents) listData[documentToMove.targetListId]._documents = [];
			listData[documentToMove.targetListId]._documents.push(documentToMove.document);
		}
	},

	async unlinkNewComponents({ rootGetters }, { id, data, savedList }) {
		const newBaseData = data.listData[savedList];
		if (!newBaseData._components || !rootGetters['product/isBaseList']) return;
		const product = await Repo.loadProductWithCache(id);
		const baseListId = rootGetters['list/baseDataList'].id;
		const oldBaseData = product.data.listData[baseListId];
		let otherListIds = Object.keys(data.listData).filter(listId => listId !== baseListId);

		function _initComponents(data) {
			if (!data._components) {
				data._components = { base: [], additional: [] };
				return;
			}
			if (!data._components.base) {
				data._components.base = [];
			}
			if (!data._components.additional) {
				data._components.additional = [];
			}
		}
		//listData is from repo cache. repocache is wiped during save
		//product/setLoadedList probably not

		function _unlinkComponentsForType(type) {
			if (!newBaseData._components[type]) return;
			let oldComponentIds = oldBaseData._components && oldBaseData._components[type] ? oldBaseData._components[type].map(comp => comp._id) : [];
			const componentsAdded = [];
			for (const component of newBaseData._components[type]) {
				if (!oldComponentIds.includes(component._id)) {
					let secrecy = component.secrecy;
					if (secrecy) secrecy = secrecy.selectValue;
					if (secrecy !== 'base-data-responsible') secrecy = 'core-partners';
					componentsAdded.push([component._id, secrecy]);
				}
			}

			for (const [newId, newSecrecy] of componentsAdded) {
				for (const otherListId of otherListIds) {
					if (doNotUnlinkNewLists.includes(otherListId)) continue;
					_initComponents(data.listData[otherListId]);
					if (!data.listData[otherListId]._components[type].find(comp => comp._id === newId)) {
						// add new component to target list
						const unlinkedComponentData = {
							_id: newId,
							secrecy: {
								selectValue: newSecrecy
							}
						};
						data.listData[otherListId]._components[type].push(unlinkedComponentData);
					}
				}
			}
		}

		_initComponents(newBaseData);
		_unlinkComponentsForType('base');
		_unlinkComponentsForType('additional');
	},

	cleanDeletedComponents({ rootGetters }, { data, savedList }) {
		if (!rootGetters['product/isBaseList']) return;
		const newBaseData = data.listData[savedList];
		const baseListId = rootGetters['list/baseDataList'].id;
		for (let [ listId, listData ] of Object.entries(data.listData)) {
			if (listId === baseListId) {
				continue;
			}
			if (listData._components) {
				for (let type of [ 'base', 'additional' ]) {
					if (listData._components[type] && listData._components[type].length) {
						let baseCopy = JSON.parse(JSON.stringify(listData._components[type]));
						for (let component of baseCopy) {
							let existsInBaseList = newBaseData._components[type] && newBaseData._components[type].find(entry => entry._id === component._id);
							if (!existsInBaseList) {
								let index = listData._components[type].indexOf(component);
								listData._components[type].splice(index, 1);
							}
						}
					}
				}
			}
		}
	},

	moveList(_, params) {
		return Repo.moveList(params);
	},

	aggregateSearch(_, params) {
		return Repo.aggregateSearch(params);
	}

};

const mutations = {

	addProduct(state, entity) {
		let idx = state.products.findIndex(p => p.id === entity.id);
		if (idx > -1) state.products.splice(idx, 1);
		state.products.push(entity);
	},
	deleteProduct(state, { id }) {
		let idx = state.products.findIndex(p => p.id === id);
		if (idx === -1) throw new Error(`entity not found: ${id}`);
		state.products.splice(idx, 1);
	},
	updateProductList(state, { id, list, data, full }) {
		let prod = state.products.find(p => p.id === id);
		if (!prod) return;
		if (full) {
			prod.data.listData = full;
		} else {
			prod.data.listData[list] = data;
		}
	},
	setDerivedProduct(state, { id, derivedProductId }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		const idx = state.products.indexOf(entity);
		Vue.set(state.products[idx].data, 'derivedProductId', derivedProductId ? derivedProductId : '');
	},
	resetNationalPartnerStatus(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);

		const idx = state.products.indexOf(entity);
		Vue.delete(state.products[idx].data, 'isNationalPartner');
	},
	resetProducts(state) {
		state.products = [];
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	setCount(state, { count }) {
		state.productSearchCount = count;
	},
	setProductCache(state, entries) {
		state.productSearchCache = entries;
	},
	addProductToSearchCache(state, entity) {
		state.productSearchCache.push(entity);
	},
	setSort(state, sort) {
		state.sort = sort;
	},
	setCompanyCache(state, companies) {
		state.companyCache = companies;
	},
	setPagination(state, { start, limit }) {
		if (!isNaN(start)) state.paginationStart = start;
		if (!isNaN(limit)) {
			state.paginationLimit = limit;
			state.paginationStart = 0;
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
