<template>
	<div
		class="message"
		:class="cls"
		@click="close"
	>
		<header>{{ title }}</header>
		<article v-show="message">
			{{ message }}
		</article>
		<button class="faded" @click="pin">
			<span v-html="pinSign" />
		</button>
	</div>
</template>

<script>

export default {
	name: 'Message',

	props: {
		title: {
			required: true,
			type: String
		},
		message: {
			default: '',
			type: String
		},
		type: {
			default: 'success',
			type: String
		}
	},

	data() {
		return {
			pinned: false,
			timeToClose: this.type === 'success' ? 1000 : 5000
		};
	},

	computed: {
		pinSign() {
			return this.pinned ? '&dArr;' : '&lArr;';
		},
		cls() {
			switch (this.type) {
				default:
					return 'success';

				case 'success':
				case 'error':
				case 'warn':
					return this.type;
			}
		}
	},

	created() {
		setTimeout(() => {
			this.close();
		}, this.timeToClose);
	},

	methods: {
		close() {
			if (this.pinned) return;
			this.$emit('close');
		},
		pin() {
			this.pinned = !this.pinned;
		}
	}
};
</script>
