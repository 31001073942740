import EventBus from '@/util/eventbus.js';
import Vue from 'vue';
import { i18n } from '@/main.js';

export function transformErrorObject(err) {
	let lines = [];
	if (err.code === 503) {
		lines.push(i18n.t('default.common.error.backendMaintenance'));
		return lines;
	}
	if (err.code) {
		lines.push(`Code: ${err.code}`);
	}
	if (err.reqId) {
		lines.push(`Request ID: ${err.reqId}`);
	}
	if (err.url) {
		lines.push(`Request URL: ${err.url}`);
	}
	if (err.message) {
		lines.push(`Message: ${err.message}`);
	}
	return lines;
}


if (process.env.NODE_ENV !== 'development') {
	window.onerror = function(message, source, lineno, colno, error) {
		EventBus.$emit('error', error);
		console.log(message, error);
	};

	Vue.config.errorHandler = function(err, vm, info) {
		EventBus.$emit('error', err);
		console.log(err, info);
	};
}
