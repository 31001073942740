import { post } from '@/util/request.js';

export async function getTemplateByName(name) {
	return await post('batch/template/getbyname', name);
}

export async function enqueueJob(templateId, attributeFilter, flagId) {
	const payload = {
		template: templateId,
		config: {
			flagId
		}
	};

	if (attributeFilter && Object.keys(attributeFilter).length > 0) {
		payload.attributeFilter = attributeFilter;
	}

	// get the actual job id.
	return await post('batch/job/run', payload);
}

export async function getJobStatusById(jobId) {
	return await post('batch/job/checkstatus', jobId);
}
