import DisplayModule from '@fibl/bml-display';
const Display = DisplayModule.display.default;

const state = {
	categoryPath: '_category',
	usedCompanies: [],
	usedRestrictionTexts: [],
	display: null,
};

const PROPERTY_VALUE = 'value';
const PROPERTY_OVERRIDE = 'override';


const getters = {
	/**
	 * Returns an array of all products currently held in memory or an empty array.
	 */
	getAllProducts: (state, getters, rootState, rootGetters) => {
		const currentProductId = rootGetters['product/getCurrentProductId'];

		if (typeof currentProductId === 'undefined') {
			console.warn('No product ID defined!');
			return [1];
		}

		return [ currentProductId ];
	},

	/**
	 * Returns an array of all product standards currently held in memory or an empty array.
	 */
	getAllStandards: (state, getters, rootState, rootGetters) => {
		return rootGetters['biostandard/all'];
	},

	/**
	 * Gets all displayed fields.
	 */
	getDisplayFields: (state) => () => {
		return state.display.getDisplayFields();
	},

	/**
	 * Returns an array with all field data.
	 */
	getFieldData: (state, getters, rootState, rootGetters) => {
		return rootGetters['field/all'];
	},

	/**
	 * Returns an array with all permit data.
	 */
	getPermitData: (state, getters, rootState, rootGetters) => {
		return rootGetters['permit/all'];
	},

	/**
	 * Returns an array with all component data.
	 */
	getComponentData: (state, getters, rootState, rootGetters) => {
		return rootGetters['component/all'];
	},

	/**
	 * Returns an array with all biostandard data.
	 */

	getBiostandardData: (state, getters, rootState, rootGetters) => {
		return rootGetters['biostandard/all'];
	},

	/**
	 * Returns an array with all approval data.
	 */

	getApprovalData: (state, getters, rootState, rootGetters) => {
		if (!rootState.product.currentList) return [];
		const listId = rootState.product.currentList.id;
		return rootGetters['product/getApprovalDataWithParent']({ listId });
	},

	getCategoryData: (state, getters, rootState, rootGetters) => {
		return rootGetters['category/all'];
	},

	getRestrictionTextData: (state) => {
		return state.usedRestrictionTexts;
	},

	getCompanyBaseData: (state) => {
		return state.usedCompanies;
	},

	getCompanyData: (state, getters, rootState, rootGetters) => {
		return rootGetters['product/getValue']('_companies');
	},

	/**
	 * Returns the actual main category of the selected category in the category tree.
	 */
	getSelectedMainCategory: (state, getters, rootState, rootGetters) => {
		const v = rootGetters['product/getValue'](state.categoryPath);
		if (!v) {
			return null;
		}
		const totalEntries = rootGetters['product/getTotalSelection']('category', JSON.parse(JSON.stringify(v)));
		for (const id of totalEntries) {
			let category = rootGetters['category/byId'](id);
			if (!category.data.parent && category.data.mainCategoryKey) {
				return category;
			}
		}
		return null;
	},

	getPrintLanguage: (state, getters, rootState) => () => {
		return rootState.base.selectedUiLanguage;
	},

	getFallbackLanguage: (state, getters, rootState) => () => {
		return rootState.base.fallbackLocale;
	},

	getPrintStandard: (state, getters, rootState, rootGetters) => () => {
		return rootGetters['product/getValue']('_display.selectedStandard');
	},

	getProductData: (state, getters, rootState, rootGetters) => () => {
		let workingCopy = JSON.parse(JSON.stringify(rootGetters['product/getWorkingCopy']));

		if (!workingCopy._components) {
			workingCopy._components = {};
		}

		if (!workingCopy._components.base) {
			workingCopy._components.base = [];
		} else {
			workingCopy._components.base = Object.values(workingCopy._components.base);
		}

		// merge additional components
		if (workingCopy._components.additional) {
			for (let v of Object.values(workingCopy._components.additional)) {
				workingCopy._components.base.push(v);
			}
		}

		workingCopy._components.base = Object.values(workingCopy._components.base).filter(e => e.secrecy && (e.secrecy === 'public' || e.secrecy.selectValue === 'public'));

		return workingCopy;
	},

	/**
	 * Get default display value for field (shown above the field)
	 */
	getDisplayValue: (state) => (language, fieldKey) => {
		return state.display.getDisplayValue(language, fieldKey);
	},

	getHeaderValue: (state) => (fieldKey) => {
		return state.display.getHeaderValue(fieldKey);
	},

	/**
	 * Returns an saved field value written with the WYSIWYG editor
	 */
	getValueFromField: (state, getters, rootState, rootGetters) => (mainCategoryKey, sourceKey, language, standard, property) => {
		const data = rootGetters['product/getValue']('_display.data');
		if (!data || !Array.isArray(data)) {
			return null;
		}

		const obj = data.find(f =>
			f.category === mainCategoryKey &&
			f.field === sourceKey &&
			f.language === language &&
			f.standard === standard);

		if (!obj) {
			return null;
		}

		return obj[property];
	},

	/**
	 * Returns an array of JSON object which consist of information for each field currently
	 * shown in the table (dependent on the category selection).
	 * Has: override value (value was overridden in the WYSIWYG editor) and the saved value or the actual fallback.
	 */
	getDynamicValues: (state, getters) => (languages, standard) => {
		const fields = state.display.getDisplayFields();
		const mainCategory = getters.getSelectedMainCategory;

		if (!mainCategory) {
			return [];
		}

		const mainCategoryKey = mainCategory.data.mainCategoryKey;

		let ret = [];
		for (const language of languages) {
			let l = {};
			l.language = language;
			l.data = [];

			for (const field of fields) {
				const value = getters.getValueFromField(mainCategoryKey, field.key, language, standard, PROPERTY_VALUE);
				const override = getters.getValueFromField(mainCategoryKey, field.key, language, standard, PROPERTY_OVERRIDE);
				const editable = typeof field.editable === 'undefined' ? false : field.editable;

				let r = {};
				r.key = field.key;
				r.title = field.label;
				r.data = {
					editable,
					value,
					override
				};

				l.data.push(r);
			}

			ret.push(l);
		}

		return ret;
	},

	/**
	 * Creates the HTML output of the template
	 */
	getPrintDataHTML: (state, getters, rootState) => () => {
		if (!state.display) {
			return '';
		}
		return state.display.getPrintDataHTML(rootState.base.userLanguagesForMultiLangFields);
	}
};

const actions = {
	async loadNecessaryCompanies({ getters, dispatch, commit }) {
		const productData = getters.getProductData();

		if (!productData._companies) {
			return;
		}

		const uniqueCompanyIds = [...new Set(productData._companies.map(f => f.company))];
		if (uniqueCompanyIds.length === 0) {
			return;
		}

		const list = await dispatch('companylist/loadByIds', uniqueCompanyIds, { root: true });
		commit('setUsedCompanies', list);
	},
	async loadRestrictionTexts({ getters, dispatch, commit, rootState }) {
		let baseData = rootState.product.baseData;
		const productData = getters.getProductData();

		if (!productData._approval) {
			return;
		}

		let ids = [];
		for (let approval of Object.values(productData._approval)) {
			if (approval.restrictionTextId && approval.restrictionTextId.selectValue) {
				ids.push(approval.restrictionTextId.selectValue);
			}
		}
		if (baseData && baseData._approval) {
			for (let approval of Object.values(baseData._approval)) {
				if (approval.restrictionTextId && approval.restrictionTextId.selectValue) {
					ids.push(approval.restrictionTextId.selectValue);
				}
			}
		}
		const uniqueRestrictionTextIds = [...new Set(ids)];
		if (uniqueRestrictionTextIds.length === 0) {
			return;
		}

		const list = await dispatch('restrictiontext/loadByIds', uniqueRestrictionTextIds, { root: true });
		commit('setUsedRestrictionTexts', list);
	},
	/**
	 * Set default state.
	 */
	async init({ commit, dispatch, getters, rootGetters, rootState }, { standard }) {
		const selectOptions = rootGetters['selectoptions/all'];

		await dispatch('loadNecessaryCompanies');
		await dispatch('loadRestrictionTexts');
		const listId = rootState.product.currentList.id;
		commit('init',
			{
				productData: getters.getProductData(),
				selectOptions,
				mainCategory: getters.getSelectedMainCategory,
				language: getters.getPrintLanguage(),
				standard,
				fieldData: getters.getFieldData,
				permits: getters.getPermitData,
				components: getters.getComponentData,
				biostandards: getters.getBiostandardData,
				approvals: getters.getApprovalData,
				categories: getters.getCategoryData,
				restrictionTexts: getters.getRestrictionTextData,
				fallbackLanguage: getters.getFallbackLanguage(),
				companyData: getters.getCompanyData,
				companyBase: getters.getCompanyBaseData,
				list: rootGetters['list/byId'](listId)
			});
	},

	updateProductData({ state, commit, getters }) {
		if (!state.display) {
			return;
		}

		commit('updateProductData', {
			productData: getters.getProductData(),
			language: getters.getPrintLanguage(),
			standard: getters.getPrintStandard(),
			productIds: getters.getAllProducts
		});
	},

	async updateCompanyData({ commit, getters, dispatch, state }) {
		if (!state.display) return;
		await dispatch('loadNecessaryCompanies');
		commit('updateCompanyData', {
			companyData: getters.getCompanyData,
			companyBase: getters.getCompanyBaseData
		});
	}
};

const mutations = {
	init(state, { selectOptions, mainCategory, language, standard, fieldData, permits, components, biostandards,
		approvals, categories, fallbackLanguage, companyData, companyBase, list, restrictionTexts }) {
		state.display = new Display({
			selectOptions,
			mainCategory,
			language,
			standard,
			fieldData,
			permits,
			components,
			biostandards,
			approvals,
			categories,
			fallbackLanguage,
			companyData,
			companyBase,
			restrictionTexts,
			list
		});
	},

	updateProductData(state, { productData, language, standard, productIds }) {
		state.display.updateProductData(productData);
		state.display.updateLanguage(language);
		state.display.updateStandard(standard);
		state.display.updateProductIds(productIds);
	},

	setUsedCompanies(state, usedCompanies) {
		state.usedCompanies = usedCompanies;
	},

	setUsedRestrictionTexts(state, usedRestrictionTexts) {
		state.usedRestrictionTexts = usedRestrictionTexts;
	},

	updateCompanyData(state, { companyData, companyBase }) {
		state.display.updateCompanyData(companyData, companyBase);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
