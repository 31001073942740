import { post } from '@/util/request.js';
import { search, genericEntityUpdate } from '../system.js';

export async function createColumnSet(data, entityClassId, attributeSetId) {
	let payload = {
		entityclass: entityClassId,
		attributeset: attributeSetId,
		data
	};

	return await post('eav/entity/create', payload);
}

export function updateColumnSet(oldEntity, newData, set) {
	return genericEntityUpdate(oldEntity, newData, set);
}

export async function deleteColumnSet(id) {
	const deletedEntity = await post('eav/entity/disable', id);
	return deletedEntity.id;
}

export function getAllColumnSets(entityClassId) {
	return search(entityClassId);
}
