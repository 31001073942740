<template>
	<div id="SettingsPanel" v-click-outside="hide">
		<b-button @click="toggle">
			<v-icon icon="cog" class="m-0" />
		</b-button>
		<b-card class="popout" v-show="open && loaded">
			<b-form-group :label="$t('default.settingsPanel.interface')">
				<b-form-select :options="languages" v-model="mainUiLang" />
			</b-form-group>
			<b-form-group :label="$t('default.settingsPanel.fieldLangs')">
				<multi-select
					:max="3"
					v-model="fieldLangs"
					:options="languageCodes"
					:multiple="true"
					:allow-empty="false"
					:custom-label="languageTitle"
					:close-on-select="false"
					:disabled="!overrideMultitextLanguages"
					:placeholder="$t('default.multiselect.placeholder')"
				/>
			</b-form-group>
			<b-form-group :label="$t('default.settingsPanel.useListDataForMultitextLanguages')">
				<b-form-checkbox v-model="overrideMultitextLanguages" />
			</b-form-group>
			<b-form-group :label="$t('default.settingsPanel.receiveMailsNotes')">
				<b-form-checkbox v-model="receiveMailsNotes" />
			</b-form-group>
			<b-form-group :label="$t('default.settingsPanel.receiveMailsMessages')">
				<b-form-checkbox v-model="receiveMailsMessages" />
			</b-form-group>
			<b-form-group label="Default Search">
				<b-form-select :options="searchOptions" v-model="defaultProductFilter" />
			</b-form-group>
			<b-form-group label="Default Columns">
				<b-form-select :options="columnsetOptions" v-model="defaultProductColumns" />
			</b-form-group>
			<b-button
				variant="primary"
				@click="save"
				:disabled="!canSave"
			>
				{{ $t('default.dialog.save') }}
			</b-button>
		</b-card>
	</div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
	name: 'SettingsPanel',

	components: {
		MultiSelect
	},

	data() {
		return {
			open: false,
			loaded: false,
			canSave: false,
			defaultProductFilter: null,
			defaultProductColumns: null,
			mainUiLang: null,
			fieldLangs: [],
			receiveMailsNotes: false,
			receiveMailsMessages: false,
			overrideMultitextLanguages: false
		};
	},

	computed: {
		languages() {
			return this.$store.getters['base/getLanguagesForSelect'];
		},
		languageCodes() {
			return this.$store.state.base.languages;
		},
		token() {
			return this.$store.getters['auth/login/getCurrentToken'];
		},
		mainUiLangSaved: {
			get() {
				return this.$store.state.base.selectedUiLanguage;
			},
			set(v) {
				this.$store.commit('base/setUiLanguage', v);
			}
		},
		fieldLangsSaved: {
			get() {
				return this.$store.state.base.userLanguagesForMultiLangFields;
			},
			set(v) {
				this.$store.commit('base/setUserLangForFields', v);
			}
		},
		receiveMailsNotesSaved: {
			get() {
				return this.$store.state.base.receiveMailsNotes;
			},
			set(v) {
				this.$store.commit('base/setReceiveMailsNotes', { enabled: v });
			}
		},
		receiveMailsMessagesSaved: {
			get() {
				return this.$store.state.base.receiveMailsMessages;
			},
			set(v) {
				this.$store.commit('base/setReceiveMailsMessages', { enabled: v });
			}
		},
		overrideMultitextLanguagesSaved: {
			get() {
				return !this.$store.state.base.overrideMultitextLanguages;
			},
			set(v) {
				this.$store.commit('base/setOverrideMultitextLanguages', { enabled: v });
			}
		},
		searchOptions() {
			return this.$store.getters['search/getVisible'].map(s => ({
				value: s.id,
				text: s.data.search.label
			}));
		},
		columnsetOptions() {
			return this.$store.getters['columnset/all'].map(s => ({
				value: s.id,
				text: s.data.configuration.name
			}));
		},
		defaultProductColumnsSaved: {
			get() {
				let account = this.$store.getters['auth/user/getCurrentAccount']();
				return account.userData.defaultProductColumns;
			},
			set(nv) {
				let obj = this.$store.getters['columnset/all'].find(e => e.id === nv);
				this.$store.commit('columnset/setSelectedColumnSet', obj);
			}
		},
		defaultProductFilterSaved: {
			get() {
				let account = this.$store.getters['auth/user/getCurrentAccount']();
				return account.userData.defaultProductFilter;
			},
			set(nv) {
				let obj = this.$store.getters['search/getVisible'].find(e => e.id === nv);
				this.$store.commit('search/setActiveSearch', obj);
				this.$store.commit('search/setAdhocSearch', obj);
			}
		}
	},

	watch: {
		defaultProductFilter(nv) {
			if (nv !== this.defaultProductFilterSaved) {
				this.canSave = true;
			}
		},
		defaultProductColumns(nv) {
			if (nv !== this.defaultProductColumnsSaved) {
				this.canSave = true;
			}
		},
		mainUiLang(nv) {
			if (nv !== this.mainUiLangSaved) {
				this.canSave = true;
			}
		},
		fieldLangs(nv) {
			if (nv.join(',') !== this.fieldLangsSaved.join(',')) {
				this.canSave = true;
			}
		},
		overrideMultitextLanguages(nv) {
			if (nv !== this.overrideMultitextLanguagesSaved) {
				this.canSave = true;
			}
		},
		receiveMailsNotes(nv) {
			if (nv !== this.receiveMailsNotesSaved) {
				this.canSave = true;
			}
		},
		receiveMailsMessages(nv) {
			if (nv !== this.receiveMailsMessagesSaved) {
				this.canSave = true;
			}
		}
	},

	methods: {
		toggle() {
			this.open = !this.open;
			if (this.open) {
				this.mainUiLang = this.mainUiLangSaved;
				this.fieldLangs = this.fieldLangsSaved.slice(0);
				this.overrideMultitextLanguages = this.overrideMultitextLanguagesSaved;
				this.receiveMailsNotes = this.receiveMailsNotesSaved;
				this.receiveMailsMessages = this.receiveMailsMessagesSaved;
				this.defaultProductFilter = this.defaultProductFilterSaved;
				this.defaultProductColumns = this.defaultProductColumnsSaved;
				this.canSave = false;
			}
			if (this.open && !this.loaded) {
				this.loaded = true;
				this.$store.dispatch('search/loadAll', { ifEmpty: true });
				this.$store.dispatch('columnset/loadAll', { ifEmpty: true });
			}
		},
		languageTitle(code) {
			return this.$store.getters['base/getLanguageTitle'](code);
		},
		hide() {
			this.open = false;
		},
		async save() {
			this.canSave = false;
			const updateUserData = { token: this.token };
			if (this.mainUiLang !== this.mainUiLangSaved) {
				updateUserData.selectedUiLanguage = this.mainUiLang;
				this.mainUiLangSaved = this.mainUiLang;
			}
			if (this.fieldLangs.join(',') !== this.fieldLangsSaved.join(',')) {
				updateUserData.userLanguagesForMultiLangFields = this.fieldLangs;
				this.fieldLangsSaved = this.fieldLangs;
			}
			if (this.overrideMultitextLanguages !== this.overrideMultitextLanguagesSaved) {
				updateUserData.overrideMultiLangFieldsWithListData = !this.overrideMultitextLanguages;
				this.overrideMultitextLanguagesSaved = this.overrideMultitextLanguages;
			}
			if (this.receiveMailsNotes !== this.receiveMailsNotesSaved) {
				updateUserData.receiveMailsNotes = this.receiveMailsNotes;
				this.receiveMailsNotesSaved = this.receiveMailsNotes;
			}
			if (this.receiveMailsMessages !== this.receiveMailsMessagesSaved) {
				updateUserData.receiveMailsMessages = this.receiveMailsMessages;
				this.receiveMailsMessagesSaved = this.receiveMailsMessages;
			}
			if (this.defaultProductFilter !== this.defaultProductFilterSaved) {
				updateUserData.defaultProductFilter = this.defaultProductFilter;
				this.defaultProductFilterSaved = this.defaultProductFilter;
			}
			if (this.defaultProductColumns !== this.defaultProductColumnsSaved) {
				updateUserData.defaultProductColumns = this.defaultProductColumns;
				this.defaultProductColumnsSaved = this.defaultProductColumns;
			}
			// console.log(updateUserData);
			await this.$store.dispatch('auth/user/updateUserData', updateUserData);
		}
	}
};
</script>

<style lang="scss">
#SettingsPanel {
	position: relative;
	color: #000;
	> button {
		z-index: 2;
	}
	> .popout {
		z-index: 1090;
		position: absolute;
		top: 30px;
		right: 0;
		width: 320px;
	}
}
</style>
