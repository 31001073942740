import Emitter from 'promise-events';

const bus = new Emitter();

//compat to vue emitter
bus.$on = bus.on;
bus.$emit = bus.emit;
bus.$off = bus.off;

export default bus;
