import { traverse } from "@/util/field-helper";
import BmlCommon from "@fibl/bml-common";
import Store from "@/store/main.js";

export function getParty(conversation, uid) {
	try {
		return BmlCommon.MessageClass.getParty(
			conversation,
			uid,
			Store.getters["auth/login/currentAccountInstitution"],
			Store.getters["auth/login/currentAccountCompany"]
		);
	} catch (error) {
		throw new Error(`Error fetching current party: ` + error);
	}
}

export function getMeFilterBothSides(extra) {
	return {
		$or: [
			getFilterForMe("sender", extra),
			getFilterForMe("recipient", extra),
		],
	};
}

export function getFilterForMe(party, extra) {
	const storeOrgs = Store.getters["auth/login/currentAccountInstitution"];
	const storeCompany = Store.getters["auth/login/currentAccountCompany"];
	const amFibl = storeOrgs.length > 0;
	let res;
	if (amFibl) {
		res = {
			$or: storeOrgs.map((org) => ({
				field: `/data/${party}/fiblorg`,
				comparison: "eq",
				value: org,
			})),
		};
	} else {
		let company = storeCompany || "none";
		res = {
			field: `/data/${party}/company`,
			comparison: "eq",
			value: company,
		};
	}
	let wrap = res;
	if (extra) {
		extra = JSON.parse(JSON.stringify(extra));
		traverse(extra, function (layer) {
			if (layer?.field) {
				layer.field = layer.field.replace("%P", party);
			}
		});
		wrap = { $and: [res, extra] };
	}
	return wrap;
}
