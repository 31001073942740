import Store from '@/store/main';
import { isUuid as uuidValidate } from '../uuid';

const RESERVED_PATHS = ['rev', 'created', 'updated', 'data.isNationalPartner'];

// used to translate field subpaths from indexData to listData
const INDEX_LISTDATA_MAPPING = {
	'name': 'basis-f1'
};

const SORTABLE_PATHS = ['name', 'rev', 'created', 'updated', 'data.isNationalPartner', '_categoryTree', 'companyManufacturer', 'companyDistributor'];

// used to translate dynamic fields (e. g. from search-fields.json) to their sortable representations
const FIELD_SEARCHPATH_TABLE = {
	'_categoryTree': 'category.label',
	'companyManufacturer': 'manufacturer.label',
	'companyDistributor': 'distributor.label',
	'name': 'nameSorting'
};

// used to append postfixes to given columns
const FIELD_POSTFIX = {
	// '_categoryTree': 'secondary'
};

// if the column is included here, we have an additional multi lang field
// example _categoryTree: gets translated to category.label via FIELD_SEARCHPATH_TABLE
// afterwards the constructed path gets a language code suffix via MULTILANG_TABLE
// finally the field gets its final representation with FIELD_POSTFIX
// _categoryTree => category.label => category.label.de_DE => category.label.de_DE.secondary
const MULTILANG_TABLE = ['_categoryTree'];

function isDateField(fieldPath) {
	const field = getActualField(fieldPath);
	if (field) {
		if (field.data.displayType === 'tab' && field.data.type.toLowerCase() === 'datefield') {
			return true;
		}
	}

	return false;
}

export function isFieldSortable(fieldPath) {
	if (!fieldPath) {
		return false;
	}

	if (SORTABLE_PATHS.includes(fieldPath)) {
		return true;
	}

	// date-fields (not in table) are sortable
	return isDateField(fieldPath);
}

function isIndexField(fieldPath) {
	if (!fieldPath) {
		return false;
	}

	return !isDateField(fieldPath);
}

/**
 * Maps an index field id to the corresponding field id in the columnset field store, if possible
 * @param fieldId
 * @returns {*}
 */
function mapColumnToListField(fieldId) {
	const listField = INDEX_LISTDATA_MAPPING[fieldId];
	if (!listField) {
		return fieldId;
	}

	return listField;
}

/**
 * Maps an field path to its actual representation, if needed.
 * @param fieldId
 * @returns {*}
 */
function mapColumnToTranslatedPath(fieldId) {
	const translatedPath = FIELD_SEARCHPATH_TABLE[fieldId];
	if (!translatedPath) {
		return fieldId;
	}

	return translatedPath;
}

function mapFieldWithPostfix(fieldId) {
	const postfix = FIELD_POSTFIX[fieldId];
	if (!postfix) {
		return '';
	}

	return `.${postfix}`;
}

function getActualField(fieldId, tableColumnId) {
	const actualFieldId = mapColumnToListField(tableColumnId) || mapColumnToListField(fieldId);
	return Store.getters['columnset/fieldByPath'](actualFieldId);
}

function isMultiLangField(fieldId, tableColumnId) {
	const field = getActualField(fieldId, tableColumnId);
	if (!field) {
		return false;
	}

	return field.data.type.toLowerCase().includes('multitext') || MULTILANG_TABLE.includes(field.data.path);
}

function getCurrentLanguage() {
	return Store.state.base.selectedUiLanguage;
}

function generateColumnPath(column) {
	if (RESERVED_PATHS.includes(column)) return column;
	const [listId, fieldId, tableRowId, tableColumnId] = column.split('/');
	let basePath = 'data.';
	let fieldPath = basePath;
	let multiLang;
	let actualField = column;
	if (uuidValidate(listId, 4) && fieldId) {
		multiLang = isMultiLangField(fieldId, tableColumnId);
		actualField = tableColumnId || fieldId;
		fieldPath += `_searchIndex_.${listId}`;
	} else {
		multiLang = isMultiLangField(column);
		const baseListId = Store.getters['list/baseDataList'].id;
		fieldPath += `_searchIndex_.${baseListId}`;
	}
	fieldPath += `.${mapColumnToTranslatedPath(actualField)}`;
	let _searchIndex_ = isIndexField(actualField) ? 'indexData' : 'listData';
	fieldPath = fieldPath.replace('_searchIndex_', _searchIndex_);
	if (multiLang) {
		fieldPath += `.${getCurrentLanguage()}`;
	} else if (isDateField(actualField)) {
		fieldPath += '.dateValue';
	}
	fieldPath += mapFieldWithPostfix(actualField);
	return fieldPath;
}

/**
 * Entry point. Constructs the actual sort path to be provided to the REST API
 * @param sorting Object with "order" \in {'desc', 'asc'} and "column" \in {fieldPaths}
 * @returns {[]|*[]} Returns an array of strings. Basically there are multiple sort paths possible but we just allow single sorting at the moment.
 */
export function generateSortFields(sorting) {
	if (!sorting || !sorting.order || !sorting.column) {
		return [];
	}

	let result = [];

	const path = generateColumnPath(sorting.column);
	result.push(`${path}:${sorting.order}`);

	return result;
}
