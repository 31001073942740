import Store from '@/store/main.js';
import BmlCommon from '@fibl/bml-common';
import { getCopyOfConversation } from './portal-store-helper';

const LIST_EU = "3bbeaa3b-92cf-4630-b80d-3e0b69790d45";

export async function getSubjectFromContext(convo) {
	const conversation = getCopyOfConversation(convo);
	if (!conversation.context) return "";
	const context = Object.keys(conversation.context);
	const id = Object.values(conversation.context);
	if (context[0] === "invoiceId") {
		const invoiceDetail= await getInvoiceNumber(id[0]);
		if (invoiceDetail)
			return invoiceDetail.productName
				? `Invoice: ${invoiceDetail.invoiceNumber}.\nProduct: ${invoiceDetail.productName}.\nCompany: ${invoiceDetail.companyName}`
				: `Invoice: ${invoiceDetail.invoiceNumber}. \nCompany: ${invoiceDetail.companyName}`;
		else return "";
	}
	if (context[0] === "productId") {
		const productName = await getProductName(id[0]);
		return productName ? `Product: ${productName}` : "";
	}
	if (context[0] === "confirmationId") {
		const confirmationDetail = await getConfirmationDetail(id[0]);

		if (confirmationDetail)
			return `Confirmation type: ${confirmationDetail.type}.\nProduct: ${confirmationDetail.productName}.\nCompany: ${confirmationDetail.companyName}`;
		else return "";
	}
	if (context[0] === "requestId") {
		const requestDetail = await getRequestDetail(id[0]);
		if (requestDetail)
			return requestDetail.productName
				? `Request type: ${requestDetail.type}.\nProduct: ${requestDetail.productName}.\nCompany: ${requestDetail.companyName}`
				: `Request type: ${requestDetail.type}. \nCompany: ${requestDetail.companyName}`;
		else return "";
	}
	return `${context}: ${id}`;
}

async function getInvoiceNumber(id) {
	if (!id) return "";
	const entity = await Store.dispatch('related/addRequiredEntity', {id: id});
	const invoiceNumber = entity.data.indexData.invoiceNumber;
	const companyName = entity.data.indexData.company.name;
	return { invoiceNumber, companyName};
}

async function getProductName(id) {
	if (!id) return "";
	try {
		const entity = await Store.dispatch('related/addRequiredEntity', { id: id, ec: 'product' });
		const copyEntity = JSON.parse(JSON.stringify(entity));
		const productClass = new BmlCommon.ProductClass();
		productClass.fromData(copyEntity.data);
		return productClass.getName();
	} catch (error) {
		if (error.message.includes("id not found")) return;
		else throw new Error(error);
	}
}

async function getCompanyName(id) {
	if (!id) return "";
	const entity = await Store.dispatch('related/addRequiredEntity', {id: id});
	return entity.data.baseData.name;
}

async function getConfirmationDetail(id) {
	if (!id) return "";
	const entity = await Store.dispatch('related/addRequiredEntity', {id: id});
	const type = entity.data.type;
	let productName = '';
	if(entity.data.productId) productName = await getProductName(entity.data.productId);
	else if(entity.data.requestId) productName = await getProductNameFromRequestId(entity.data.requestId);
	const companyName = await getCompanyName(entity.data.companyId);
	return { type, productName, companyName };
}

async function getRequestDetail(id) {
	if (!id) return "";
	const entity = await Store.dispatch("related/addRequiredEntity", {
		id: id,
	});
	const type = entity.data.type;
	let productName = "";
	if (type.includes("company"))
		productName = await getCompanyName(entity.data.targetId);
	else if (type.includes("product"))
		if (!entity.data.targetId)
			productName = await getProductNameFromRequestId(entity.id);
		else productName = await getProductName(entity.data.targetId);
	const companyName = await getCompanyName(entity.data.company);
	return { type, companyName, productName };
}

async function getProductNameFromRequestId(id) {
	if (!id) return '';
	try {
		const language = Store.state.base.selectedUiLanguage || Store.state.base.fallbackLocale;
		const entity = await Store.dispatch("related/addRequiredEntity", {
			id: id,
		});
		const prodListData = JSON.parse(entity.data.productListData);
		let reqProdName = '';
		if (
			prodListData[LIST_EU] &&
			prodListData[LIST_EU]['basis-f1'] &&
			prodListData[LIST_EU]['basis-f1'][language]
		)
			reqProdName = prodListData[LIST_EU]['basis-f1'][language];
		else reqProdName = Object.values(Object.values(prodListData)[0]['basis-f1'])[0];
		return reqProdName;
	} catch (error) {
		if (error.message.includes('id not found')) return;
		else throw new Error(error);
	}
}