import * as Repo from '@/repository/lists.js';
import { toLocale } from '@/plugins/filters';
import PdfLogoTypes from '@/assets/config/pdf-logo-types.json';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';

const state = {

	loaded: false,
	lists: [],
	attributesetId: '',
	entityclassId: '',
	pdfLogoOptions: PdfLogoTypes
};

const getters = {

	byId: (state) => (id) => {
		return state.lists.find(r => r.id === id);
	},

	root: (state) => {
		return state.lists;
	},

	baseDataList: (state) => {
		let list = state.lists.find(r => r.data.isBasedata === true);
		if (!list) throw new Error('base list is not loaded or does not exist');
		return list;
	},

	getPdfLogoOptions: (state) => {
		return state.pdfLogoOptions;
	},

	allLists: (state) => {
		return state.lists;
	},

	euList: (state) => {
		return state.lists.find((e) => e.data.isBasedata);
	},

};

const actions = {

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('list');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('lists');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createList(data, state.entityclassId, state.attributesetId);
		commit('addList', entity);
	},

	async loadAll({ commit }, { ifEmpty }) {
		if (state.loaded && ifEmpty) return;
		commit('setLoaded', true);
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('list');
			commit('setEcId', { id: ec.id });
		}
		let list = await Repo.getAllLists(state.entityclassId);
		list = list.sort((a, b) => {
			let valueA = toLocale(a.data.title);
			let valueB = toLocale(b.data.title);
			return valueA.localeCompare(valueB);
		});
		commit('resetLists', list);
	},

	async update({ state, commit, getters }, list) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('lists');
			commit('setAsId', { id: ec.id });
		}
		let oldList = getters.byId(list.id);
		let updated = await Repo.updateList(oldList, list.data, state.attributesetId);
		if (updated) {
			commit('deleteList', { id: list.id });
			commit('addList', updated);
		}
	}

};

const mutations = {

	setLoaded(state, l) {
		state.loaded = l;
	},
	addList(state, entity) {
		state.lists.push(entity);
	},
	resetLists(state, list) {
		state.lists = list || [];
	},
	deleteList(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.lists.indexOf(entity);
		state.lists.splice(idx, 1);
	},


	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
