import { post as sendPostRequest } from '@/util/request.js';
import { search, getEntitiesById } from './system.js';
import Store from '@/store/main';
import { chunk } from 'lodash';
import {toLocale} from '@/plugins/filters';
import { createPatch } from 'rfc6902';

export function getCompanyById(id) {
	return sendPostRequest('bml/company/load', { id });
}

// add additional index fields
function _updateIndex(entityData, oldData) {
	if (oldData && oldData.indexData) {
		entityData.indexData = JSON.parse(JSON.stringify(oldData.indexData));
	}
	if (!entityData.indexData) {
		entityData.indexData = {};
	}
	// country for main address
	const mainAddress = entityData.addresses.find(f => f.type === 'main');
	if (mainAddress && mainAddress.country) {
		const country = Store.getters['country/byId'](mainAddress.country);
		if (country) {
			entityData.indexData.country = toLocale(country.data.title);
		}
	}
	// owner
	const owner = entityData.baseData.owner;
	if (owner) {
		const institution = Store.getters['auth/org/byId'](owner);
		if (institution) {
			entityData.indexData.owner = institution.data.title;
		}
	}
}

export async function createCompany(entityclass, attributeset, data) {
	_updateIndex(data);
	let createdEntity = await sendPostRequest('bml/company/create', data);
	return createdEntity;
}

export function updateProductsAfterMerge(companyIds) {
	return sendPostRequest('bml/company/updateProductsAfterMerge', companyIds);
}

export function updateCompany(oldEntity, newData, changeset) {
	_updateIndex(newData, oldEntity.data);
	if (!oldEntity) {
		return null;
	}
	const patch = createPatch(oldEntity.data, newData);
	if (patch.length === 0) {
		return null;
	}
	let payload = {
		id: oldEntity.id,
		rev: oldEntity.rev,
		patch
	};
	if (changeset && changeset !== oldEntity.attributeset) {
		payload.changeset = changeset;
	}
	return sendPostRequest('bml/company/save', payload);
}

export async function deleteCompany(id) {
	let deletedEntity = await sendPostRequest('eav/entity/disable', id);
	return deletedEntity.id;
}

export function searchCompanies(entityclassId, filter, pagination, sort) {
	return search(entityclassId, filter, pagination, sort);
}

export async function getCompaniesByIds(entityclassId, companyIds) {
	let result = [];
	const CHUNK_SIZE = 500;
	const chunks = chunk(companyIds, CHUNK_SIZE);
	for (const chunk of chunks) {
		const tempResult = await getEntitiesById(chunk);
		result = result.concat(tempResult);
	}
	return result;
}

export async function aggregateSearch({ attributeFilter }) {
	const endpoint = 'bml/company/aggregate';
	let result = await sendPostRequest(endpoint, { attributeFilter });
	return result;
}
