import { ROLE } from '@/util/role';
import {uniq} from 'lodash';

const state = {
	groups: [],
	currentRoutes: []
};

function hasFittingRole(userRole, routeRole) {
	if (userRole === ROLE.Admin) {
		return true;
	}

	if (routeRole === ROLE.Registered) {
		return userRole === ROLE.Editor || userRole === ROLE.Registered;
	}

	return userRole === routeRole;
}

function doRouteFilter(userRole, group, route) {
	if (!group && !route.meta) {
		return true;
	}

	if (route.meta.group !== group || route.meta.hideInNav) {
		return false;
	}

	if (route.meta.permission) {
		return hasFittingRole(userRole, route.meta.permission);
	}

	return true;
}

const getters = {
	getGroups: (state) => () => {
		return state.groups;
	},
	getRoutesByGroup: (state) => (group) => {
		return state.currentRoutes.filter(f => f.group === group);
	}
};

const actions = {
	async setRoutes({ commit }, { userRole }) {
		const router = (await import('@/router.js')).default;
		commit('setRoutes', { userRole, router });
	}
};

const mutations = {
	setRoutes(state, { userRole, router }) {
		const groups = uniq(router.options.routes
			.filter(r => !!r.meta)
			.map(r => (r.meta.group ? r.meta.group : '')));
		let actualGroups = [];
		let routes = [];
		for (const group of groups) {
			const filteredRoutes = router.options.routes
				.filter(r => doRouteFilter(userRole, group, r));
			if (filteredRoutes.length > 0) {
				actualGroups.push(group);
				for (const route of filteredRoutes) {
					let routeObj = route;
					routeObj.group = group;
					routes.push(routeObj);
				}
			}
		}

		state.groups = actualGroups;
		state.currentRoutes = routes;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
