import Vue from 'vue';
import Router from 'vue-router';
import AcceptInvite from './views/auth/acceptinvite.vue';
import store from './store/main.js';
import { ROLE } from '@/util/role.js';
import { basePath } from './util/env.js';
import EventBus from './util/eventbus.js';

Vue.use(Router);

let router = new Router({
	mode: 'history',
	base: basePath,
	routes: [
		{
			path: '/',
			name: 'productList',
			component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductList.vue'),
			meta: {
				group: 'Redaktion',
				permission: 'Editor'
			}
		},
		{
			path: '/company/list',
			name: 'companyList',
			component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/company/CompanyList.vue'),
			meta: {
				group: 'Redaktion',
				permission: 'Editor'
			}
		},
		{
			path: '/invoice/list',
			name: 'invoiceList',
			component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/invoice/InvoiceList.vue'),
			meta: {
				group: 'Redaktion',
				permission: 'Admin'
			}
		},
		{
			path: '/company/view/:company_id',
			name: 'company',
			component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/company/CompanyViewDetail.vue'),
			meta: {
				permission: 'Editor'
			}
		},
		{
			path: '/company/merge',
			name: 'CompanyMerge',
			component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/company/CompanyMerge.vue'),
			meta: {
				permission: 'Editor'
			}
		},
		{
			path: '/product/:product_id/:list_id/:rev?',
			name: 'product',
			component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/Product.vue'),
			meta: {
				permission: 'Editor'
			},
			children: [{
				path: 'main',
				name: 'product-tab-main',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabBase.vue'),
				meta: {
					permission: 'Editor'
				},
			}, {
				path: 'permits',
				name: 'product-tab-permits',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabPermits.vue'),
				meta: {
					permission: 'Editor'
				},
			}, {
				path: 'specific',
				name: 'product-tab-specific',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabSpecific.vue'),
				meta: {
					permission: 'Editor'
				},
			}, {
				path: 'hobbygarden',
				name: 'product-tab-hobbygarden',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabHobbyGarden.vue'),
				meta: {
					permission: 'Editor'
				},
			}, {
				path: 'misc',
				name: 'product-tab-misc',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabMisc.vue'),
				meta: {
					permission: 'Editor'
				},
			}, {
				path: 'documents',
				name: 'product-tab-documents',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabDocuments.vue'),
				meta: {
					permission: 'Editor'
				}
			}, {
				path: 'display',
				name: 'product-tab-display',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabDisplay.vue'),
				meta: {
					permission: 'Editor'
				}
			}, {
				path: 'checklist',
				name: 'product-tab-checklist',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabChecklist.vue'),
				meta: {
					permission: 'Editor'
				}
			}, {
				path: 'approvals',
				name: 'product-tab-approvals',
				component: () => import(/* webpackChunkName: "editorial" */ './views/editorial/product/ProductTabApprovals.vue'),
				meta: {
					permission: 'Editor'
				}
			}, ]
		},
		{
			path: '/admin/category',
			name: 'CategoryManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/CategoryManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/categorytext',
			name: 'CategoryTextManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/CategoryTextManagement.vue'),
			meta: {
				group: 'Redaktion',
				permission: 'Editor'
			}
		},
		{
			path: '/admin/component',
			name: 'ComponentManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/ComponentManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/hobbygarden',
			name: 'HobbyGardenManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/HobbyGardenManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/biostandard',
			name: 'biostandard',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/Biostandard.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/checklist',
			name: 'ChecklistManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/ChecklistManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/selectoptions',
			name: 'SelectOptionManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/SelectOptionManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/restrictiontext',
			name: 'RestrictionTextManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/RestrictionTextManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/countrymanagement',
			name: 'CountryManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/CountryManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/listmanagement',
			name: 'ListManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/ListManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/labelmanagement',
			name: 'LabelManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/LabelManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/permitmanagement',
			name: 'PermitManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/PermitManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/fieldmanagement',
			name: 'FieldManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/FieldManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/user/accountmanagement',
			name: 'AccountManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/user/AccountManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/user/institutionmanagement',
			name: 'InstitutionManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/user/InstitutionManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/user/paidaccounts',
			name: 'PaidAccountManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/user/PaidAccountManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/pubsearch',
			name: 'PubSearchManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/PubSearchManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/admin/linkeddocumentmanagement',
			name: 'LinkedDocumentManagement',
			component: () => import(/* webpackChunkName: "admin" */ './views/admin/LinkedDocumentManagement.vue'),
			meta: {
				group: 'Administration',
				permission: 'Admin'
			}
		},
		{
			path: '/auth/acceptinvite',
			name: 'AcceptInvite',
			component: AcceptInvite,
			meta: {
				group: '',
				hideInNav: true
			}
		},
		{
			path: '/user/notes',
			name: 'NotesManagement',
			component: () => import(/* webpackChunkName: "user" */ './views/user/Notes.vue'),
			meta: {
				group: 'User-Center',
				permission: 'Registered'
			}
		},
		{
			path: '/user/messagecenter',
			name: 'MessageCenter',
			component: () => import(/* webpackChunkName: "user" */ './views/user/messagecenter/MessageCenter.vue'),
			meta: {
				group: 'User-Center',
				permission: 'Registered'
			},
			children: [
				{
					path: 'detail/:id',
					name: 'MessagingDetail',
					component: () => import(/* webpackChunkName: "user" */ './views/user/messagecenter/MessagingDetail.vue'),
					meta: {
						permission: 'Registered'
					},
				},
			],
		},
	]
});

let waitForRole = new Promise(res => {
	store.watch(() => store.getters['auth/user/getCurrentAccountId'], a => {
		if (a) res();
	});
});

router.beforeEach((to, from, next) => {
	const isAdmin = store.getters['auth/user/roles/isAdmin'];
	const isAuthRoute = to.matched.some(f => f.meta.permission);
	store.commit('base/setRoute', to);
	if (!isAuthRoute || isAdmin) {
		return next();
	} else {
		return waitForRole.then(() => {
			const isAdmin = store.getters['auth/user/roles/isAdmin'];
			const isEditor = isAdmin || store.getters['auth/user/roles/isEditor'];
			if (to.matched.some(f => f.meta.permission === ROLE.Admin)) {
				if (!isAdmin) {
					return next({name: 'home'});
				} else {
					return next();
				}
			}
			if (to.matched.some(f => f.meta.permission === ROLE.Editor)) {
				if (!isEditor) {
					return next({name: 'home'});
				} else {
					return next();
				}
			}
			return next();
		});
	}

});
router.beforeEach((t, f, next) => {
	EventBus.removeAllListeners('globalKeyUp');
	return next();
});
router.afterEach((to, from) => {
	if ((from.name || '').startsWith('product-tab-') && !(to.name || '').startsWith('product-tab-')) {
		//leaving product
		store.commit('product/closeProduct');
	}
});

export default router;
