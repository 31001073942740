import * as Repository from '@/repository/listingconfirmation';
import { genericEntityUpdate, genericEntityDelete, search, getEntityclassByExternalId } from '@/repository/system';

const state = {
	entityClassId: null,
	listingConfirmations: []
};

const getters = {

	getListingConfirmations: (state) => (productId) => {
		return state.listingConfirmations.filter(confirmation => {
			return confirmation.data.productId === productId;
		});
	},

	byId: (state) => confirmationId => {
		return state.listingConfirmations.find(confirmation => confirmation.id === confirmationId);
	}

};

const actions = {

	async loadAll({ commit, state }, { productId }) {
		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId('listing_confirmation');
			commit('setEntityClassId', entityClass.id);
		}
		let filter = {
			field: '/data/productId',
			comparison: 'eq',
			value: productId
		};
		let list = await search(state.entityClassId, filter);
		commit('resetList', list);
	},

	async createConfirmation({ commit }, { productId, listIds, institutionId, companyId, language, type}) {
		let confirmation = await Repository.createListingConfirmation({ productId, listIds, institutionId, companyId, language, type });
		commit('addConfirmation', confirmation);
	},

	async updateConfirmationLanguage({ commit, getters }, { confirmationId, language }) {
		let oldConfirmation = getters.byId(confirmationId);
		let newConfirmation = JSON.parse(JSON.stringify(oldConfirmation));
		newConfirmation.data.language = language;
		let confirmation = await genericEntityUpdate(oldConfirmation, newConfirmation.data);
		commit('updateConfirmation', confirmation);
	},

	async sendConfirmation({ commit }, { confirmationId }) {
		let confirmation = await Repository.sendListingConfirmation({ confirmationId });
		if (confirmation && confirmation.data.sendDate) {
			commit('updateConfirmation', confirmation);
		}
	},

	async deleteConfirmation({ commit }, { confirmationId }) {
		await genericEntityDelete({ id: confirmationId });
		commit('deleteConfirmation', confirmationId);
	},

	getPdf(_, { confirmationId }) {
		return Repository.createListingConfirmationPdf({ confirmationId });
	}

};

const mutations = {
	setEntityClassId(state, id) {
		state.entityClassId = id;
	},
	resetList(state, list) {
		state.listingConfirmations = list;
	},
	addConfirmation(state, confirmation) {
		state.listingConfirmations.push(confirmation);
	},
	deleteConfirmation(state, confirmationId) {
		let oldIndex = state.listingConfirmations.findIndex(entry => entry.id === confirmationId);
		state.listingConfirmations.splice(oldIndex, 1);
	},
	updateConfirmation(state, confirmation) {
		let oldIndex = state.listingConfirmations.findIndex(entry => entry.id === confirmation.id);
		state.listingConfirmations.splice(oldIndex, 1, confirmation);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
