import { get, post } from '@/util/request.js';
import { search, genericEntityUpdate } from './system.js';

export async function createPermit(data, entityclassId, attributesetId) {
	return post('eav/entity/create', {
		entityclass: entityclassId,
		attributeset: attributesetId,
		data
	});
}

export function getAllPermits(entityclassId) {
	return search(entityclassId);
}

export function updatePermit(oldEntry, newData, set) {
	return genericEntityUpdate(oldEntry, newData, set);
}

export async function deletePermit(id) {
	let permit = await post('eav/entity/disable', id);

	return permit.id;
}
