import login from './login.js';
import user from './user.js';
import admin from './admin.js';
import org from './org.js';

const modules = {
	login,
	user,
	admin,
	org
};

export default {
	namespaced: true,
	modules
};
