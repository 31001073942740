import EventBus from '@/util/eventbus.js';
import { backendUrl, LogUrl } from './env.js';
import { getToken } from './jwt-cache.js';
import { tryStartSpinner, tryStopSpinner } from './load-indicator.js';

let backoffTimer = 0;
let backoffInterval = 500;

async function request(path, data, post, auth, surpressError, allowEmptyPayload, hideProgress, retries) {
	if (!data && !allowEmptyPayload) data = {};
	if (isNaN(retries)) retries = 0;
	if (path.substr(0, 1) !== '/') {
		path = `/${path}`;
	}
	let url = backendUrl + path;
	let opts = {};
	opts.mode = 'cors';
	// opts.cache = 'no-cache';
	opts.headers = { 'Content-Type': 'application/json' };
	opts.redirect = 'follow';
	if (auth) {
		opts.headers.Authorization = `Bearer ${auth}`;
	} else {
		console.warn(`no auth supplied for request: ${path}`);
		// return;
	}
	if (!post) {
		opts.method = 'GET';
		if (typeof payload === 'undefined') {
			url += `?__payload=${JSON.stringify(data)}`;
		}
	} else {
		opts.method = 'POST';
		opts.body = JSON.stringify(data);
	}
	if (!hideProgress) tryStartSpinner();

	EventBus.$emit('request.start', { url, opts, hideProgress });
	const res = await global.fetch(url, opts);
	if (res.status === 429) {	//too many requests
		if (retries > 5) {
			const error = new Error('failed after 5 retries');
			error.url = url;
			EventBus.$emit('error', error);
			tryStopSpinner();
			throw error;
		}
		return retry(path, data, post, auth, surpressError, allowEmptyPayload, true, retries+1);
	}
	try {
		if (res.status > 299) throw new Error(`http status ${res.status}`);
		let ct = res.headers.get('Content-Type');
		if (!ct.includes('application/json')) throw new Error(`invalid content-type was returned: ${ct}`);
		const json = await res.json();
		if (!json.ok) {
			let error = new Error();
			error.message = json.message ? json.message : 'unknown error';
			if (!json.message) console.error('unknown error', json);
			error.code = json.code;
			error.reqId = json.id;
			error.url = url;
			throw error;
		}
		if (!hideProgress) tryStopSpinner();
		EventBus.$emit('request.end', { url, opts, json });
		if (backoffTimer > 0) backoffTimer -= backoffInterval;
		return json.data;
	} catch (error) {
		if (!surpressError) {
			if (!error.url) error.url = url;
			EventBus.$emit('error', error);
		}
		if (!hideProgress) tryStopSpinner();
		EventBus.$emit('request.end', { url, opts, error });
		throw error;
	}
}

async function retry(path, data, post, auth, surpressError, allowEmptyPayload, hideProgress, retries) {
	backoffTimer += backoffInterval;
	console.debug('backing off', backoffTimer);
	await sleep(backoffTimer);
	return request(path, data, post, auth, surpressError, allowEmptyPayload, hideProgress, retries);
}

function sleep(ms) {
	return new Promise(function(resolve) {
		setTimeout(resolve, ms);
	});
}

export function get(path, data, auth, supe, emptyPayload, hideProgress) {
	if (!auth) auth = getToken();
	return request(path, data, false, auth, supe, emptyPayload, hideProgress);
}

export function post(path, data, auth, supe, emptyPayload, hideProgress) {
	if (!auth) auth = getToken();
	return request(path, data, true, auth, supe, emptyPayload, hideProgress);
}

export function log(data) {
	global.fetch(LogUrl, {
		method: 'POST',
		headers: {
			'Content-type': 'application/json'
		},
		body: JSON.stringify(data)
	});
}
