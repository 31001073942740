import {createDocument, findIdentical, getDocument} from '@/repository/document-repository.js';
import SECRECY_LEVELS from '@/assets/config/secrecy-levels.json';

const state = {
	loadedDocuments: [],	//cache for doc content
	toUpload: [],
	secrecyLevels: SECRECY_LEVELS
};

const getters = {
	byId: (state) => (id) => {
		return state.loadedDocuments.find(d => d.id === id);
	}
};

const actions = {

	async uploadDocuments({state, commit, rootGetters, rootState}) {
		if (state.toUpload.length === 0) {
			return;
		}

		const uploadDoc = async function(doc) {
			doc.uploadTime = new Date();
			doc.fileName = doc.fileData.name;
			let existingId = await findIdentical(doc.fileData);
			if (existingId) {
				doc.fileId = existingId;
			} else {
				let newFile = await createDocument(doc.fileData, doc.fileName);
				doc.fileId = newFile.id;
			}

			delete doc.fileData;
			return doc;
		};

		let docs = await Promise.all(state.toUpload.map(uploadDoc));

		commit('resetUploadDocs');

		return docs;
	},

	async loadDocument({commit, getters}, {fileId, fileName}) {
		let document = getters.byId(fileId);
		if (!document) {
			document = await getDocument(fileId, fileName);
			commit('addToLoadedDocuments', document);
		}
		return document;
	}
};

const mutations = {

	addDocumentToUpload(state, document) {
		state.toUpload.push(document);
	},

	resetUploadDocs(state) {
		state.toUpload = [];
	},

	removeDocFromTemp(state, doc) {
		let idx = state.toUpload.indexOf(doc);
		if (idx === -1) return;
		state.toUpload.splice(idx, 1);
	},

	deleteDocument(state, {listId, document}) {
		state.documents[listId].splice(state.documents[listId].findIndex(d => d === document), 1);
	},

	addToLoadedDocuments(state, doc) {
		state.loadedDocuments.push(doc);
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
