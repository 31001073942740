import { search } from './system.js';
import { post as sendPostRequest } from '@/util/request.js';
import { createPatch } from 'rfc6902';

export function createConversation(data) {
	return sendPostRequest('bml/conversation/create', data);
}

export function getAllConversationsForUser(entityclassId, accountId, productId) {
	const accountFilter = { $or: [
		{
			'field': '/data/initiator',
			'comparison': 'eq',
			'value': accountId
		},
		{
			'field': '/data/rcpt',
			'comparison': 'eq',
			'value': accountId
		}
	]};

	let attributeFilter;
	if (productId) {
		attributeFilter = { $and: [
			{
				'field': '/data/productId',
				'comparison': 'eq',
				'value': productId
			}
		]};

		attributeFilter.$and.push(accountFilter);
	} else {
		attributeFilter = accountFilter;
	}

	const sorting = [ 'updated:desc' ];

	return search(entityclassId, attributeFilter, null, sorting);
}

export function getSingleConversation(entityclassId, id) {
	let attributeFilter = id ? { $and: {
		'field': '/id',
		'comparison': 'eq',
		'value': id
	} } : null;

	return search(entityclassId, attributeFilter);
}

export function updateConversation(oldEntity, newData, changeset) {
	if (!oldEntity) {
		return null;
	}
	const patch = createPatch(oldEntity.data, newData);
	if (patch.length === 0) {
		return null;
	}
	let payload = {
		id: oldEntity.id,
		rev: oldEntity.rev,
		patch
	};
	if (changeset && changeset !== oldEntity.attributeset) {
		payload.changeset = changeset;
	}
	return sendPostRequest('bml/conversation/save', payload);
}

export function setArchived(oldEntry, changeset) {
	const newData = JSON.parse(JSON.stringify(oldEntry.data));
	newData.archived = true;

	let entity = updateConversation(oldEntry, newData, changeset);
	return entity;
}
