import { get, post } from '@/util/request.js';
import { getToken } from '@/util/jwt-cache.js';

export async function listAccountsForUser() {
	if (!getToken()) return [];
	let list = await get('auth/domain/listaccounts');
	return list;
}

export async function switchAccount(accountId) {
	const payload = { account: accountId };
	const data = await post('auth/account/switchaccount', payload);
	return data;
}

export async function registerWithDomainByInvite(inviteId, registrationName, token) {
	const payload = {
		inviteId,
		registrationName,
		userData: {},
		token
	};
	let { account } = await post('auth/domain/register', payload);
	return account;
}

export function refreshToken() {
	return post('auth/account/refreshtoken');
}

export function getAccountDetails() {
	return get('auth/account/getaccount');
}

export function unregisterAccount(account) {
	return post('auth/account/unregisterwithaccount', { account });
}

export function updateAccount(data) {
	return post('auth/account/updateaccount', data);
}

export function loginToDomain(token, useAccount) {
	let p = { token };
	if (useAccount) p.useAccount = useAccount;
	return post('auth/domain/login', p);
}

export function createNewAccount({ registrationName, requiresConfirmation, userData, staticData }) {
	return post('auth/accountmanage/createaccount', {
		registrationName, requiresConfirmation, userData, staticData
	});
}

export function updateExistingAccount({ active, account, userData, staticData, maxUsers }) {
	return post('auth/accountmanage/updateaccount', {
		active, account, userData, staticData, maxUsers
	});
}

export function removeAccount({ account }) {
	return post('auth/accountmanage/removeaccount', {
		account
	});
}

export async function createAccountInvite({ account }) {
	let { inviteId } = await post('auth/accountmanage/createaccountinvite', {
		account
	}, getToken());
	return inviteId;
}

export function sendInvite({ link, email }) {
	return post('bml/paiduser/sendinvite', { link, email, template: 'generic' }, getToken());
}
