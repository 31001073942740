export const backendUrl = process.env.VUE_APP_BASE_URL || 'https://demoapi.ecodb.de/bml';
export const pubUrl = process.env.VUE_APP_PUB_URL || 'https://demostatic.ecodb.de/bmlpub/';
export const basePath = process.env.VUE_APP_BASE_PATH || '/';
export const disablePresence = !!process.env.VUE_APP_DISABLE_PRESENCE || false;
export const CommitSha = process.env.VUE_APP_COMMIT_SHA || 'dev';
export const LogUrl = process.env.VUE_APP_LOG_URL || 'https://demostatic.ecodb.de/csp/';

let tmp = backendUrl.replace(/\/$/, '');
export const DOMAIN = tmp.substr(tmp.lastIndexOf('/') + 1);

export const KeycloakInfo = process.env.VUE_APP_KEYCLOAK_INFO
	? JSON.parse(process.env.VUE_APP_KEYCLOAK_INFO)
	: {
		'realm': 'zdb',
		'url': 'http://localhost/auth/',
		'clientId': 'bml'
	};
