export const ROLE = {
	Admin: 'Admin',
	Editor: 'Editor',
	Registered: 'Registered',
	None: 'None'
};

export function accountToRole(accountData) {
	if (!accountData) {
		return ROLE.None;
	}

	const staticData = accountData.staticData;

	if (staticData) {
		if (staticData.isAdmin) {
			return ROLE.Admin;
		} else {
			return ROLE.Editor;
		}
	}

	return ROLE.Registered;
}
