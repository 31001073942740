import { toLocale } from '@/plugins/filters';

const state = {};

const getters = {

	// returns all possible approvals (base data) for an given list
	getRelevantApprovals: (state, getters, rootState, rootGetters) => ({listId}) => {
		if (!rootState.product.currentList) return [];
		const list = rootGetters['list/byId'](listId);
		if (!list) return [];
		const standards = list.data.biostandards;
		return rootGetters['biostandard/sortedByPublicOrder'].filter(b => standards.includes(b.id));
	},

	// returns an array containing all approval base data
	// only includes entries for an given list where the approval was actually approved
	getApprovedApprovals: (state, getters, rootState, rootGetters) => ({listId}) => {
		const data = rootGetters['product/getApprovalData'](listId);
		const relevantApprovals = getters.getRelevantApprovals({listId});

		return relevantApprovals.filter((approval) => {
			const id = approval.id;

			if (!data[id] || !data[id].begin) return false;

			let now = rootGetters['product/referenceTime'];
			let begin = new Date(data[id].begin);
			if (begin > now) return false;
			if (data[id].end) {
				let end = new Date(data[id].end);
				if (end < now) return false;
			}
			return true;
		});
	},

	getApprovalDataWithParent: (state, getters, rootState, rootGetters) => ({listId}) => {
		let currentApprovalRows = JSON.parse(JSON.stringify(rootGetters['product/getApprovalData'](listId)));
		let baseListId = rootGetters['list/baseDataList'].id;
		let baseApprovalRows = JSON.parse(JSON.stringify(rootGetters['product/getApprovalData'](baseListId)));
		let relevantBiostandards = JSON.parse(JSON.stringify(getters.getRelevantApprovals({listId})));

		// filter only those biostandards that are currently relevant for this list
		relevantBiostandards = relevantBiostandards
			.filter((approval) => {
				const id = approval.id;

				if (!currentApprovalRows[id] || !currentApprovalRows[id].begin) return false;

				let now = rootGetters['product/referenceTime'];
				let begin = new Date(currentApprovalRows[id].begin);
				if (begin > now) return false;
				if (currentApprovalRows[id].end) {
					let end = new Date(currentApprovalRows[id].end);
					if (end < now) return false;
				}
				return true;
			});


		const isRestrictionEmpty = function(value) {
			if ((!value.restriction || !toLocale(value.restriction).trim() || toLocale(value.restriction) === '---') && (!value.restrictionTextId || !value.restrictionTextId.selectValue)) {
				return true;
			}
			let isEmpty = !toLocale(value.restriction).trim() || toLocale(value.restriction) === '---';
			if (isEmpty && value.restrictionTextId && value.restrictionTextId.selectValue) {
				let text = rootGetters['restrictiontext/byId'](value.restrictionTextId.selectValue);
				return !text || toLocale(text.data.text) === '---';
			}

			return isEmpty;
		};

		// now find the approvals that don't have any restriction and swap those restrictions with the corresponding parents
		for (let [id, value] of Object.entries(currentApprovalRows)) {
			// check if current restriction is empty
			if (!relevantBiostandards.find(f => f.id === id)) {
				continue;
			}

			if (isRestrictionEmpty(value)) {
				let parentStandard = rootGetters['biostandard/byId'](id);
				while (parentStandard) {
					let parentApproval = currentApprovalRows[parentStandard.id] ? currentApprovalRows[parentStandard.id] : baseApprovalRows[parentStandard.id];
					if (!parentApproval) {
						break;
					}

					if (isRestrictionEmpty(parentApproval) && parentStandard.data.dependency) {
						let dep = parentStandard.data.dependency;
						parentStandard = rootGetters['biostandard/byId'](dep);
						if (parentStandard) {
							continue;
						} else {
							break;
						}
					}
					value.restriction = parentApproval.restriction;
					if ((!toLocale(value.restriction).trim() || toLocale(value.restriction) === '---') && parentApproval.restrictionTextId && parentApproval.restrictionTextId.selectValue) {
						let text = rootGetters['restrictiontext/byId'](parentApproval.restrictionTextId.selectValue);
						if (text && toLocale(text.data.text) !== '---') {
							value.restriction = text.data.text;
						}
					}
					break;
				}
			}
		}

		return currentApprovalRows;
	},

	getApprovalData: (state, getters, rootState) => (listId) => {
		if (!rootState.product.currentList) return {};
		if (listId === rootState.product.currentList.id) {
			return rootState.product.workingCopy._approval ?
				rootState.product.workingCopy._approval : {};
		} else {
			return rootState.product.loadedData[listId]
			&& rootState.product.loadedData[listId]._approval ?
				rootState.product.loadedData[listId]._approval : {};
		}
	},

	isBaseCheckApproved: (state, getters, rootState) => (listId) => {
		const approvalData = getters.getRelevantApprovals({listId});
		let hasRelevantApprovals = false;
		for (const biostandard of approvalData) {
			if (biostandard.data.baseApproval) {
				hasRelevantApprovals = true;
				break;
			}
		}

		if (!hasRelevantApprovals) {
			return true;
		}

		const approvals = getters.getApprovedApprovals({listId});

		for (const approval of approvals) {
			if (approval.data.baseApproval) {
				return true;
			}
		}

		return false;
	}

};

const actions = {};

const mutations = {
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
};
