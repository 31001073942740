import Languages from '@/assets/config/languages.json';
import LanguageTitles from '@/assets/config/language-titles.json';

const state = {

	selectedUiLanguage: 'de_DE',
	fallbackLocale: 'en_GB',
	languageTitles: LanguageTitles,
	languages: Languages,
	userLanguagesForMultiLangFields: [ 'de_DE', 'en_GB' ],
	route: {},
	favoredSearches: [],
	receiveMailsNotes: false,
	receiveMailsMessages: false,
	overrideMultitextLanguages: false,
	notificationEmail: '',
	loading: false,
	maintenanceMode: false,

	userTimeFormat: 'H:mm:ss',
	userDateFormat: 'DD.MM.YY',

	back: {
		url: '',
		params: {}
	}
};

const getters = {
	getLanguageTitle: (state) => (languageCode) => {
		let entry = state.languageTitles.find(x => x.key === languageCode);

		if (!entry) {
			throw new Error(`Language code ${languageCode} not defined`);
		}

		return entry.title;
	},
	getLanguagesForSelect: (state, getters) => {
		return state.languages.map(code => {
			return { value: code, text: getters.getLanguageTitle(code) };
		}).sort((a, b) => a.text.localeCompare(b.text));
	},
	isInProduct: (state) => {
		return state.route.name && state.route.name.startsWith('product-tab-');
	},
	getMultitextLanguages: (state, getters, rootState) => {
		if (!state.overrideMultitextLanguages || !getters.isInProduct) {
			return state.userLanguagesForMultiLangFields;
		}
		let list = rootState.product.currentList;
		if (!list) return [];
		return list.data.languages;
	},
	getFavoriteSearches: (state) => {
		return state.favoredSearches;
	},
	getLanguages: (state) => {
		return state.languages;
	},
	getBackUrl: (state) => {
		return state.back.url ? state.back : null;
	}
};

const actions = {


};

const mutations = {

	setUiLanguage(state, lang) {
		if (!state.languages.includes(lang)) throw new Error(`invalid lang: ${lang}`);
		state.selectedUiLanguage = lang;
	},

	setUserLangForFields(state, langs) {
		if (!Array.isArray(langs)) throw new Error('must be an array');
		langs.forEach(lang => {
			if (!state.languages.includes(lang)) throw new Error(`invalid lang: ${lang}`);
		});
		state.userLanguagesForMultiLangFields = [...new Set(langs)];
	},

	setReceiveMailsNotes(state, { enabled, email }) {
		if (typeof enabled !== 'undefined') state.receiveMailsNotes = !!enabled;
		if (typeof email !== 'undefined') state.notificationEmail = email || '';
	},

	setReceiveMailsMessages(state, { enabled, email }) {
		if (typeof enabled !== 'undefined') state.receiveMailsMessages = !!enabled;
		if (typeof email !== 'undefined') state.notificationEmail = email || '';
	},

	setOverrideMultitextLanguages(state, { enabled }) {
		state.overrideMultitextLanguages = !!enabled;
	},

	updateFavoriteSearch(state, searchId) {
		if (state.favoredSearches.includes(searchId)) {
			const idx = state.favoredSearches.findIndex(f => f === searchId);
			state.favoredSearches.splice(idx, 1);
		} else {
			state.favoredSearches.push(searchId);
		}
	},

	removeFavoriteSearch(state, searchId) {
		const idx = state.favoredSearches.findIndex(f => f === searchId);
		state.favoredSearches.splice(idx, 1);
	},

	setFavoredSearches(state, searches) {
		state.favoredSearches = searches;
	},

	setBackLink(state, { url, params }) {
		state.back.url = url;
		state.back.params = params || null;
	},

	resetBackLink(state) {
		state.back.url = null;
		state.back.params = null;
	},

	setLoadingState(state, loading) {
		state.loading = loading;
	},

	setIsMaintenance(state) {
		state.maintenanceMode = true;
	},

	setRoute(state, route) {
		state.route = {
			fullPath: route.fullPath,
			name: route.name,
			params: {
				...route.params
			},
			path: route.path,
			query: {
				...route.query
			}
		};
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
