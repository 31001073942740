import * as Repo from '@/repository/system.js';
import Types from '@/assets/config/paiduser-types.json';
import * as AccountRepo from '@/repository/auth.js';

const state = {

	listAso: [],
	entityclassId: '',
	roleOptions: Types,
	checkedBaseline: false,
	checkedRows: [],
	listFilter: {
		name: '',
		roles: [],
		activeUntil: ''
	},
	listTotalCount: 0
};

const ENTITY_CLASS_ID = 'paiduser';

const getters = {

	isChecked: (state) => (item) => {
		return state.checkedRows.includes(item) ? !state.checkedBaseline : state.checkedBaseline;
	},

	getListFilter: state => filter => {
		return state.listFilter[filter];
	},

	getListAttributeFilter: state => {
		const filter = state.listFilter;
		let $and = [];
		let roles = filter.roles.map(e => {
			return {
				field: '/data/staticData/role',
				comparison: 'eq',
				value: e
			};
		});
		if (roles.length) {
			$and.push({ $or: roles });
		}
		if (filter.name) {
			$and.push({
				field: '/indexfields/search',
				comparison: 'eq',
				value: filter.name,
				use: 'ngram'
			});
		}
		if (filter.activeUntil) {
			$and.push({
				field: '/data/staticData/paidPeriodEnds',
				comparison: 'gte',
				value: filter.activeUntil
			});
		}
		return { $and };
	},

	getListFinalFilter: (state, getters) => {
		const base = getters.getListAttributeFilter;
		const checkedAsIds = state.checkedRows.map(id => {
			return {
				field: '/id',
				comparison: 'eq',
				value: id
			};
		});
		if (state.checkedBaseline) {
			if (state.checkedRows.length === 0) {
				return base;
			}
			return {
				$and: [
					base,
					{ $not: { $or: checkedAsIds } }
				]
			};
		} else {
			return {
				$and: [
					base,
					{ $or: checkedAsIds }
				]
			};
		}
	},

	checkedCount: state => {
		return state.checkedBaseline ? state.listTotalCount - state.checkedRows.length : state.checkedRows.length;
	},

	getAsoUsers: state => {
		return state.listAso;
	},
};

const actions = {

	async ensureAsoUsersLoaded({ state, dispatch, commit }) {
		if (state.listAso.length === 0) {
			let list = await dispatch('load', { filter: {
				field: '/data/staticData/role',
				comparison: 'eq',
				value: 'aso'
			}});
			commit('setAsoCache', list);
		}
		return state.listAso;
	},

	async load({ dispatch, commit }, { filter, sorting, pagination }) {
		let ec = await dispatch('getEntityclass');
		let list = await Repo.search(ec, filter, pagination, sorting);
		let count = await Repo.countEntries(ec, filter);
		commit('setListCount', count);
		return list;
	},

	loadBatch({ state }, { filter }) {
		let generator = Repo.searchCursorWithGenerator(state.entityclassId, filter, 'POST', 'search/search/cursor');
		return generator;
	},

	async getEntityclass({ state, commit }) {
		if (!state.entityclassId) {
			let ec = await Repo.getEntityclassByExternalId(ENTITY_CLASS_ID);
			commit('setEntityclass', ec);
		}
		return state.entityclassId;
	},

	async findAdminUsersForCompany({ state, dispatch }, { company }) {
		const ec = await dispatch('getEntityclass');
		const filter = {
			$and: [
				{
					field: '/data/staticData/companyLink',
					comparison: 'eq',
					value: company,
				},
				{
					field: '/data/userData/subAccountFlags',
					comparison: 'eq',
					value: 'admin',
				},
			],
		};
		let ids = await Repo._search(ec, filter, null, 'search/search/execute', null, null, true);
		return ids;
	},

	create(_, { registrationName, userData, staticData }) {
		return AccountRepo.createNewAccount({ registrationName, userData, staticData });
	},

	update(_, { account, userData, staticData, maxUsers, active }) {
		return AccountRepo.updateExistingAccount({ account, userData, staticData, maxUsers, active });
	},

	remove(_, { account }) {
		return AccountRepo.removeAccount({ account });
	}

};

const mutations = {

	setEntityclass(state, ec) {
		state.entityclassId = ec.id;
	},

	setAsoCache(state, list) {
		state.listAso = list;
	},

	setChecked(state, { base, item, value }) {
		if (base) {
			state.checkedBaseline = value;
			state.checkedRows = [];
		} else {
			let idx = state.checkedRows.findIndex(e => e === item);
			if (idx > -1) {
				state.checkedRows.splice(idx, 1);
			} else if (idx === -1) {
				state.checkedRows.push(item);
			}
		}
	},

	resetChecked(state) {
		state.checkedBaseline = false;
		state.checkedRows = [];
	},

	setListFilter(state, { filter, value }) {
		if (!(filter in state.listFilter)) throw new Error(`unknown filter: ${filter}`);
		state.listFilter[filter] = value;
	},

	setListCount(state, count) {
		state.listTotalCount = count;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
