import favloader from 'favloader';
import Store from '../store/main.js';

const MIN_SPINNER_DURATION_MS = 500;

favloader.init({
	color: '#2ab561',
	duration: MIN_SPINNER_DURATION_MS
});

let requestCounter = 0;
let spinnerVisibleUntil = null;
let spinnerTimer = null;

function _refreshSpinner() {
	spinnerVisibleUntil = new Date(Date.now() + MIN_SPINNER_DURATION_MS);
}
function _isRequestQueueEmpty() {
	return requestCounter < 1;
}

function _isSpinnerExpired() {
	return _isRequestQueueEmpty() && Date.now() >= spinnerVisibleUntil;
}

function _isSpinnerCleared() {
	return spinnerVisibleUntil === null;
}

export function tryStartSpinner() {
	requestCounter += 1;
	// console.log('tryStartSpinner', requestCounter);
	if (_isSpinnerCleared()) {
		// favloader.start();
		Store.commit('base/setLoadingState', true);
	}
	_refreshSpinner();
}

export function tryStopSpinner() {
	requestCounter -= 1;
	// console.log('tryStopSpinner', requestCounter);
	if (_isSpinnerExpired() || !spinnerVisibleUntil) {
		hideSpinner();
	} else if (!spinnerTimer) {
		spinnerTimer = setTimeout(hideSpinner, spinnerVisibleUntil - Date.now());
	}
}

function hideSpinner() {
	const now = Date.now();
	if (spinnerVisibleUntil && spinnerVisibleUntil > now) {
		spinnerTimer = setTimeout(hideSpinner, spinnerVisibleUntil - now);
		return;
	}
	spinnerVisibleUntil = null;
	// favloader.stop();
	clearInterval(spinnerTimer);
	spinnerTimer = null;
	Store.commit('base/setLoadingState', false);
}
