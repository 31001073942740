import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faSave,
	faWindowRestore,
	faSearch,
	faTimes,
	faPencilAlt,
	faPlus,
	faPlusCircle,
	faCircle,
	faCheckCircle,
	faTimesCircle,
	faTrashAlt,
	faCheckSquare,
	faSquareFull,
	faGlobe,
	faGlobeEurope,
	faUserCircle,
	faStar,
	faLock,
	faArrowCircleUp,
	faArrowCircleDown,
	faClock,
	faCalendar,
	faTrash,
	faCog,
	faFilter,
	faBars,
	faEye
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faSave,
	faWindowRestore,
	faSearch,
	faTimes,
	faPencilAlt,
	faPlus,
	faPlusCircle,
	faCircle,
	faCheckCircle,
	faTimesCircle,
	faTrashAlt,
	faCheckSquare,
	faSquareFull,
	faGlobe,
	faGlobeEurope,
	faUserCircle,
	faStar,
	faLock,
	faArrowCircleUp,
	faArrowCircleDown,
	faClock,
	faCalendar,
	faTrash,
	faCog,
	faFilter,
	faBars,
	faEye
);

Vue.component('VIcon', FontAwesomeIcon);
