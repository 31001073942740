// import '@babel/polyfill';
// import 'mutationobserver-shim';
import Vue from 'vue';
import { BootstrapVue,IconsPlugin } from 'bootstrap-vue';
import VueProgressBar from 'vue-progressbar';
import '@/assets/scss/custom.scss';
import App from './App.vue';
import router from './router';
import store from './store/main.js';
import './store/hooks.js';
import './plugins/icons.js';
import './plugins/filters.js';
import './plugins/directives.js';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import SaveDialog from '@/components/Product/SaveDialog.vue';
import init from '@/util/initialization.js';
import EventBus from '@/util/eventbus.js';
import Autocomplete from 'v-autocomplete';

Error.stackTraceLimit = Infinity;
import { languages } from '@/assets/config/translations/index.js';

const languageStrings = Object.assign(languages);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueProgressBar, {
	thickness: '10px',
	color: '#2ab561'
});

Vue.use(VueI18n);
Vue.use(Vuelidate);

Vue.component('VAutocomplete', Autocomplete);

Vue.config.productionTip = false;

export const i18n = new VueI18n({
	locale: store.state.base.selectedUiLanguage,
	fallbackLocale: 'de_DE',
	messages: languageStrings
});

Vue.directive('click-outside', {
	bind: (el, binding, vnode) => {
		el.clickOutsideEvent = function(event) {
			if (!(el == event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind: (el) => {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	},
});

const VIEW_NAME = 'save-dialog';

Vue.use(VuejsDialog, {
	view: VIEW_NAME,
	html: true,
	loader: true,
	okText: 'Ok',
	cancelText: 'Abbrechen'
});

Vue.dialog.registerComponent(VIEW_NAME, SaveDialog);

init();

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');

const tokenCheckHandler = () => {
	EventBus.emit('checkToken');
};

window.addEventListener('load', tokenCheckHandler);
window.addEventListener('focus', tokenCheckHandler);
