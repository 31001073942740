<template>
	<b-modal
		ref="expirationModal"
		scrollable
		size="lg"
		:title="$t('auth.views.tokenExpired.title')"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
		v-model="show"
	>
		<div>
			<p>
				<strong>
					{{ $t('auth.views.tokenExpired.message') }}
				</strong>
			</p>
			<p>
				{{ $t('auth.views.tokenExpired.backToSSOMessage') }}
			</p>
		</div>
		<template slot="modal-footer">
			<b-button variant="primary" @click="goToSso()">
				{{ $t('auth.views.tokenExpired.backToSSOButton') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'TokenExpired',

	computed: {
		show() {
			return !this.$store.getters['auth/login/isLoggedIn'];
		}
	},

	methods: {
		dispatchedShow() {
			this.$refs.expirationModal.show();
		},
		goToSso() {
			this.$store.dispatch('auth/login/authenticate');
		}
	}
};
</script>
