import * as Repo from '@/repository/hobbygarden.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';

const state = {

	entries: [],
	attributesetId: '',
	entityclassId: ''

};

const getters = {
	roots: (state) => {
		return state.entries.filter(c => !c.data.parent);
	},
	byParent: (state) => (id) => {
		return state.entries.filter(c => c.data.parent === id);
	},
	byId: (state) => (id) => {
		return state.entries.find(c => c.id === id);
	},
	all: (state) => {
		return state.entries;
	},
	getLevel: (state, getters) => (id) => {
		let actualDepth = 0;
		let traverseElements = function(elements, depth) {
			for (const el of elements) {
				if (el.id === id) {
					actualDepth = depth;
				}

				if (el.children && el.children.length > 0) {
					traverseElements(el.children, depth + 1);
				}
			}
		};

		traverseElements(getters.roots, 0);
		return actualDepth;
	},

	pathToRoot: (state, getters) => (ids) => {
		let entries = ids;
		for (const id of ids) {
			const entry = getters.byId(id);
			if (entry.data.parent) {
				let parent = getters.byId(entry.data.parent);
				while (parent) {
					if (!entries.includes(parent.id)) {
						entries.push(parent.id);
					}

					parent = getters.byId(parent.data.parent);
				}
			}
		}

		return entries;
	}
};

const CLASS_ID = 'hobbygarden';

const actions = {

	async _setEntityClassId({ state, commit }) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId(CLASS_ID);
			commit('setEcId', { id: ec.id });
		}
	},

	async _setAttributeSetId({ state, commit }) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId(CLASS_ID);
			commit('setAsId', {id: ec.id});
		}
	},

	async create({ state, commit, dispatch, getters }, { title, parentId, disabled }) {
		await dispatch('_setEntityClassId');
		await dispatch('_setAttributeSetId');

		let entity = await Repo.createEntry(title, parentId, disabled, state.entityclassId, state.attributesetId);
		commit('addEntry', entity);

		if (parentId) {
			const parent = getters.byId(parentId);
			commit('addChild', { parent, child: entity });
		}
	},

	async update({ commit, state, dispatch, getters }, entry) {
		await dispatch('_setAttributeSetId');
		let oldEntry = getters.byId(entry.id);

		let updated = await Repo.updateEntry(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deleteEntry', { id: entry.id });
			commit('addEntry', updated);

			if (updated.data.parent) {
				const parent = getters.byId(updated.data.parent);
				commit('removeChild', { parent, childId: updated.id });
				commit('addChild', { parent, child: updated });
			}
		}
	},

	async delete({ commit, dispatch }, { entry }) {
		if (typeof entry === 'undefined') {
			return;
		}

		if (typeof entry.children !== 'undefined') {
			let list = new Set(entry.children);
			for (let child of list) {
				await dispatch('delete', {entry: child});
			}
		}

		await Repo.deleteEntry(entry.id);
		commit('deleteEntry', { id: entry.id });

		if (entry.data.parent) {
			const parent = getters.byId(state)(entry.data.parent);
			commit('removeChild', { parent, childId: entry.id });
		}
	},

	async loadAll({ state, commit, dispatch }, { ifEmpty }) {
		if (ifEmpty && state.entries.length > 0) return;

		await dispatch('_setEntityClassId');

		let list = await Repo.getAllEntries(state.entityclassId);
		commit('setList', list);
	},

};

const mutations = {

	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	addChild(state, { parent, child }) {
		if (!parent.children) {
			parent.children = [];
		}

		parent.children.push(child);
	},
	removeChild(state, { parent, childId }) {
		if (!parent.children) {
			return;
		}

		const idx = parent.children.findIndex(f => f.id === childId);
		parent.children.splice(idx, 1);
	},
	setList(state, entries) {
		state.entries = entries;

		for (let entity of state.entries) {
			if (!entity.children) {
				entity.children = [];
			}

			entity.children = getters.byParent(state)(entity.id);
		}
	},
	addEntry(state, entity) {
		if (!entity.children) {
			entity.children = [];
		}

		entity.children = getters.byParent(state)(entity.id);
		state.entries.push(entity);
	},

	deleteEntry(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.entries.indexOf(entity);
		state.entries.splice(idx, 1);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
