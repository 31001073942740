import { toLocale, toSortable } from '@/plugins/filters.js';
import Vue from 'vue';

const state = {

	company: null,
	checklists: {}

};

const VALID_KEYS = ['status', 'until', 'date', 'comment', 'updatedBy'];

const getters = {

	getValue: (state) => (clid, key) => {
		let cl = state.checklists[clid];
		if (!cl) return null;
		return cl[key];
	},

	forSaving: (state) => {
		let res = {};
		Object.entries(state.checklists).forEach(([k, v]) => {
			let entry = Object.fromEntries(Object.entries(v).filter(([k, v]) => !!v));
			res[k] = entry;
		});
		return res;
	}

};

const actions = {

	init({ commit, rootGetters }, { data, company }) {
		commit('init', { data, company });

		// getters.getMandatoryChecklists.forEach(entry => {
		// 	if (!entry.data.isOptional && !(entry.id in state.checklists)) {
		// 		let firstOption = entry.data.options[0];
		// 		if (!firstOption) return;
		// 		commit('updateValue', {
		// 			clid: entry.id,
		// 			key: 'status',
		// 			value: firstOption.id,
		// 			defaultStatusOption: firstOption.id,
		// 			account: rootGetters['auth/user/getCurrentAccountId']
		// 		});
		// 	}
		// });
	}

};

const mutations = {

	init(state, { data, company }) {
		state.checklists = data;
		state.company = company || null;
	},

	updateValue(state, { clid, key, value, defaultStatusOption, account }) {
		let cl = state.checklists[clid];
		if (!cl) {
			cl = {
				status: defaultStatusOption,
				until: '',
				date: '',
				comment: '',
				updatedBy: ''
			};
			Vue.set(state.checklists, clid, cl);
		}
		cl.updatedBy = account;
		if (!VALID_KEYS.includes(key)) throw new Error(`invalid checklist key: ${key}`);
		Vue.set(cl, key, value); //cl[key] = value;
	},

	removeRow(state, clid) {
		Vue.delete(state.checklists, clid);
	},

	addRow(state, clid) {
		let cl = state.checklists[clid];
		if (cl) return;
		cl = {
			status: '',
			until: '',
			date: '',
			comment: '',
			updatedBy: ''
		};
		Vue.set(state.checklists, clid, cl);
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
