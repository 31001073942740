import * as Repo from '@/repository/search/columnset.js';
import { getEntityclassByExternalId, getAttributesetByExternalId, getEntityById } from '@/repository/system.js';
import SearchFields from '@/assets/config/search/search-fields';
import ExportConfigPackages from '@/assets/config/search/export-config-packages';
import { i18n } from '@/main';

// The columnset and export set share the same base logic (e. g. anyone gets a copy of the state design).
export default class BaseColumnsetStore {
	constructor(classId) {
		this.state = {
			columnsets: [],
			selectedColumnSet: null,
			availableFields: [],
			attributesetId: '',
			entityclassId: ''
		};

		this.getters = {
			all: (state) => {
				return state.columnsets;
			},
			byId: (state) => (id) => {
				// return state.columnsets.find(f => f.id === id);
				let res = state.columnsets.filter(f => f.id === id);
				if (res.length > 1) {
					console.warn('byId', id, res.length, res);
				}
				return res[0];
			},
			hasColumnSetSelected: (state) => {
				return state.selectedColumnSet !== null;
			},
			getSelectedColumnSet: (state) => {
				return state.selectedColumnSet;
			},
			getAvailableFields: (state) => {
				return state.availableFields;
			},
			fieldByPath: (state) => (path, displayKey) => {
				// return state.availableFields.find(f => f.data.pathInternal === path || f.data.path === path);
				let res = state.availableFields.filter(f => f.data.pathInternal === path || f.data.path === path);
				if (res.length > 1) {
					// console.warn('fieldByPath', path, res.length, res);
					let refinedResult = [];
					if (displayKey) {
						displayKey = displayKey.replace('_', '');
						refinedResult = res.filter(f => f.data.displayKey === displayKey);
					}
					if (refinedResult && refinedResult.length) res = refinedResult;
				}
				return res[0];
			},
			fieldById: (state) => (id) => {
				// return state.availableFields.find(f => f.id === id);
				let res = state.availableFields.filter(f => f.id === id);
				if (res.length > 1) {
					console.warn('fieldById', id, res.length, res);
				}
				return res[0];
			},
			fieldsByType: (state) => (type) => {
				return state.availableFields.filter(f => f.data.type === type);
			},
			fieldsByParent: (state) => (parent) => {
				return state.availableFields.filter(f => f.data.displayKey === parent);
			}
		};

		this.actions = {
			async _loadEntityClass({ state, commit }) {
				if (state.entityclassId) {
					return;
				}

				const ec = await getEntityclassByExternalId(classId);
				commit('setEcId', { id: ec.id });
			},
			async _loadAttributeSet({ state, commit }) {
				if (state.attributesetId) {
					return;
				}

				const as = await getAttributesetByExternalId(classId);
				commit('setAsId', { id: as.id });
			},
			async init({ commit, dispatch, state }) {
				if (state.availableFields.length > 0) return;
				let allFields = [];

				const baseFields = await dispatch('field/loadAll', { ifEmpty: true }, { root: true });
				allFields = allFields.concat(baseFields);

				let additionalFields = [];
				const translateFieldTitle = function(field) {
					if (!field.data) {
						return field;
					}

					let prefix = 'product.search.fieldTitles';
					if (field.data.translationPrefix) {
						prefix = field.data.translationPrefix;
					}

					field.data.title = field.label = i18n.t(`${prefix}.${field.id}`);

					return field;
				};

				// used to add the additional fields from JSON files to the total amount of fields.
				const concatFields = function(base, additionalFields) {
					for (const field of additionalFields) {
						// if (base.find(e => e.id === field.id)) {
						// 	console.warn(`duplicate id: ${field.id}`, field);
						// }
						base = base.concat(translateFieldTitle(field));

						if (field.data && field.data.children) {
							base = concatFields(base, field.data.children);
						}
					}

					return base;
				};
				// console.log('additional start');
				additionalFields = concatFields(additionalFields, JSON.parse(JSON.stringify(SearchFields)));
				additionalFields = concatFields(additionalFields, JSON.parse(JSON.stringify(ExportConfigPackages)));
				// console.log('additional end');
				allFields = allFields.concat(additionalFields);

				commit('setAvailableFields', allFields);
			},
			async loadAll({ state, commit, dispatch }, { ifEmpty }) {
				if (ifEmpty && state.columnsets.length > 0) return;
				await dispatch('_loadEntityClass');

				const list = await Repo.getAllColumnSets(state.entityclassId);
				commit('resetList', list);
				commit('sortColumnsetsByName');
			},
			async create({ state, commit, dispatch }, columnSet) {
				await dispatch('_loadEntityClass');
				await dispatch('_loadAttributeSet');

				const entity = await Repo.createColumnSet(columnSet, state.entityclassId, state.attributesetId);
				commit('addColumnSet', entity);
				commit('sortColumnsetsByName');
				return entity;
			},
			async update({ state, commit, dispatch }, newSet) {
				await dispatch('_loadAttributeSet');

				const oldSet = state.columnsets.find(f => f.id === newSet.id);

				const updated = await Repo.updateColumnSet(oldSet, newSet.data, state.attributesetId);
				if (updated) {
					commit('deleteColumnSet', oldSet.id);
					commit('addColumnSet', updated);
					commit('sortColumnsetsByName');
				}

				return updated;
			},
			async delete({ commit }, set) {
				if (typeof set === 'undefined') {
					return;
				}
				await Repo.deleteColumnSet(set.id);
				commit('deleteColumnSet', set.id);
			},
			async loadById({ commit, dispatch }, id) {
				return await getEntityById(id);
			}
		};

		this.mutations = {
			setEcId(state, { id }) {
				state.entityclassId = id;
			},
			setAsId(state, { id }) {
				state.attributesetId = id;
			},
			resetList(state, l) {
				state.columnsets = l || [];
			},
			addColumnSet(state, columnset) {
				state.columnsets.push(columnset);
			},
			deleteColumnSet(state, id) {
				const idx = state.columnsets.findIndex(f => f.id === id);
				state.columnsets.splice(idx, 1);
			},
			setSelectedColumnSet(state, columnSet) {
				state.selectedColumnSet = columnSet;
			},
			resetSelectedColumnSet(state) {
				state.selectedColumnSet = null;
			},
			setAvailableFields(state, fields) {
				state.availableFields = fields;
			},
			sortColumnsetsByName(state) {
				if (state.columnsets && state.columnsets.length) {
					state.columnsets.sort((columnsetA, columnsetB) => {
						if (columnsetA.data.configuration && columnsetB.data.configuration) {
							return columnsetA.data.configuration.name.trim().localeCompare(columnsetB.data.configuration.name.trim());
						}
						return 1;
					});
				}
			}
		};
	}
}
