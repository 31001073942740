import * as Repo from '@/repository/batchjob.js';
import { getEntityById } from '@/repository/system';
import { constructSelectionFilter } from '@/util/search/query-generator';

export const SET_PRODUCT_FLAGS_TEMPLATE = 'SetProductFlags';
export const REMOVE_PRODUCT_FLAGS_TEMPLATE = 'RemoveProductFlags';

const ALL_TEMPLATES = [SET_PRODUCT_FLAGS_TEMPLATE, REMOVE_PRODUCT_FLAGS_TEMPLATE];

const state = {
	templates: {},
	jobStatus: {}
};

const getters = {

	templateByName: (state) => (name) => {
		return state.templates[name];
	},

	jobStatusByJobId: (state) => (jobId) => {
		return state.jobStatus[jobId];
	}

};

const actions = {

	async loadTemplates({ commit }) {
		const templates = {};

		for (const templateName of ALL_TEMPLATES) {
			const template = await Repo.getTemplateByName(templateName);
			templates[templateName] = template.id;
		}

		commit('setTemplates', templates);
	},

	async setFlag({ getters, dispatch, rootState, rootGetters }, { flagId, templateName }) {
		if (!flagId) {
			console.warn('No flag id provided.');
			return;
		}

		await dispatch('loadTemplates');

		const templateId = getters.templateByName(templateName);
		if (!templateId) {
			console.warn(`Template ${templateName} not found. Aborting.`);
			return;
		}

		const productSelection = rootState.search.selection;

		let attributeFilter = rootGetters['search/getSearchFilter'];
		attributeFilter = constructSelectionFilter(attributeFilter, productSelection);

		const job = await Repo.enqueueJob(templateId, attributeFilter, flagId);
		if (!job) {
			console.warn('Could not enqueue job. Aborting.');
			return;
		}

		const jobId = job.id;
		const jobResult = await dispatch('updateJobStatus', jobId);

		return { jobId, status: jobResult.status, errors: jobResult.errors };
	},

	async updateJobStatus({ commit }, jobId) {
		if (!jobId) {
			console.warn('No job id provided.');
			return;
		}

		const response = await Repo.getJobStatusById(jobId);
		const status = response.status;
		let errors = null;

		if (status === 'complete' || status === 'abort') {
			const jobEntity = await getEntityById(jobId);
			if (jobEntity) {
				errors = jobEntity.data.errors;
			}
		}

		commit('setJobStatus', { jobId, status: response.status, errors });
		return { status: response.status, errors };
	}
};

const mutations = {
	setTemplates(state, templates) {
		state.templates = templates;
	},

	setJobStatus(state, { jobId, status, errors }) {
		state.jobStatus[jobId] = {
			status,
			errors
		};
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
