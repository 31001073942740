import store from '../store/main.js';
import { setToken } from './jwt-cache.js';
import Vue from 'vue';
import jwtDecode from 'jwt-decode';
import EventBus from '@/util/eventbus.js';
import { CommitSha } from './env.js';
import { log } from './request';

function checkForMaintenance(e) {
	if (!e || !e.message) return false;
	if (e.message.includes('service is currently not available. check back later')) {
		store.commit('base/setIsMaintenance');
		return true;
	}
	return false;
}

function getExtraLogData() {
	return {
		ua: navigator.userAgent,
		lang: navigator.language,
		plat: navigator.platform,
		loc: window.location.href,
		sx: screen.width,
		sy: screen.height,
		app: 'tdb',
		build: CommitSha
	};
}

async function logError(data) {
	try {
		await log({ ...data, ...getExtraLogData() });
	} catch (e) {
		console.log('error during error logging', e);
	}
}

export default async function init() {

	// error handling
	Vue.config.errorHandler = function(error) {
		checkForMaintenance(error);
		console.error('vue.errorHandler', error);
		if (process.env.NODE_ENV !== 'production') EventBus.$emit('error', error);
	};
	Vue.config.warnHandler = function(error) {
		checkForMaintenance(error);
		console.warn('vue.warnHandler', error);
		if (process.env.NODE_ENV !== 'production') EventBus.$emit('warning', error);
	};
	window.addEventListener('unhandledrejection', event => {
		checkForMaintenance(event.reason);
		console.log('unhandledRejection', event);
	});
	window.onerror = function(message, source, lineno, colno, error) {
		console.log('window.onerror', error);
		EventBus.$emit('error', error);
		let maintenance = checkForMaintenance(error);
		if (!maintenance) {
			logError({ message, source, lineno, colno, error });
		}
	};

	if (store.getters['auth/login/isLoggedIn']) {
		let token = store.getters['auth/login/getCurrentToken'];
		let { exp } = jwtDecode(token);
		if (exp > ~~(Date.now()/1000)) {
			setToken(token);
			if (store.getters['auth/login/hasAccountSelected']) {
				store.dispatch('auth/user/loadAccounts');
				store.dispatch('auth/user/loadAccountData');
			}
		}
	}

	window.addEventListener('keyup', function(event) {
		if (event.keyCode === 13) {
			EventBus.emit('globalKeyUp', 'ENTER');
		}
	});

	await store.dispatch('auth/login/initApp');
}
