import * as SystemRepository from '@/repository/system.js';
import * as InvoiceRepository from '@/repository/invoice.js';
import { constructSelectionFilter } from '@/util/search/query-generator';

const ENTITY_EXTERNAL_ID = 'invoice';
const INDEX_DATA_ITEMS = [ 'customerNumber', 'invoiceNumber' ];
const BOOL_FILTER = [ 'isCancelled', 'isSent' ];

const state = {

	entityclassId: null,
	attributesetId: null,
	invoices: [],
	checkedBaseline: false,
	checkedRows: [],
	listFilter: {
		type: '',
		institution: '',
		targetYear: 0,
		sendDate: '',
		invoiceDate: '',
		periodDescription: {},
		companyId: '',
		customerNumber: '',
		invoiceNumber: '',
		isCancelled: null,
		isSent: null,
		showMarkedAsSent: false
	},
	creationModal: {
		lists: [],
		type: 'yearly',
		institution: '',
		targetYear: parseInt(new Date().toJSON().substring(0, 4)),
		invoiceDate: new Date().toJSON().substring(0, 10),
		standardEndDateFrom: null,
		standardEndDateTo: null,
		periodDescription: {
			de_DE: ''
		},
		emailText: {
			de_DE: ''
		},
		dryRun: false,
		ourSignId: null,
	},
	selection: {
		blacklistMode: false,
		items: []
	},
	sendDateComparator: 'eq',
	invoiceDateComparator: 'eq',
	listTotalCount: 0,
	invoicedInvoices: []

};

const getters = {

	byId: (state) => (id) => {
		return state.invoices.find(invoice => invoice.id === id);
	},

	getFilterByOption: state => option => {
		return state.listFilter[option];
	},

	getCreationModalParamters: state => {
		return state.creationModal;
	},

	getCreationModalParamter: state => (name) => {
		return state.creationModal[name];
	},

	getListFilter: state => filter => {
		return state.listFilter[filter];
	},

	getComparator: state => comparator => {
		return state[comparator];
	},

	getListAttributeFilter: (state) => {
		let $and = [];
		function getBoolFilter(item, data) {
			if (data === null) {
				return null;
			}
			if (item === 'isSent') {
				item = 'sendDate';
			}
			if (item === 'isCancelled') {
				item = 'cancellationDate';
			}
			let filter = {
				field: '/data',
				comparison: 'exists',
				value: item
			};
			return data ? filter : { $not: filter };
		}
		for (let [ item, data ] of Object.entries(state.listFilter)) {
			let comparator = 'eq';
			if (item === 'showMarkedAsSent') {
				if (!data) {
					$and.push({
						$or: [
							{
								field: '/data/sendDate',
								comparison: 'gte',
								value: new Date('2000').toJSON().substring(0, 10)
							},
							{
								$not: {
									field: '/data',
									comparison: 'exists',
									value: 'sendDate'
								}
							}
						]
					});
				}
				continue;
			}
			if (!BOOL_FILTER.includes(item)) {
				if (!data || typeof data === 'object' && !Object.keys(data).length) continue;
			}
			if (INDEX_DATA_ITEMS.includes(item)) {
				if (item === 'customerNumber') {
					$and.push({
						field: `/data/indexData/company/${item}`,
						comparison: comparator,
						value: data
					});
				} else {
					$and.push({
						field: `/data/indexData/${item}`,
						comparison: comparator,
						value: data
					});
				}
			} else {
				if (item.includes('Date')) {
					comparator = state[`${item}Comparator`] ? state[`${item}Comparator`] : 'eq';
				}
				if (BOOL_FILTER.includes(item)) {
					let filter = getBoolFilter(item, data);
					if (filter) {
						$and.push(filter);
					}
				} else {
					$and.push({
						field: `/data/${item}`,
						comparison: comparator,
						value: data
					});
				}
			}
		}
		return { $and };
	},

	byIds: (state) => (invoicIds) => {
		if (!invoicIds || !Array.isArray(invoicIds)) {
			return [];
		}

		return state.invoices.filter(category => invoicIds.includes(category.id));
	},

	getAll: (state) => {
		return state.invoices;
	},

	getListFinalFilter: (state, getters) => {
		return constructSelectionFilter(getters.getListAttributeFilter, state.selection);
	},

	getSelectedItems: (state) => {
		return state.selection.items;
	},

	getBlacklistMode: (state) => {
		return state.selection.blacklistMode;
	},

	getCheckedCount: (state, getters, rootState) => {
		if (state.selection.blacklistMode) {
			return rootState.productlist.productSearchCount - state.selection.items.length;
		}
		return state.selection.items.length;
	},
	getInvoicedList: () => (sum, invoice, company) => {
		return {
			id: invoice.id,
			targetYear: invoice.data.targetYear,
			sendDate: invoice.data.sendDate,
			invoiceDate: invoice.data.invoiceDate,
			listId: sum.listId,
			type: invoice.data.type,
			invoiceNumber: invoice.data.indexData.invoiceNumber,
			companyName: company ? company.data.baseData.companyAbbreviation: '',
			customerNumber: company ? company.data.baseData.customerNumber : '',
			cancellationDate: invoice.data.cancellationDate,
			unindex: sum.unindex
		};
	}

};

const actions = {

	async ensureAttributesetEntityclassLoaded({ commit }) {
		if (!state.entityclassId) {
			const ec = await SystemRepository.getEntityclassByExternalId(ENTITY_EXTERNAL_ID);
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			const as = await SystemRepository.getAttributesetByExternalId(ENTITY_EXTERNAL_ID);
			commit('setAsId', { id: as.id });
		}
	},

	async load({ dispatch, commit }, { filter, pagination, sorting }) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let list = await SystemRepository.search(state.entityclassId, filter, pagination, sorting);
		let count = await SystemRepository.countEntries(state.entityclassId, filter);
		list = list.map(invoice => {
			invoice.data.invoiceId = invoice.id;
			return invoice;
		});
		commit('setListCount', count);
		commit('setInvoices', { invoices: list });
		return list;
	},

	findInvoicedSpecialItems({ rootGetters }, { ids }) {
		let responsible = rootGetters['product/getValue']('_responsible');
		if (!responsible || !responsible.institution) {
			return [];
		}
		return InvoiceRepository.findInvoicedSpecialItems({ ids, institutionId: responsible.institution });
	},

	async loadInvoicedInvoices({ commit, rootGetters, dispatch, getters }, { productId }) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let responsible = rootGetters['product/getValue']('_responsible');
		if (!responsible || !responsible.institution) {
			console.warn('No institution found for list.');
			return [];
		}
		let filter = { $and: [
			{
				field: '/data/sums/productId',
				comparison: 'eq',
				value: productId
			},
			{
				field: '/data',
				comparison: 'exists',
				value: 'sendDate'
			},
			{
				field: '/data/institution',
				comparison: 'eq',
				value: responsible.institution
			}
		]};
		let list = await SystemRepository.search(state.entityclassId, filter);
		let invoicedLists = [];
		let companyIds = [ ...new Set(list.map(invoice => invoice.data.companyId))];
		let companies = await dispatch('companylist/loadByIds', companyIds, { root: true });
		for (let invoice of list) {
			let company = companies.find(company => company.id === invoice.data.companyId);
			for (let sum of invoice.data.sums) {
				if (sum.productId === productId) {
					let invoicedList = getters.getInvoicedList(sum, invoice, company);
					invoicedLists.push(invoicedList);
				}
			}
		}
		commit('setInvoicedInvoices', invoicedLists);
		return invoicedLists;
	},

	async update({ commit, dispatch, getters, state }, invoice) {
		await dispatch('ensureAttributesetEntityclassLoaded');
		let oldEntity = JSON.parse(JSON.stringify(getters.byId(invoice.invoiceId)));
		delete oldEntity.data.invoiceId;
		let invoiceCopy = JSON.parse(JSON.stringify(invoice));
		delete invoiceCopy.invoiceId;
		const updated = await InvoiceRepository.updateInvoice(oldEntity, invoiceCopy, state.attributesetId);
		if (updated) {
			commit('updateInvoice', { invoice: updated });
		}
		return updated;
	},

	deleteSelected({ getters }) {
		return InvoiceRepository.deleteSelection(getters.getListFinalFilter);
	},

	sendSelected({ getters }) {
		return InvoiceRepository.sendSelection(getters.getListFinalFilter);
	},

	markForUnindex(_, data) {
		return InvoiceRepository.markForUnindex(data);
	},

	markSelectedAsPseudoSent({ getters }) {
		return InvoiceRepository.markAsPseudoSent(getters.getListFinalFilter);
	}

};

const mutations = {
	setFilterOption(state, { filter, value }) {
		if (filter === 'active') {
			state.listFilter[filter].booleanValue = value;
		} else {
			state.listFilter[filter] = value;
		}
		state.selection.blacklistMode = false;
		state.selection.items = [];
	},
	setComparator(state, { comparator, value }) {
		if (comparator && comparator in state) {
			state[comparator] = value;
		}
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	addInvoice(state, { invoice }) {
		addUpdateInvoice(state, invoice);
	},
	updateInvoice(state, { invoice }) {
		if (!invoice.invoiceId) return;
		let entry = state.invoices.find(old => old.id === invoice.invoiceId);
		entry.data = invoice;
	},
	// deleteInvoice(state, { id }) {
	// 	const textIndex = state.categoryTexts.findIndex(categoryText => categoryText.id === id);
	// 	if (textIndex === -1) throw new Error(`entity not found: ${id}`);
	// 	state.categoryTexts.splice(textIndex, 1);
	// },
	setInvoices(state, { invoices }) {
		if (!invoices || !invoices.length) {
			state.invoices = [];
			return;
		}
		state.invoices = invoices;
	},
	setListCount(state, count) {
		state.listTotalCount = count;
	},
	setBlacklistMode(state, value) {
		state.selection.blacklistMode = value;
		state.selection.items = [];
	},
	setSelectedItems(state, items) {
		state.selection.items = items;
	},
	setCreationModalParamter(state, { key, value }) {
		state.creationModal[key] = value;
	},
	resetFilter(state) {
		state.listFilter = {
			type: '',
			institution: '',
			targetYear: 0,
			sendDate: '',
			invoiceDate: '',
			periodDescription: {},
			companyId: '',
			customerNumber: '',
			invoiceNumber: '',
			isCancelled: null,
			isSent: null
		};
		state.sendDateComparator = 'eq';
		state.invoiceDateComparator = 'eq';
	},
	setInvoicedInvoices(state, list) {
		state.invoicedInvoices = list || [];
	},
	updateInvoicedList(state, invoicedList) {
		let index = state.invoicedInvoices.findIndex(list => list.productId === invoicedList.productId && list.listId === invoicedList.listId);
		if (index > -1) {
			state.invoicedInvoices.splice(index, 1, invoicedList);
		}
	}
};

function addUpdateInvoice(state, invoice) {
	if (!invoice.id) return;
	const oldInvoice = state.invoices.findIndex(entry => entry.id === invoice.id);
	if (oldInvoice !== -1) {
		state.invoices.splice(oldInvoice, 1, invoice);
	} else {
		state.invoices.push(invoice);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
