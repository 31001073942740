import { post as sendPostRequest } from '@/util/request.js';
import { search, genericEntityUpdate, getEntityById } from './system.js';

export async function createCategory(title, parentId, disabled, mainCategoryKey, number, entityclass, attributeset, order) {

	let categoryData = {};
	categoryData.title = title;
	if (typeof parentId === 'string' && parentId !== '') categoryData.parent = parentId;
	if (typeof disabled !== 'undefined') categoryData.disabled = disabled;
	if (typeof mainCategoryKey !== 'undefined') categoryData.mainCategoryKey = mainCategoryKey;
	if (order) {
		categoryData.order = order;
	}

	if (number) {
		categoryData.number = parseFloat(number);
	}

	let categoryConfig = {
		entityclass,
		attributeset,
		data: categoryData
	};

	let createdCategoryEntity = await sendPostRequest('eav/entity/create', categoryConfig);

	return createdCategoryEntity;
}

export function getCategoryById(id) {
	return getEntityById(id);
}

export function updateCategory(oldCategory, newData, set) {
	let categoryData = JSON.parse(JSON.stringify(newData));
	if (categoryData.number) {
		categoryData.number = parseFloat(categoryData.number);
	}

	return genericEntityUpdate(oldCategory, categoryData, set);
}

export async function deleteCategory(id) {
	let deletedCategoryEntity = await sendPostRequest('eav/entity/disable', id);
	return deletedCategoryEntity.id;
}

export function getAllCategories(entityclassId) {
	return search(entityclassId);
}
