import { post } from '@/util/request.js';
import BmlCommon from '@fibl/bml-common';

const FIBL_DE = 'ab2e5fd7-9ebb-43d8-a0ab-5aedfb43708b';

export async function loadConversation(
	contextKey,
	id,
) {
	const config = {
		context: {},
	};
	if (contextKey === 'invoiceId') config.context.invoiceId = id;
	else if (contextKey === 'confirmationId') config.context.confirmationId = id;
	else if (contextKey === 'productId') config.context.productId = id;
	else if (contextKey === 'requestId') config.context.requestId = id;
	config.recipientOrg = FIBL_DE;
	const entity = await post('bml/message/loadmessage', config);
	const loadedMessage = new BmlCommon.MessageClass();
	loadedMessage.fromEntity(entity);
	return loadedMessage;
}

export async function sendConversation(conversation) {
	const config = {
		context: {},
		message: conversation.messages[conversation.messages.length - 1].content,
	};
	if (conversation.recipient.fiblorg) config.recipientOrg = conversation.recipient.fiblorg;
	if (conversation.recipient.company) config.recipientCompany = conversation.recipient.company;
	if (conversation.recipient.fibluser) config.recipientUser = conversation.recipient.fibluser;
	if (conversation.context) {
		if (conversation.context.requestId)
			config.context.requestId = conversation.context.requestId;
		else if (conversation.context.confirmationId)
			config.context.confirmationId = conversation.context.confirmationId;
		else if (conversation.context.invoiceId)
			config.context.invoiceId = conversation.context.invoiceId;
		else if (conversation.context.productId)
			config.context.productId = conversation.context.productId;
	}
	if (
		conversation.messages[conversation.messages.length - 1].attachments !== undefined &&
		conversation.messages[conversation.messages.length - 1].attachments?.length
	) {
		config.attachments = conversation.messages[conversation.messages.length - 1].attachments;
	}
	if (conversation.subject) {
		config.subject = conversation.subject;
	}
	const entity = await post('bml/message/sendmessage', config);
	conversation.fromEntity(entity);
}
