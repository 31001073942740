import { getProductTitle } from '@/util/field-helper';
import { _searchCursor, _search } from '@/repository/system.js';

const state = {
	entityClassId: '',
	notes: [],
	needsLoading: false,
	filter: {
		institute: null,
		user: null,
		list: null,
		label: null,
		category: null,
		status: 'unread'
	},
	page: 1
};

const backendParamKeys = ['institute', 'user', 'list', 'label', 'category'];
const filterKeys = ['institute', 'user', 'list', 'status', 'label', 'category'];

function extractInstitutionsFromProduct(product) {
	let institutions = [];
	for (const list of Object.values(product.data.listData)) {
		if (list._responsible && list._responsible.institution) {
			institutions.push(list._responsible.institution);
		}
	}

	return [...new Set(institutions)];
}

const getters = {
	getNotes: (state) => {
		return state.notes;
	},

	archiveMode: (state) => {
		return state.filter.status !== 'unread';
	}
};

const actions = {
	// Calculates all notes for all products for an given institute (and user).
	// @TODO: This can be costly as we don't have an mechanism to join related data.
	async loadNotes({ commit, rootGetters, dispatch, state }) {
		await dispatch('category/loadAll', { ifEmpty: true }, { root: true });
		await dispatch('list/loadAll', { ifEmpty: true }, { root: true });
		await dispatch('label/loadAll', { ifEmpty: true }, { root: true });
		if (!state.needsLoading) return;
		let params = {};
		backendParamKeys.forEach(key => {
			if (state.filter[key]) params[key] = state.filter[key];
		});
		if (state.filter.status === 'unread') params.unread = true;
		const relevantProducts = await dispatch('loadProducts', params) || [];
		let notes = [];
		for (const product of relevantProducts) {
			if (!product.data || !product.data.listData) {
				continue;
			}
			const filteredLists = Object.entries(product.data.listData)
				.filter(([_, listData]) => typeof listData._notes !== 'undefined');

			for (const [listId, productListData] of filteredLists) {
				const list = rootGetters['list/byId'](listId);
				const categorySelection = productListData._category;
				let categorySelectionEntities = rootGetters['category/byIds'](categorySelection);

				for (const note of productListData._notes) {
					let obj = note;
					if (!obj.read) {
						obj.read = false;
					}
					if (obj.read && state.filter.status === 'unread') continue;
					const label = obj.label ? rootGetters['label/byId'](obj.label) : null;
					obj.product = {
						id: product.id,
						title: getProductTitle(product),
						list: {
							id: list.id,
							title: list.data.title
						},
						categories: categorySelectionEntities
					};
					if (label) {
						if (state.filter.label && label.id !== state.filter.label) continue;
						obj.product.label = {
							color: label.data.color,
							title: label.data.title
						};
					} else {
						obj.product.label = {
							color: '',
							title: {}
						};
					}
					let institutions = extractInstitutionsFromProduct(product);
					obj.institutions = institutions;
					notes.push(obj);
				}
			}
		}
		commit('resetNotes');
		commit('setNotes', notes);
	},

	async loadProducts({ rootGetters, dispatch, getters }, { institute, user, list, unread, label, category }) {
		await dispatch('productlist/_setEntityClassId', null, { root: true });
		let lists = rootGetters['list/root'];
		let filter = { $or: [] };
		function addForList(listId) {
			let res = [];
			res.push({
				field: `/data/listData/${listId}/_responsible/institution`,
				comparison: 'eq',
				value: institute
			});
			if (user) {
				res.push({
					field: `/data/listData/${listId}/_responsible/accountId`,
					comparison: 'eq',
					value: user
				});
			}
			if (category) {
				res.push({
					field: `/data/listData/${listId}/_categoryTree`,
					comparison: 'eq',
					value: category
				});
			}
			let notesFilter = [];
			if (label) {
				notesFilter.push({
					field: `/data/listData/${listId}/_notes/label`,
					comparison: 'eq',
					value: label
				});
			}
			if (unread) {
				notesFilter.push({
					field: `/data/listData/${listId}/_notes/read`,
					comparison: 'eq',
					value: false
				});
			} else if (notesFilter.length === 0) {
				notesFilter.push({ $not: {	//must be something nested because notes is not indexed in root doc
					field: `/data/listData/${listId}/_notes/id`,
					comparison: 'eq',
					value: '0'
				} });
			}
			res.push({
				$nested: {
					match: { $and: notesFilter },
					path: `/data/listData/${listId}/_notes`
				}
			});
			return { $and: res };
		}
		if (list) {
			filter.$or.push(addForList(list));
		} else {
			for (let l of lists) {
				filter.$or.push(addForList(l.id));
			}
		}
		let res;
		if (getters.archiveMode) {
			const productsPerPage = 10;
			let pagination = {
				start: (state.page-1) * productsPerPage,
				limit: productsPerPage
			};
			res = await _search(null, filter, 'post', 'bml/product/search', pagination);
		} else {
			res = await _searchCursor(null, filter, 'post', 'bml/product/search');
		}
		return res;
	},


};

const mutations = {
	resetNotes(state) {
		state.notes = [];
	},
	setNotes(state, notes) {
		state.notes = notes;
		state.needsLoading = false;
	},
	updateFilter(state, f) {
		filterKeys.forEach(key => {
			if (key in f && f[key] !== state.filter[key]) {
				state.filter[key] = f[key];
				state.needsLoading = true;
			}
		});
	},
	setPage(state, { page }) {
		state.page = page;
		state.needsLoading = true;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
