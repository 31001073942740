import * as Repo from '@/repository/permit.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';

const state = {

	permits: [],
	attributesetId: '',
	entityclassId: ''

};

const getters = {

	byId: (state) => (id) => {
		return state.permits.find(r => r.id === id);
	},

	all: (state) => {
		return state.permits;
	},

	forListAndCategory: (state) => (listId, categoryId) => {
		return state.permits.filter(
			p => (!p.data.lists || p.data.lists.length === 0 || p.data.lists.includes(listId))
			&& (!p.data.mainCategories || p.data.mainCategories.length === 0 || p.data.mainCategories.includes(categoryId))
		);
	}

};

const actions = {

	async loadAll({ state, commit }, { ifEmpty }) {
		if (ifEmpty && state.permits.length > 0) return;
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('permit');
			commit('setEcId', { id: ec.id });
		}
		let list = await Repo.getAllPermits(state.entityclassId);
		commit('resetPermits', list);
	},

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('permit');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('permit');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createPermit(data, state.entityclassId, state.attributesetId);
		commit('addPermit', entity);
	},

	async update({ state, commit, getters }, entry) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('permit');
			commit('setAsId', { id: ec.id });
		}
		let oldEntry = getters.byId(entry.id);
		let updated = await Repo.updatePermit(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deletePermit', { id: entry.id });
			commit('addPermit', updated);
		}
	},

	async delete({ commit }, {id}) {
		await Repo.deletePermit(id);
		commit('deletePermit', {id});
	},

};

const mutations = {

	addPermit(state, entity) {
		state.permits.push(entity);
	},

	resetPermits(state, l) {
		state.permits = l || [];
	},

	deletePermit(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.permits.indexOf(entity);
		state.permits.splice(idx, 1);
	},


	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
