import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import { createBiostandard, deleteBiostandard, getAllBiostandards, updateBiostandard, getBiostandardById } from '@/repository/biostandard-repository.js';
import ApprovalWorkflowService from '@/util/approval-workflow-service';
import { toLocale } from '@/plugins/filters';

const state = {
	biostandards: [],
	entityClassId: null,
	attributeSetId: null
};

const getters = {
	byId: (state) => (id) => {
		return state.biostandards.find(biostandard => biostandard.id === id);
	},
	all: (state) => {
		return state.biostandards;
	},
	toRoot: (state, getters) => (id) => {
		let elements = [];
		let traverseToRoot = function(elements, standards, current) {
			const parent = standards.find(f => f.id === current.data.dependency);
			if (!parent) {
				return;
			}

			elements.push(parent);
			traverseToRoot(elements, standards, parent);
		};

		const obj = getters.byId(id);
		if (!obj) {
			return null;
		}

		traverseToRoot(elements, state.biostandards, obj);

		return elements;
	},
	isBaseApproval: (state, getters) => (id) => {
		const standard = getters.byId(id);
		if (!standard) return false;
		return !!standard.data.baseApproval;
	},
	sortedByPublicOrder: (state) => {
		let base = state.biostandards;
		let sorted = base.slice(0);
		sorted.sort((a, b) => {
			let valueA = parseInt(a.data.publicOrder) || 0;
			let valueB = parseInt(b.data.publicOrder) || 0;
			return valueA > valueB ? 1 : -1;
		});
		return sorted;
	},
	sortedByName: (state) => {
		let base = state.biostandards;
		let sorted = base.slice(0);
		sorted.sort((a, b) => {
			let valueA = toLocale(a.data.name) || '';
			let valueB = toLocale(b.data.name) || '';
			return valueA.localeCompare(valueB);
		});
		return sorted;
	},
	getDependencyLine: (state) => (id) => {
		let res = [];
		let addDep = (id) => {
			let bs = state.biostandards.find(bs => bs.id === id);
			if (bs.data.dependency) {
				res.unshift(bs.data.dependency);
				addDep(bs.data.dependency);
			}
		};
		addDep(id);
		return res;
	},
	canBeApprovedWithDependencies: (state, getters, rootState, rootGetters) => (id) => {
		//check is current list or all other lists if an approval is set
		let checkIn = [rootState.product.workingCopy];
		Object.keys(rootState.product.loadedData).forEach(listId => {
			if (listId !== rootState.product.currentList.id) {
				checkIn.push(rootState.product.loadedData[listId]);
			}
		});
		function checkIfCurrentlyApproved(bsId, data) {
			if (!data) return false;
			if (!(data._approval && data._approval[bsId])) return false;
			let approvalData = data._approval[bsId];
			return approvalData.active && approvalData.active.booleanValue && ApprovalWorkflowService.checkDateForApproval(approvalData);
		}
		let dependentOnApprovals = rootGetters['biostandard/getDependencyLine'](id);
		for (let bsId of dependentOnApprovals) {
			let valid = checkIn.reduce((c, listData) => c || checkIfCurrentlyApproved(bsId, listData), false);
			// console.log('dependency check', bsId, valid);
			if (!valid) return false;
		}
		return true;
	}
};

const actions = {

	async create({state, commit}, data) {
		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId('biostandard');
			commit('setEntityClassId', entityClass);
		}
		if (!state.attributeSetId) {
			let attributeSet = await getAttributesetByExternalId('biostandard');
			commit('setAttributeSetId', { id: attributeSet.id });
		}
		let entity = await createBiostandard(data, state.entityClassId, state.attributeSetId);
		commit('addBiostandard', entity);
	},

	async delete({state, commit}, {id}) {
		await deleteBiostandard(id);
		commit('deleteBiostandard', {id});
	},

	async loadAll({ commit, state }, { ifEmpty }) {
		if (ifEmpty && state.biostandards.length > 0) return;
		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId('biostandard');
			commit('setEntityClassId', entityClass);
		}
		let list = await getAllBiostandards(state.entityClassId);
		commit('resetList', list);
	},

	async update({ state, commit, getters }, standard) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('biostandard');
			commit('setAttributeSetId', { id: ec.id });
		}
		let old = getters.byId(standard.id);
		let updated = await updateBiostandard(old, standard.data, state.attributesetId);
		if (updated) {
			commit('deleteBiostandard', { id: standard.id });
			commit('addStandard', updated);
		}
	}
};

const mutations = {

	setEntityClassId(state, {id}) {
		state.entityClassId = id;
	},

	setAttributeSetId(state, {id}) {
		state.attributeSetId = id;
	},

	addBiostandard(state, entity) {
		state.biostandards.push(entity);
	},

	deleteBiostandard(state, {id}) {
		let entity = state.biostandards.find(biostandard => biostandard.id === id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let index = state.biostandards.indexOf(entity);
		state.biostandards.splice(index, 1);
	},

	resetList(state, l) {
		state.biostandards = l || [];
	},
	addStandard(state, entity) {
		state.biostandards.push(entity);
	},

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
