import { post } from '@/util/request.js';
import { waitForTicket } from './pdf.js';

export async function createListingConfirmationPdf({ confirmationId }) {
	let { ticket, pdf } = await post('bml/product/pdflistingconfirmation', { confirmationId });
	if (!pdf) {
		pdf = await waitForTicket(ticket);
	}
	return pdf;
}

export function createListingConfirmation(params) {
	return post('bml/product/createlistingconfirmation', params, null, true);
}

export function sendListingConfirmation(params) {
	return post('bml/product/sendlistingconfirmation', params);
}

export async function createInvoicePdf(params) {
	let { ticket } = await post('bml/invoice/createinvoicepdf', params);
	return ticket;
}
