import { get, post } from '@/util/request.js';
import { search, genericEntityUpdate, genericEntityCreate } from './system.js';

export function createField(data, entityclassId, attributesetId) {
	return genericEntityCreate(entityclassId, attributesetId, data);
}

export function getAllFields(entityclassId) {
	return search(entityclassId);
}

export function updateField(oldEntry, newData, set) {
	return genericEntityUpdate(oldEntry, newData, set);
}

export async function deleteField(id) {
	let field = await post('eav/entity/disable', id);

	return field.id;
}

export async function hasDuplicatePaths(entityclass, path, id) {
	let pathFilter = {
		'field': '/data/path',
		'comparison': 'eq',
		'value': path
	};
	let idFilter = id ? { $not: {
		'field': '/id',
		'comparison': 'eq',
		'value': id
	} } : null;

	let filter = idFilter ? {
		$and: [ pathFilter, idFilter ]
	} : pathFilter;
	let searchRequest = {
		entityClass: entityclass,
		attributeFilter: filter
	};

	const count = await get('search/search/count', searchRequest);
	return count > 0;
}
