<template>
	<div>
		<b-card>
			<b-card-body v-if="!isLoggedIn">
				<p>
					{{ $t('auth.views.acceptInvite.requiresLogin') }}
				</p>
				<b-button
					@click="gotoLogin"
					variant="primary"
				>
					{{ $t('auth.views.acceptInvite.loginBtn') }}
				</b-button>
			</b-card-body>
			<b-card-body v-else>
				<p>
					{{ $t('auth.views.acceptInvite.introText') }}
				</p>
				<b-button @click="registerWithDomain()" variant="success">
					{{ $t('default.dialog.submit') }}
				</b-button>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>

export default {
	name: 'AcceptInvite',

	computed: {
		token() {
			return this.$route.query.token;
		},
		currentToken() {
			return this.$store.getters['auth/login/getCurrentToken'];
		},
		isLoggedIn() {
			return this.$store.getters['auth/login/hasSsoLogin'];
		},
		inviteId() {
			return this.$route.query.id;
		},
		registrationName() {
			return this.$route.query.registrationName;
		}
	},

	created() {
		if (!this.inviteId || this.inviteId.trim() === '' || !this.registrationName || this.registrationName.trim() === '') {
			// redirect to home page
			this.$router.replace('/');
		}
	},

	methods: {
		gotoLogin() {
			this.$store.dispatch('auth/login/authenticate');
		},
		async registerWithDomain() {
			await this.$store.dispatch('auth/user/acceptInvite', { inviteId: this.inviteId, registrationName: this.registrationName });
			this.$router.replace('/');
		}
	}
};
</script>
