import * as Repo from '@/repository/auth.js';
import { getEntityclassByExternalId, search } from '@/repository/system.js';
import { basePath } from '@/util/env.js';

const state = {
	accountsForAllUsers: [],
	registrationOptions: []
};

const ACCOUNT_ENTITY_CLASS_ID = 'account';

const getters = {
	getAccountsForAllUsers: (state) => {
		return state.accountsForAllUsers;
	},
	getAccountsForActiveUsers: (state) => {
		return state.accountsForAllUsers.filter(e => e.data.active);
	},
	getAccountById: (state) => (id) => {
		return state.accountsForAllUsers.find(f => f.id === id);
	},
	getRegistrationOptions: (state) => () => {
		return state.registrationOptions;
	},
	getInviteLinkUrl: () => (accountId, inviteId, registrationName) => {
		let invite;
		if (!inviteId.includes('__')) {
			invite = `${accountId}__${inviteId}`;
		} else {
			invite = inviteId;
		}
		return `${document.location.origin}${basePath}auth/acceptinvite?id=${invite}&registrationName=${registrationName}`;
	},
	getInvites: (state) => {
		return state.accountsForAllUsers
			.filter(f => f.data.invites && Object.keys(f.data.invites).length > 0)
			.map(f => {
				const name = f.data.staticData.fullname ? f.data.staticData.fullname : '-';

				return {
					accountId: f.id,
					accountName: name,
					invites: f.data.invites
				};
			});
	}
};

const actions = {
	async loadAccountsForAllUsers({ state, commit, rootGetters }, { ifEmpty }) {
		if (ifEmpty && state.accountsForAllUsers.length > 0) {
			return;
		}

		// load entity class from backend
		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId(ACCOUNT_ENTITY_CLASS_ID);
			commit('setEntityClassId', entityClass);
		}

		let accountList = await search(state.entityClassId, null, { limit: 1000 });
		commit('resetAccountsForAllUsers');

		for (const account of accountList) {
			account.orgs = rootGetters['auth/org/byAccountId'](account.id);
		}
		commit('addAccountForAllUsers', accountList);
	}
};

const mutations = {
	setEntityClassId(state, { id }) {
		state.entityClassId = id;
	},
	resetAccountsForAllUsers(state) {
		state.accountsForAllUsers = [];
	},
	addAccountForAllUsers(state, accounts) {
		if (accounts && accounts.length) {
			accounts = accounts.sort((account1, account2) => account1.data.staticData.fullname.localeCompare(account2.data.staticData.fullname));
		}
		state.accountsForAllUsers = accounts;
	},
	setRegistrationOptions(state, registrationOptions) {
		state.registrationOptions = registrationOptions;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
