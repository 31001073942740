import { getProductTimeline } from '@/repository/product.js';
import flatten, { unflatten } from 'flat';
import { i18n } from '@/main';
import { toDateTimeFormat } from '../../../plugins/filters';

const PATH_FILTER = ['indexData', 'componentIndex', '_system', 'approvalHistory', '_nameHistory', '_linked', '_id'];
const BASE_PATHS = ['invoiceFlags', 'isNationalPartner', 'isNationalPartnerInitial'];

function unrollEntries(list) {
	list.forEach(entry => {
		let newPatches = [];
		entry.patch.forEach(p => {
			if (p.value && typeof p.value === 'object') {
				let value = flatten(p.value);
				Object.keys(value).forEach(key => {
					let path = `${p.path}.${key}`;
					newPatches.push({
						op: p.op,
						path,
						value: value[key]
					});
				});
			} else {
				newPatches.push(p);
			}
		});
		entry.patch = newPatches;
	});
	return list;
}

function parsePath(path) {
	let totalPath = path;

	if (path.substr(0, 1) === '/') {
		totalPath = totalPath.substring(1);
	}
	const [l, id, p] = totalPath.split('/');

	if (BASE_PATHS.includes(l)) {
		return totalPath;
	}

	if (p) {
		const [a, b, f, f2] = p.split('.');
		if (!f && !f2) {
			totalPath = a;
		}

		if (f2) {
			totalPath = f2;
		}
	} else if (id) {
		const [i, f, idx] = id.split('.');
		totalPath = f;
	}

	return totalPath;
}

function matchesPathFilter(path, pathFilter) {
	for (const filter of pathFilter) {
		if (path.includes(filter)) {
			return true;
		}
	}
	return false;
}

function aggregateHistory(data) {
	return data.map(rev => {
		let aggregatedFields = {};
		for (const op of rev.patch) {
			const path = parsePath(op.path);
			if (matchesPathFilter(op.path, PATH_FILTER)) {
				continue;
			}
			if (!aggregatedFields[path]) {
				aggregatedFields[path] = [];
			}
			aggregatedFields[path].push(op);
		}
		if (Object.keys(aggregatedFields).length > 0) {
			rev.aggregatedFields = aggregatedFields;
		}
		return rev;
	}).filter(e => 'aggregatedFields' in e);
}

const state = {

	productId: '',
	timeline: []

};

const getters = {

	getHistory: (state) => ({ begins, includeBasePaths }) => {
		const pathMatches = (p) => {
			if (includeBasePaths && matchesPathFilter(p, BASE_PATHS)) return true;
			if (begins && p.startsWith(begins)) return true;
			return false;
		};
		return state.timeline.filter(e => {
			return !!e.patch.find(p => pathMatches(p.path));
		});
	},

	getTimelineEntryCount: (state) => () => {
		return state.timeline.length;
	},

	getInvoiceFlagTimeStamp: (state) => (invoiceFlagValue) => {
		const revisionData = state.timeline.find(update => {
			return update.rev === invoiceFlagValue;
		});
		return revisionData && revisionData.updated ? toDateTimeFormat(revisionData.updated) : invoiceFlagValue;
	}

};

const actions = {

	async loadTimeline({ state, commit }, { productId, refresh }) {
		if (state.productId === productId && !refresh) {
			return;
		}

		let data = await getProductTimeline(productId);
		data = unrollEntries(data);
		data = aggregateHistory(data);
		commit('cache', { productId, data });
	}

};

const mutations = {

	cache(state, { productId, data }) {
		state.productId = productId;
		state.timeline = data;
	}

};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
};
